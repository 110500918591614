import React from 'react'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import { dayjsUTC } from 'src/common/react-platform-components/utils/mm'
import { useIntl } from 'src/common/react-platform-translation'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import { useHistory } from 'react-router'
import { IUser } from 'src/modules/User/model'
import { Button, Icon } from '@mui/material'

/**.
 * installerDetailsHeader component.
 *
 *
 * @param props N/A.
 * @param props.installerDetails The current installer selected as prop.
 * @param props.isEdit Indicating if the installerDetails is editable or not.
 * @returns installerDetailsHeader component.
 *
 */
const InstallerDetailsHeader = ({
    /**
     *
     */
    isEdit,
    /**
     *
     */
    installerDetails,
}: /**
 *
 */
{
    /**
     *
     */
    isEdit: boolean
    /**
     *
     */
    installerDetails: IUser
}) => {
    const mainTheme = selectTheme()
    const { formatMessage } = useIntl()
    const history = useHistory()

    const backToPreviousText = 'Revenir à la map'
    const returnUrl = '/installers'

    return (
        <ThemeProvider theme={mainTheme}>
            <div className="flex flex-col justify-start w-full">
                <div className="hidden sm:block mb-2">
                    <Button color="secondary" onClick={() => history.push(returnUrl)} className="text-12 md:text-16">
                        <Icon
                            component={motion.span}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1, transition: { delay: 0.2 } }}
                            className="text-16 md:text-24 mr-2"
                        >
                            arrow_back
                        </Icon>
                        {formatMessage({ id: backToPreviousText, defaultMessage: backToPreviousText })}
                    </Button>
                </div>
                <div className="flex flex-1 w-full items-center justify-between">
                    <div className="flex items-center ">
                        <Icon
                            component={motion.span}
                            initial={{ scale: 0 }}
                            animate={{ scale: 1, transition: { delay: 0.2 } }}
                            className="hidden sm:inline text-36 md:text-60 "
                        >
                            account_box
                        </Icon>

                        <div className="block sm:hidden">
                            <Button color="secondary" onClick={() => history.push('/customers')}>
                                <Icon
                                    component={motion.span}
                                    initial={{ scale: 0 }}
                                    animate={{ scale: 1, transition: { delay: 0.2 } }}
                                    className="text-24"
                                >
                                    arrow_back
                                </Icon>
                            </Button>
                        </div>

                        <div className="flex flex-col">
                            <div>
                                <Typography
                                    component={motion.span}
                                    initial={{ x: -20 }}
                                    animate={{ x: 0, transition: { delay: 0.2 } }}
                                    className="text-16 md:text-24 ml-12 mr-8 font-semibold"
                                >
                                    {`${installerDetails.companyName},`}
                                </Typography>
                                <Typography
                                    component={motion.span}
                                    initial={{ x: -20 }}
                                    animate={{ x: 0, transition: { delay: 0.2 } }}
                                    className="text-12 md:text-16 font-medium"
                                >
                                    {`${installerDetails.firstName} ${installerDetails.lastName}`}
                                </Typography>
                            </div>
                            <Typography
                                component={motion.span}
                                initial={{ x: -20 }}
                                animate={{ x: 0, transition: { delay: 0.2 } }}
                                className="text-12 md:text-16 mx-12 font-light"
                            >
                                {formatMessage({ id: 'Créé le', defaultMessage: 'Créé le' })}{' '}
                                <span className="font-medium">
                                    {dayjsUTC(installerDetails.createdAt).format('DD/MM/YYYY')}
                                </span>
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </ThemeProvider>
    )
}

export default InstallerDetailsHeader
