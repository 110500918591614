import { authTypes, IRoute } from 'src/common/react-platform-components'
import { LoginConfig } from './modules/User/Login/LoginConfig'
import { Redirect } from 'react-router-dom'
import { RegisterConfig } from './modules/User/Register/RegisterConfig'
import { ForgotPasswordConfig } from './modules/User/ForgotPassword/ForgotPasswordConfig'
import { CustomerDetailsConfig } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsConfig'
import { CustomerListConfig } from 'src/modules/MapPages/CustomerList/CustomerListConfig'
import { InstallationsRequestsConfig } from 'src/modules/InstallationsRequests/InstallationsRequestsConfig'
import { LeadExchangesConfig } from 'src/modules/LeadExchanges/LeadExchangesConfig'
import { InstallerListConfig } from 'src/modules/MapPages/InstallerList/InstallerListConfig'
import { navbarItemType } from './common/ui-kit/fuse/components/FuseNavigation/FuseNavigation'
import { InstallerRequestsConfig } from './modules/RegistrationRequests/InstallerRequests/InstallerRequestsConfig'
import { InstallerDetailsConfig } from './modules/DetailsPages/InstallerDetails/InstallerDetailsConfig'
import { ResetPasswordConfig } from 'src/modules/User/ResetPassword/ResetPasswordConfig'
import { ModifyProfileConfig } from 'src/modules/User/ModifyProfile/ModifyProfileConfig'

/**
 *
 */
export const routes = [
    ...InstallerRequestsConfig,
    ...InstallerListConfig,
    ...InstallerDetailsConfig,
    ...CustomerListConfig,
    ...CustomerDetailsConfig,
    ...LoginConfig,
    ...RegisterConfig,
    ...ForgotPasswordConfig,
    ...ResetPasswordConfig,
    ...LeadExchangesConfig,
    ...InstallationsRequestsConfig,
    ...ModifyProfileConfig,
    {
        /**
         * TODO Document.
         *
         * @returns TODO Document.
         */
        // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
        // eslint-disable-next-line react/display-name
        component: (): JSX.Element => <Redirect to="/login" />,
        path: '/',
        auth: { authType: authTypes.loginRequired },
    } as IRoute</**
     *
     */
    {}>,
]

/**
 * Represent the routes that are going to be displayed in the navbar, they have a type of IRoute but with UINavbarItem property that is required.
 */
// eslint-lint-disable-next-line jsdoc/require-jsdoc
export type IRouteNavigationConfig<T> = IRoute<T> & /**
 *
 */ {
    /**
     *
     */
    settings: /**
     *
     */
    {
        /**
         *
         */
        layout: // eslint-lint-disable-next-line jsdoc/require-jsdoc
        /**
         *
         */
        {
            // eslint-lint-disable-next-line jsdoc/require-jsdoc
            /**
             *
             */
            navbar: /**
             *
             */
            {
                /**
                 *
                 */
                UINavbarItem: navbarItemType
            }
        }
    }
}

/**
 * NavigationConfig Represent all the routes that are going to be displayed in the first level of the navbar, they potentially have children which are going to be IRouteNavigationConfig.
 */
// eslint-lint-disable-next-line jsdoc/require-jsdoc
export const navigationsConfig: IRouteNavigationConfig</**
 *
 */
{}>[] = [
    InstallerListConfig[0],
    CustomerListConfig[0],
    InstallerRequestsConfig[0],
    InstallationsRequestsConfig[0],
    LeadExchangesConfig[0],
]
