import React from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { email, requiredBuilder, Form } from 'src/common/react-platform-components'
import { TextField, PasswordField, ButtonLoader } from 'src/common/ui-kit'
import { useLogin } from 'src/modules/User/Login/hooks'

/**
 * Form used for user login. This component is based on form hooks.
 *
 * @param root0 N/A.
 * @param root0.loginHook React hook that handles all logical treatment.
 * @returns Login form component.
 */
export const LoginForm = ({ loginHook = useLogin }) => {
    const { isLoginInProgress, onSubmit } = loginHook()
    const { formatMessage } = useIntl()

    return (
        // {/* "handleSubmit" will validate your inputs before invoking "onSubmit" */}
        <Form onSubmit={onSubmit}>
            <div className="flex flex-col justify-center w-full">
                {/* register your input into the hook by invoking the "register" function */}
                <TextField name="email" label="Email" validateFunctions={[requiredBuilder(), email()]} />
                <PasswordField name="password" label="Mot de passe" validateFunctions={[requiredBuilder()]} />
                <div className="mx-auto">
                    <Link to="/forgot-password">
                        {formatMessage({
                            id: 'Mot de passe oublié?',
                            defaultMessage: 'Mot de passe oublié?',
                        })}
                    </Link>
                </div>
                <ButtonLoader
                    variant="contained"
                    color="primary"
                    className="w-224 mx-auto mt-16"
                    aria-label="LOG IN"
                    inProgress={isLoginInProgress}
                    type="submit"
                >
                    {formatMessage({
                        id: 'Se connecter',
                        defaultMessage: 'Se connecter',
                    })}
                </ButtonLoader>
            </div>
        </Form>
    )
}
