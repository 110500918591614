import React, { useState, useEffect } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import Icon from '@mui/material/Icon'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { INotification, useNotifications, notificationStatus } from './notificationsHooks'
import Divider from '@mui/material/Divider'
import CircularProgress from '@mui/material/CircularProgress'
import { CardActionArea } from '@mui/material'

dayjs.extend(utc)

/**
 * Notification Card.
 *
 * @param props N/A.
 * @param props.notification Notification Object.
 * @returns Notifications Card Component.
 */
const NotificationCard = ({
    notification,
}: /**
 *
 */
{
    /**
     *
     */
    notification: INotification
}) => {
    const { isNotificationsLoading, archiveNotification, setReadNotification } = useNotifications()
    const [notificationDetail, setNotificationDetail] = useState(notification)

    const [isNotificationCardMounted, setIsNotificationCardMounted] = useState(false)

    useEffect(() => {
        if (isNotificationCardMounted === false) {
            if (notificationDetail.status === notificationStatus.NEW) setReadNotification(notificationDetail.id)
            setIsNotificationCardMounted(true)
        }
    }, [setReadNotification, notificationDetail, isNotificationCardMounted])

    if (notificationDetail.status === notificationStatus.ARCHIVED) return <></>

    return (
        <Card
            className="mb-16 flex items-center rounded-16 shadow min-h-64"
            elevation={0}
            onClick={() => (window.location.href = notification.redirectTo)}
            sx={
                notificationDetail.status === notificationStatus.NEW
                    ? {
                          backgroundColor: 'lightyellow',
                      }
                    : {}
            }
        >
            <CardActionArea className="relative w-full rounded-16 min-h-64 p-20 shadow">
                <Typography variant="subtitle2" className="text-bold">
                    {notification.title}
                </Typography>
                <Divider className="my-12" />

                <Typography component="div">{notification.content}</Typography>
                {isNotificationsLoading ? (
                    <CircularProgress className="top-0 right-0 absolute p-8" />
                ) : (
                    <IconButton
                        disableRipple
                        className="top-0 right-0 absolute p-8"
                        color="inherit"
                        size="small"
                        onClick={async (e: React.SyntheticEvent) => {
                            e.stopPropagation()
                            const archivedNotification = await archiveNotification(notificationDetail.id)
                            setNotificationDetail(archivedNotification)
                        }}
                    >
                        <Icon className="text-12 opacity-75" color="inherit">
                            close
                        </Icon>
                    </IconButton>
                )}
                <Typography className="bottom-0 right-0 absolute p-8 text-8 opacity-75">
                    {dayjs.utc(notification.createdAt).local().format('DD/MM/YYYY HH:mm')}
                </Typography>
            </CardActionArea>
        </Card>
    )
}
export default NotificationCard
