import { rest } from 'msw'
import { AUTH_BASE_URL } from 'src/modules/User/configs'
import { IUserRegister, IUser } from 'src/modules/User/model'
import { getPaginationFromElementList } from 'src/mocks/utils'
import { SnakeCasedProperties, SnakeCasedPropertiesDeep } from 'type-fest'
import { CREATED_AT_DATA } from 'src/mocks/handlers/customers'
import { updateInstalerRequestInputType } from 'src/modules/RegistrationRequests/InstallerRequests/installerRequests'
import { API_RESOURCES_URL } from 'src/configs'

/**
 * Success mail to send for login.
 */
export const TEST_SUCCESS_MAIL = 'user@success.com'

/**
 * Wrong role mail to send for login.
 */
export const TEST_WRONG_ROLE_MAIL = 'user@enduser.com'

/**
 *
 */
export const TEST_FAIL_ID = 23

/**
 * Success user to send in response.
 */
export const TEST_SUCCESS_USER = {
    id: '1',
    siren: '123456789',
    company_name: 'company name',
    first_name: 'Orlando',
    last_name: 'Jackson',
    email: TEST_SUCCESS_MAIL,
    phone: '+33 1 23 45 67 89',
    address: {
        name: 'Apt. 556, Gwenborough. 92998-3874',
        city: 'Gwenborough',
        zip_code: '92998-3874',
        address_addition: 'testFDF',
        country: 'France',
        lat: 45.706877,
        lng: 5.011265,
        extra_data: {},
    },
    role: 'installer',
    is_active: true,
    is_verified: true,
    is_super_user: false,
    updated_at: CREATED_AT_DATA,
    created_at: CREATED_AT_DATA,
}

let TEST_USER_WRONG_ROLE = {
    ...TEST_SUCCESS_USER,
    id: '2',
    role: 'enduser',
    email: TEST_WRONG_ROLE_MAIL,
}

/**
 * Mock Installer List, which represents users that doesn't have installers.
 */
export var TEST_SUCCESS_INSTALLERS = [TEST_SUCCESS_USER]
// eslint-disable-next-line jsdoc/require-jsdoc
export const userEndpoints = [
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.get(`${AUTH_BASE_URL}/users`, (req, res, ctx) => {
        const TEST_SUCCESS_USERS = getPaginationFromElementList<SnakeCasedPropertiesDeep<IUser>>(
            req,
            TEST_SUCCESS_INSTALLERS,
            ['last_name', 'first_name', 'email'],
        )
        if (TEST_SUCCESS_USER) return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_USERS))
        else return res(ctx.status(400), ctx.delay(1000))
    }),
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<FormData>(`${AUTH_BASE_URL}/auth/jwt/login`, (req, res, ctx) => {
        const username = req.body.get('username')
        if (username === TEST_SUCCESS_MAIL) {
            return res(
                ctx.status(200),
                ctx.json({ ...TEST_SUCCESS_USER, authentication_token: '123456', token_type: 'bearer' }),
            )
        } else if (username === TEST_WRONG_ROLE_MAIL) {
            return res(
                ctx.status(200),
                ctx.json({ ...TEST_USER_WRONG_ROLE, authentication_token: '123456', token_type: 'bearer' }),
            )
        } else {
            return res(ctx.status(400))
        }
    }),
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<IUserRegister>(`${AUTH_BASE_URL}/auth/register`, (req, res, ctx) => {
        const { email } = req.body
        if (email === TEST_SUCCESS_MAIL) {
            return res(ctx.status(200))
        } else {
            return res(ctx.status(400))
        }
    }),
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<string>(`${AUTH_BASE_URL}/change`, (req, res, ctx) => {
        const email = req.body
        if (email === TEST_SUCCESS_MAIL) {
            return res(ctx.status(200))
        } else {
            return res(ctx.status(401))
        }
    }),
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<SnakeCasedProperties<updateInstalerRequestInputType>>(
        `${AUTH_BASE_URL}/users/activate-inscription-request`,
        (req, res, ctx) => {
            const { inscription_accepted, user_id } = req.body
            if (user_id === TEST_SUCCESS_USER.id) {
                TEST_SUCCESS_INSTALLERS[0].is_verified = inscription_accepted
                return res(ctx.status(200), ctx.json(TEST_SUCCESS_INSTALLERS[0]))
            } else {
                return res(ctx.status(401))
            }
        },
    ),
    rest.patch<IUser>(`${AUTH_BASE_URL}/users/me`, (req, res, ctx) => {
        const { email } = req.body
        if (email === TEST_SUCCESS_USER.email) {
            return res(ctx.status(200), ctx.json({ ...TEST_SUCCESS_USER, ...req.body }))
        } else {
            return res(ctx.status(400), ctx.delay(1000), ctx.json({ detail: 'UPDATE_USER_EMAIL_ALREADY_EXISTS' }))
        }
    }),
    // Update Installer patch request
    // TODO Remove this code and change it to a more efficient one. updateInstaller is using this endpoint differently then UpdateUser.
    rest.patch</**
     *
     */
    {
        /**
         *
         */
        phone: string
        /**
         *
         */
        email: string
        /**
         *
         */
        address: typeof TEST_SUCCESS_USER.address
    }>(`${AUTH_BASE_URL}/users/:id`, (req, res, ctx) => {
        if (typeof req.body === 'string') {
            //For the Update in Auth Request, Model.test, ..etc.
            const requestBody = JSON.parse(req.body)
            if (requestBody.email === TEST_SUCCESS_MAIL) {
                return res(ctx.status(200), ctx.json(requestBody))
            } else {
                return res(ctx.status(401))
            }
        } else {
            // For Updating Installer.
            if (req.body.email !== 'error') {
                let newInstaller = {
                    ...TEST_SUCCESS_USER,
                    ...req.body,
                    created_at: CREATED_AT_DATA,
                }
                TEST_SUCCESS_INSTALLERS[0] = newInstaller
                return res(ctx.status(200), ctx.delay(1000), ctx.json(newInstaller))
            } else {
                return res(ctx.status(401), ctx.delay(1000))
            }
        }
    }),
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.get<string>(`${AUTH_BASE_URL}/users/me`, (req, res, ctx) => {
        return res(ctx.status(200), ctx.json(TEST_SUCCESS_USER))
    }),
    // Get selected user by id.
    rest.get(`${AUTH_BASE_URL}/users/:id`, (req, res, ctx) => {
        const { id } = req.params
        if (parseInt(id)) {
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_INSTALLERS[0]))
        } else {
            return res(ctx.status(404), ctx.delay(1000), ctx.json({ message: 'Customer not to be found!' }))
        }
    }),
    // Forgot password endpoint
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<{ email: string }>(`${AUTH_BASE_URL}/auth/forgot-password`, (req, res, ctx) => {
        const { email } = req.body
        if (email === TEST_SUCCESS_MAIL) {
            return res(ctx.status(200), ctx.delay(1000))
        } else {
            return res(ctx.status(404), ctx.delay(1000))
        }
    }),

    // Reset password endpoint
    // eslint-disable-next-line jsdoc/require-jsdoc
    rest.post<{ password: string; token: string }>(`${AUTH_BASE_URL}/auth/reset-password`, (req, res, ctx) => {
        const { password, token } = req.body
        if (password && token) {
            return res(ctx.status(200), ctx.delay(1000))
        } else {
            return res(ctx.status(404), ctx.delay(1000))
        }
    }),

    rest.delete(`${API_RESOURCES_URL}/installers/:installerId`, (req, res, ctx) => {
        if (req.params.installerId === '-1') {
            return res(ctx.status(404), ctx.delay(1000))
        } else {
            return res(ctx.status(200), ctx.delay(1000))
        }
    }),
]
