import React from 'react'
import { useInstallerDetails } from 'src/modules/DetailsPages/InstallerDetails/installerDetailsHooks'
import Typography from '@mui/material/Typography'
import { email, requiredBuilder, Form, regex } from 'src/common/react-platform-components'
import { ButtonLoader, TextField } from 'src/common/ui-kit'
import { Icon, useTheme } from '@mui/material'
import { useIntl } from 'src/common/react-platform-translation'
import { GoogleMapsAddressAutoCompleteField } from 'src/common/ui-kit/form-fields/GoogleMapsAddressAutoComplete/GoogleMapsAddressAutoCompleteField'
import { UserAddressType } from 'src/modules/User/model'
import { ButtonResetForm } from 'src/common/ui-kit/components/ButtonResetForm/ButtonResetForm'
import { PhoneNumber } from 'src/common/ui-kit/form-fields/phoneNumber/PhoneNumber'
import EditButton from 'src/modules/utils/EditButton'
import { useConfirm } from 'material-ui-confirm'
import TypographyFormatMessage from 'src/common/ui-kit/components/TypographyFormatMessage/TypographyFormatMessage'
import { InstallerDetailsFormProps } from 'src/modules/DetailsPages/InstallerDetails/InstallerRecordTab/installerDetailsForm.d'
import DeleteButton from 'src/modules/utils/DeleteButton'
import { sirenFieldRegex } from 'src/modules/User/Register/utils'

/**
 * Installer Detail Form.
 *
 * @param props N/A.
 * @param props.isEdit Indicating if the form is editable or not.
 * @param props.installerDetails The current installer selected as prop.
 * @param props.loadInstallerDetails Handler to be called when updating installer infos, so that we load the installerDetails again.
 * @param props.disableEditForm Handler to be called when click on Annuler and Enregistrer success so that form is not editable.
 * @param props.editInstallerDetails Edit installer details function.
 * @param props.deleteInstaller Delete installer function.
 * @returns InstallerDetailsForm.
 */
// TODO **** ADD REFACTO TO HANDLE THE FORM RECORD IN INSTALLER AND CUSTOMER DETAILS.
const InstallerDetailsForm = ({
    isEdit,
    installerDetails,
    loadInstallerDetails,
    disableEditForm,
    editInstallerDetails,
    deleteInstaller,
}: InstallerDetailsFormProps) => {
    const { isInstallerInProgress, updateInstaller } = useInstallerDetails(installerDetails.id)

    const formInitialValues = {
        firstName: installerDetails.firstName,
        lastName: installerDetails.lastName,
        companyName: installerDetails.companyName,
        siren: installerDetails.siren,
        email: installerDetails.email,
        phone: installerDetails.phone,
        address: installerDetails.address,
    }
    const { formatMessage } = useIntl()
    const theme = useTheme()

    const openDeleteInstallerDialog = useConfirm()

    const deleteTexts = [
        'Vous êtes sur le point de supprimer un compte installateur.',
        'Attention, toutes les données relatives à cet installateur seront supprimées',
        'Êtes-vous sûr de vouloir continuer ?',
    ]

    /**
     * Function to call deleteInstaller.
     */
    const onHandlerInstallerDelete = async () => {
        await deleteInstaller()
    }
    /**
     * Function that handles delete installer.
     */
    const onDeleteInstallerPopupHandler = async () => {
        await openDeleteInstallerDialog({
            title: '',
            dialogProps: {
                PaperProps: {
                    style: {
                        // MUI snackbar red color, used as a global error color.
                        background: theme.palette.error.main,
                    },
                },
            },
            description: (
                <>
                    {deleteTexts.map((text) => (
                        <TypographyFormatMessage className="text-16 md:text-20 text-center text-white">
                            {text}
                        </TypographyFormatMessage>
                    ))}
                </>
            ),

            confirmationText: (
                <TypographyFormatMessage
                    className="text-13 md:text-16 font-medium text-white"
                    onClick={onHandlerInstallerDelete}
                >
                    Continuer
                </TypographyFormatMessage>
            ),
            cancellationText: (
                <TypographyFormatMessage className="text-13 md:text-16 font-medium text-white">
                    Annuler
                </TypographyFormatMessage>
            ),
        })
    }

    return (
        <Form
            defaultValues={formInitialValues}
            onSubmit={async (
                // TODO Handle placeId prop with a more efficient way.
                // eslint-disable-next-line jsdoc/require-jsdoc
                values: Omit<typeof formInitialValues, 'address'> & {
                    // eslint-disable-next-line jsdoc/require-jsdoc
                    address: UserAddressType & { placeId?: string }
                },
            ) => {
                // If nothing change no need to update installer.
                if (
                    values.firstName === installerDetails.firstName &&
                    values.lastName === installerDetails.lastName &&
                    values.email === installerDetails.email &&
                    values.phone === installerDetails.phone &&
                    values.address.name === installerDetails.address.name &&
                    values.address.addressAddition === installerDetails.address.addressAddition &&
                    values.companyName === installerDetails.companyName &&
                    values.siren === installerDetails.siren
                ) {
                    disableEditForm()
                    return
                }
                delete values.address.placeId
                await updateInstaller({ ...values })
                loadInstallerDetails()
                disableEditForm()
            }}
        >
            <div className="flex mb-20 items-start justify-between">
                <div className="flex mb-10 sm:mb-0">
                    <Icon className="sm:inline text-20 sm:text-32 mr-8">contacts</Icon>
                    <Typography className="text-12 sm:text-18 font-semibold">
                        {formatMessage({ id: 'Coordonnées', defaultMessage: 'Coordonnées' })}
                    </Typography>
                </div>
                <div className="flex justify-between items-center">
                    {editInstallerDetails && <EditButton onClick={editInstallerDetails} isEdit={isEdit} />}
                    <DeleteButton onDelete={onDeleteInstallerPopupHandler} />
                </div>
            </div>
            <TextField
                name="lastName"
                label={formatMessage({
                    id: 'Nom',
                    defaultMessage: 'Nom',
                })}
                validateFunctions={[requiredBuilder()]}
                variant="outlined"
                style={{ marginBottom: '20px' }}
                disabled={!isEdit}
            />
            <TextField
                name="firstName"
                label={formatMessage({
                    id: 'Prénom',
                    defaultMessage: 'Prénom',
                })}
                validateFunctions={[requiredBuilder()]}
                variant="outlined"
                style={{ marginBottom: '20px' }}
                disabled={!isEdit}
            />
            <TextField
                name="companyName"
                label={formatMessage({
                    id: 'Raison sociale',
                    defaultMessage: 'Raison sociale',
                })}
                validateFunctions={[requiredBuilder()]}
                variant="outlined"
                style={{ marginBottom: '20px' }}
                disabled={!isEdit}
            />
            <TextField
                name="siren"
                label={formatMessage({
                    id: 'Siren',
                    defaultMessage: 'Siren',
                })}
                validateFunctions={[
                    requiredBuilder(),
                    regex(sirenFieldRegex, 'Le numéro Siren doit être composé de 9 chiffres'),
                ]}
                variant="outlined"
                style={{ marginBottom: '20px' }}
                disabled={!isEdit}
            />
            <PhoneNumber
                disabled={!isEdit}
                type="tel"
                name="phone"
                label={formatMessage({
                    id: 'Numéro de téléphone',
                    defaultMessage: 'Numéro de téléphone',
                })}
                sx={{ margin: '0 0 20px 0' }}
                validateFunctions={[requiredBuilder()]}
            />
            <GoogleMapsAddressAutoCompleteField
                disabled={!isEdit}
                name="address"
                validateFunctions={[requiredBuilder()]}
            />
            <TextField
                disabled={!isEdit}
                name="email"
                label={formatMessage({
                    id: 'Email',
                    defaultMessage: 'Email',
                })}
                placeholder={formatMessage({
                    id: 'Quel est son adresse email?',
                    defaultMessage: 'Quel est son adresse email?',
                })}
                validateFunctions={[requiredBuilder(), email()]}
            />
            {isEdit && (
                <div>
                    <ButtonResetForm initialValues={formInitialValues} onClickButtonReset={disableEditForm} />
                    <ButtonLoader inProgress={isInstallerInProgress} variant="contained" type="submit" className="ml-8">
                        {formatMessage({ id: 'Enregistrer', defaultMessage: 'Enregistrer' })}
                    </ButtonLoader>
                </div>
            )}
        </Form>
    )
}

export default InstallerDetailsForm
