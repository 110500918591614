import React, { FC } from 'react'
import MapElementList from 'src/common/ui-kit/components/MapElementList'
import PageSimple from 'src/common/ui-kit/fuse/components/PageSimple'
import PageHeaderSearch from 'src/common/ui-kit/fuse/components/PageHeaderSearch/PageHeaderSearch'
import { IUser } from 'src/modules/User'
import { useInstallerList } from 'src/modules/MapPages/InstallerList/installerListHook'
import InstallerCard from 'src/modules/MapPages/InstallerList/components/InstallerCard'
import { GOOGLE_MAPS_API_KEY } from 'src/configs'

/**
 * Customers component props.
 */
export interface InstallerListProps {
    /**
     * Logo to dislay.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Url of the logo.
         */
        url: string
    }
}

/**
 * InstallerList view component containing Map & InstallerList Items with search filter.
 *
 * @returns InstallerList page component.
 */
const InstallerList: FC<InstallerListProps> = (): JSX.Element => {
    const {
        elementList: installerList,
        loadingInProgress: isInstallerInProgress,
        updateFilters,
        loadMoreElements: loadMoreInstallers,
        noMoreElementToLoad: noMoreInstallerToLoad,
    } = useInstallerList(10)

    return (
        <PageSimple
            header={
                <PageHeaderSearch headerIconName="map" headerTitle="Mes Installateurs" onSearchClick={updateFilters} />
            }
            content={
                // eslint-disable-next-line jsdoc/require-jsdoc
                <MapElementList<IUser, {}>
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    data={installerList}
                    loadingData={isInstallerInProgress}
                    loadMoreData={loadMoreInstallers}
                    elementCard={InstallerCard}
                    noMoreDataToLoad={noMoreInstallerToLoad}
                />
            }
        />
    )
}

export default InstallerList
