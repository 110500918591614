import React from 'react'
import { email, requiredBuilder, Form } from 'src/common/react-platform-components'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import RoomIcon from '@mui/icons-material/Room'
import EmailIcon from '@mui/icons-material/Email'
import PhoneIcon from '@mui/icons-material/Phone'
import { useIntl } from 'src/common/react-platform-translation'
import { ButtonLoader, TextField } from 'src/common/ui-kit'
import { customerAddressType, interestValuesType } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { addCustomerInputType, userSpecificitiesT } from 'src/modules/MapPages/CustomerList/customerListHook'
import DialogActions from '@mui/material/DialogActions'
import {
    getAdditionAddressValue,
    getDisplayedLabelFromValueForCustomerAddress,
    getFormattedValueForCustomerAddress,
    getPlaceIdFromValueForCustomerAddress,
    GoogleMapsAddressAutoCompleteFieldFormCustomer,
    setAdditionAddressValue,
} from 'src/modules/MapPages/CustomerList/components'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import TelegramIcon from '@mui/icons-material/Telegram'
import ContactsIcon from '@mui/icons-material/Contacts'
import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'
import { styled } from '@mui/material/styles'
import { useToggle } from 'react-use'
import TypographyFormatMessage from 'src/common/ui-kit/components/TypographyFormatMessage/TypographyFormatMessage'
import { PhoneNumber } from 'src/common/ui-kit/form-fields/phoneNumber/PhoneNumber'

/**
 * Component Utility To reduce code of multiple Grid of (Icon + Field).
 *
 * @param props N/A.
 * @param props.icon Icon Component.
 * @param props.field Field Component.
 * @returns Grid Component that has Icon & Field.
 */
const GridComponent = (props: /**
 *
 */
{
    /**
     *
     */
    icon: JSX.Element
    /**
     *
     */
    field: JSX.Element
}) => {
    const { icon, field } = props
    return (
        <div className="flex">
            <div className="min-w-48 pt-20">{icon} </div>
            <div className="w-full">{field}</div>
        </div>
    )
}

const stepsBottom = [
    {
        text: 'Vous créez un utilisateur',
        icon: <AddCircleIcon className="text-5xl" />,
    },
    {
        text: 'Il reçoit un email pour se connecter à la plateforme',
        icon: <TelegramIcon className="text-5xl" />,
    },
    {
        text: 'Vous pouvez le suivre',
        icon: <ContactsIcon className="text-5xl" />,
    },
]

/**
 * Round Circke Style.
 */
const RoundCircle = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '1rem',
    padding: '1.5rem',
    backgroundColor: theme.palette.grey[400],
    transform: `translate(${0}px, ${-2}rem)`,
}))

/**
 * Form for the AddCustomer by the staller.
 *
 * @param props N/A.
 * @param props.onAfterCreateUpdateDeleteSuccess Handler  when addCustomer success.
 * @param props.addCustomer Submit addCustomer.
 * @param props.isCustomerInProgress Progress for customer.
 * @returns AddCustomer Form.
 */
const AddCustomerForm = ({
    onAfterCreateUpdateDeleteSuccess,
    addCustomer,
    isCustomerInProgress,
}: /**
 *
 */
{
    /**
     *
     */
    onAfterCreateUpdateDeleteSuccess: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    addCustomer: (input: addCustomerInputType) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    isCustomerInProgress: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const { formatMessage } = useIntl()

    // TODO We should use the form fields to retrieve the data instead of using this state as an external source of data.
    const [userSpecificities, setUserSpecificities] = React.useState({
        hasSensor: false,
        isInterestedByInstallation: false,
        isInterestedBySupplier: false,
    })
    /**
     * Toggle isFinalStep between "Suivant" & "Retour".
     */
    const [isFinalStep, setIsFinalStep] = useToggle(false)

    return (
        <Form
            onSubmit={async (
                // eslint-disable-next-line jsdoc/require-jsdoc
                data: Omit<addCustomerInputType, 'address'> & { address: customerAddressType & { placeId?: string } },
            ) => {
                delete data.address.placeId
                const formattedUserSpecificities: userSpecificitiesT = {
                    hasSensor: userSpecificities.hasSensor,
                    interests: [
                        userSpecificities.isInterestedByInstallation && 'installation',
                        userSpecificities.isInterestedBySupplier && 'supplier',
                    ].filter(Boolean) as interestValuesType[],
                }
                // TODO addCustomer should receive only data argument
                await addCustomer({ ...data, ...formattedUserSpecificities })
                onAfterCreateUpdateDeleteSuccess()
            }}
        >
            {!isFinalStep ? (
                <div className="h-full flex flex-col justify-between">
                    <div className="flex flex-col mb-28">
                        <div className="flex flex-col">
                            <TypographyFormatMessage>
                                Est-ce que cet utilisateur possède déjà une installation solaire ?
                            </TypographyFormatMessage>
                            <div className="flex flex-row space-x-8 items-center">
                                <TypographyFormatMessage
                                    className={`${!userSpecificities.hasSensor ? 'font-bold' : ''}`}
                                >
                                    Non
                                </TypographyFormatMessage>
                                <Switch
                                    name="has_sensor"
                                    checked={userSpecificities.hasSensor}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserSpecificities({ ...userSpecificities, hasSensor: event.target.checked })
                                    }}
                                />
                                <TypographyFormatMessage
                                    className={`${userSpecificities.hasSensor ? 'font-bold' : ''}`}
                                >
                                    Oui
                                </TypographyFormatMessage>
                            </div>
                        </div>

                        <div className="flex flex-col mb-10">
                            <TypographyFormatMessage>
                                Est-ce que cet utilisateur peut être intéressé par une installation ?
                            </TypographyFormatMessage>
                            <div className="flex flex-row space-x-8 items-center">
                                <TypographyFormatMessage
                                    className={`${!userSpecificities.isInterestedByInstallation ? 'font-bold' : ''}`}
                                >
                                    Non
                                </TypographyFormatMessage>
                                <Switch
                                    name="is_interested_by_installation"
                                    checked={userSpecificities.isInterestedByInstallation}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserSpecificities({
                                            ...userSpecificities,
                                            isInterestedByInstallation: event.target.checked,
                                        })
                                    }}
                                    data-testid="switch-installation"
                                />
                                <TypographyFormatMessage
                                    className={`${userSpecificities.isInterestedByInstallation ? 'font-bold' : ''}`}
                                >
                                    Oui
                                </TypographyFormatMessage>
                            </div>
                        </div>

                        <div className="flex flex-col mb-10">
                            <TypographyFormatMessage>
                                Est-ce que cet utilisateur peut être intéressé par un changement de fournisseur ?
                            </TypographyFormatMessage>
                            <div className="flex flex-row space-x-8 items-center">
                                <TypographyFormatMessage
                                    className={`${!userSpecificities.isInterestedBySupplier ? 'font-bold' : ''}`}
                                >
                                    Non
                                </TypographyFormatMessage>
                                <Switch
                                    name="is_interested_by_supplier"
                                    checked={userSpecificities.isInterestedBySupplier}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setUserSpecificities({
                                            ...userSpecificities,
                                            isInterestedBySupplier: event.target.checked,
                                        })
                                    }}
                                />
                                <TypographyFormatMessage
                                    className={`${userSpecificities.isInterestedBySupplier ? 'font-bold' : ''}`}
                                >
                                    Oui
                                </TypographyFormatMessage>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row mt-28">
                        <Stepper alternativeLabel activeStep={-1} className="w-full">
                            {stepsBottom.map((step) => {
                                return (
                                    <Step key={step.text}>
                                        <StepLabel
                                            StepIconComponent={() => (
                                                <>
                                                    <RoundCircle>{step.icon}</RoundCircle>
                                                </>
                                            )}
                                        >
                                            {step.text}
                                        </StepLabel>
                                    </Step>
                                )
                            })}
                        </Stepper>
                    </div>
                    <div className="flex flex-row justify-center p-10">
                        <div className="px-16 mt-4">
                            <Button variant="contained" onClick={setIsFinalStep}>
                                <TypographyFormatMessage>Suivant</TypographyFormatMessage>
                            </Button>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <GridComponent
                        icon={<PermIdentityIcon />}
                        field={
                            <TextField
                                name="lastName"
                                label={formatMessage({
                                    id: 'Nom',
                                    defaultMessage: 'Nom',
                                })}
                                placeholder={formatMessage({
                                    id: 'Veuillez entrer le nom',
                                    defaultMessage: 'Veuillez entrer le nom',
                                })}
                                validateFunctions={[requiredBuilder()]}
                            />
                        }
                    />
                    <GridComponent
                        icon={<PermIdentityIcon />}
                        field={
                            <TextField
                                name="firstName"
                                label={formatMessage({
                                    id: 'Prénom',
                                    defaultMessage: 'Prénom',
                                })}
                                placeholder={formatMessage({
                                    id: 'Veuillez entrer le prénom',
                                    defaultMessage: 'Veuillez entrer le prénom',
                                })}
                                validateFunctions={[requiredBuilder()]}
                            />
                        }
                    />

                    <GridComponent
                        icon={<RoomIcon />}
                        field={
                            <GoogleMapsAddressAutoCompleteFieldFormCustomer
                                name="address"
                                validateFunctions={[requiredBuilder()]}
                                valueFunctionsOverride={{
                                    getFormattedValue: getFormattedValueForCustomerAddress,
                                    getDisplayedLabelFromValue: getDisplayedLabelFromValueForCustomerAddress,
                                    getPlaceIdFromValue: getPlaceIdFromValueForCustomerAddress,
                                    getAddressAdditionFromValue: getAdditionAddressValue,
                                    setAddressAdditionInValue: setAdditionAddressValue,
                                }}
                            />
                        }
                    />
                    <GridComponent
                        icon={<PhoneIcon />}
                        field={
                            <PhoneNumber
                                type="tel"
                                name="phone"
                                label={formatMessage({
                                    id: 'Numéro de téléphone',
                                    defaultMessage: 'Numéro de téléphone',
                                })}
                                sx={{ margin: '0 0 20px 0' }}
                                validateFunctions={[requiredBuilder()]}
                            />
                        }
                    />
                    <GridComponent
                        icon={<EmailIcon />}
                        field={
                            <TextField
                                name="email"
                                label={formatMessage({
                                    id: 'Email',
                                    defaultMessage: 'Email',
                                })}
                                placeholder={formatMessage({
                                    id: 'Quel est son adresse email?',
                                    defaultMessage: 'Quel est son adresse email?',
                                })}
                                validateFunctions={[requiredBuilder(), email()]}
                            />
                        }
                    />
                    <DialogActions className="justify-center p-4 pb-16">
                        <div className="px-16">
                            <Button variant="outlined" onClick={setIsFinalStep}>
                                <TypographyFormatMessage>Retour</TypographyFormatMessage>
                            </Button>
                        </div>
                        <div className="px-16">
                            <ButtonLoader inProgress={isCustomerInProgress} type="submit">
                                <TypographyFormatMessage>Ajouter</TypographyFormatMessage>
                            </ButtonLoader>
                        </div>
                    </DialogActions>
                </>
            )}
        </Form>
    )
}

export default AddCustomerForm
