import CustomersList, { CustomersListProps } from 'src/modules/MapPages/CustomerList'
import { authTypes } from 'src/common/react-platform-components'
import { IRouteNavigationConfig } from 'src/routes'

const URL_CUSTOMER_LIST = '/customers'

const CustomerListConfig = [
    {
        path: URL_CUSTOMER_LIST,
        component: CustomersList,
        auth: { authType: authTypes.loginRequired },
        settings: {
            layout: {
                navbar: {
                    UINavbarItem: {
                        id: 'customers',
                        label: 'Mes Utilisateurs',
                        labelAbbreviation: 'Utilisateurs',
                        type: 'group',
                        iconLabel: 'group',
                        url: URL_CUSTOMER_LIST,
                    },
                },
            },
        },
    } as IRouteNavigationConfig<CustomersListProps>,
]

export { CustomerListConfig, URL_CUSTOMER_LIST }
