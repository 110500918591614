import { authTypes } from 'src/common/react-platform-components'
import InstallationsRequests from 'src/modules/InstallationsRequests'
import { IRouteNavigationConfig } from 'src/routes'

const URL_INSTALLATIONS_REQUESTS = '/installations-requests'
/**
 * Interface .
 *
 */
export interface InstallationRequestsProps {
    /**
     * Logo to dislay.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Url of the logo.
         */
        url: string
    }
}
const InstallationsRequestsConfig = [
    {
        path: URL_INSTALLATIONS_REQUESTS,
        component: InstallationsRequests,
        auth: { authType: authTypes.loginRequired, roles: ['installer'] },
        settings: {
            layout: {
                navbar: {
                    UINavbarItem: {
                        id: 'installation_requests',
                        label: "Demandes d'installations",
                        labelAbbreviation: 'Installations',
                        type: 'item',
                        iconLabel: 'dynamic_form_sharp',
                        url: URL_INSTALLATIONS_REQUESTS,
                    },
                },
            },
        },
    } as IRouteNavigationConfig<InstallationRequestsProps>,
]

export { InstallationsRequestsConfig, URL_INSTALLATIONS_REQUESTS }
