import { CUSTOMER_API } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { IMeterT } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/meters.d'
import { formatMessageType, useIntl } from 'src/common/react-platform-translation'
import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { searchFilterType } from 'src/modules/utils'
import { axios } from 'src/common/react-platform-components'
import { API_RESOURCES_URL } from 'src/configs'
import { useSnackbar } from 'notistack'
import { useState } from 'react'

// eslint-disable-next-line jsdoc/require-jsdoc
export const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Erreur lors du chargement des compteurs',
        defaultMessage: 'Erreur lors du chargement des compteurs',
    })
}

/**
 * Meters url.
 *
 * @param customerId The customerId of the meters.
 * @returns Meters base url.
 */
export const METERS_BASE_URL = (customerId: number) => `${CUSTOMER_API}/${customerId}/pdl`

/**
     `* Hooks for Metes List in Customer Details.
     *
     * @param customerId Customer ID to generate the base url.
     * @param sizeParam Indicates the default sizeParam for loadElementList.
     * @returns UseMetersList Hook.
     */
export const useMetersList = (customerId: number, sizeParam?: number) =>
    // eslint-disable-next-line jsdoc/require-jsdoc
    BuilderUseElementList<IMeterT, undefined, searchFilterType>({
        API_ENDPOINT: METERS_BASE_URL(customerId),
        sizeParam: sizeParam,
        snackBarMessage0verride: { loadElementListError },
    })(true, { is_verified: false })

/**
 * Hook to download CSV.
 *
 * @returns DownloadCSV hook.
 */
export const useDownloadCSV = () => {
    const [isDownloadLoading, setIsDownloadLoading] = useState(false)
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()

    /**
     * Download CSV function.
     *
     * @param housingId HousingId related to PDL.
     */
    const getCSV = async (housingId: string) => {
        setIsDownloadLoading(true)
        try {
            const response = await axios.get(`${API_RESOURCES_URL}/get_last_year_consumption_csv/${housingId}`, {
                responseType: 'blob',
            })
            if (response.status === 200) {
                enqueueSnackbar(
                    formatMessage({
                        id: 'Fichier CSV en cours de téléchargement',
                        defaultMessage: 'Fichier CSV en cours de téléchargement',
                    }),
                    { variant: 'success', autoHideDuration: 5000 },
                )
            }

            const blob = new Blob([response.data], { type: 'application/json' })
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = `${new Date().toDateString()}.csv`
            link.click()
            URL.revokeObjectURL(link.href)
        } catch (error: any) {
            setIsDownloadLoading(false)
            enqueueSnackbar(
                formatMessage({
                    id: `Une Erreur est survenue`,
                    defaultMessage: `Une Erreur est survenue`,
                }),
                { variant: 'error', autoHideDuration: 5000 },
            )
        }
        setIsDownloadLoading(false)
    }

    return { getCSV, isDownloadLoading }
}
