import { Icon, IconButton } from '@mui/material'
import { motion } from 'framer-motion'
import { DeleteButtonProps } from 'src/modules/utils/DeleteButton/props.d'

/**
 * Delete Button component.
 *
 * @param param0 N/A.
 * @param param0.onDelete Function callback that handles delete.
 * @returns Delete button JSX.
 */
export default function DeleteButton({ onDelete }: DeleteButtonProps) {
    return (
        <motion.div initial={{ y: -20, opacity: 0 }} animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}>
            <IconButton onClick={onDelete} data-testid="delete-btn">
                <Icon color="error">delete</Icon>
            </IconButton>
        </motion.div>
    )
}
