// politique de confidentialité.
const urlPolitiqueConfidentialité = 'https://www.ned-energie.fr/mentions-legales'

// Conditions Générales d’Utilisation : it's the same link as -> 'Politique de Confidentialité'
const urlLegalNotice = 'https://www.ned-energie.fr/mentions-legales'

// Condition Général de Vente
const urlCGV = 'https://drive.google.com/file/d/1262qhcvm3tvmC3VqCe5B6xfT8lfJgmEV/view'

export { urlPolitiqueConfidentialité, urlLegalNotice, urlCGV }

/**
 * Siren regex that allow only digits and up to maximum 9.
 */
export const sirenFieldRegex = /^\d{9}$/.source
