import React, { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useIntl } from 'src/common/react-platform-translation'
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { IMeterT } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/meters'
import 'src/modules/DetailsPages/CustomerDetails/InstallationTab/SelectMeters/SelectMeters.scss'
import { ISelectMeters } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/SelectMeters/selectMeters.d'

/**
 * Select Input that displays all the meters.
 *
 * @param root0 N/A.
 * @param root0.metersList List of meters.
 * @param root0.handleOnMeterSelect Handling function when we change values.
 * @returns SelectMeters component.
 */
export const SelectMeters = ({ metersList, handleOnMeterSelect }: ISelectMeters) => {
    const { formatMessage } = useIntl()
    const theme = useTheme()
    const [selectedMeter, setSelectedMeter] = useState(metersList[0].housingId)

    return (
        <div style={{ minWidth: '220px' }} className="container flex flex-row items-center">
            <FormControl fullWidth className="rounded-md">
                <InputLabel
                    shrink
                    id="input-label"
                    style={{
                        color: theme.palette.primary.main,
                    }}
                    className="rounded-md text-lg leading-6"
                >
                    {formatMessage({ id: 'Compteur', defaultMessage: 'Compteur' })}
                </InputLabel>
                <Select
                    labelId="input-label"
                    label="Compteur"
                    value={selectedMeter}
                    onChange={(event) => handleOnMeterSelect(event, setSelectedMeter)}
                    displayEmpty
                    style={{
                        color: theme.palette.primary.main,
                        stroke: theme.palette.primary.main,
                    }}
                >
                    {metersList.map((meter: IMeterT) => {
                        // value is housingId because it will be sent throught filters for the backend
                        return <MenuItem value={meter.housingId}>{meter.meterGuid}</MenuItem>
                    })}
                </Select>
            </FormControl>
        </div>
    )
}
