import { useState } from 'react'
import { useToggle } from 'react-use'
import { axios, catchError, ILoadDataPagination } from 'src/common/react-platform-components'
import { useSnackbar } from 'notistack'
import { useIntl } from 'src/common/react-platform-translation'
import { API_RESOURCES_URL } from 'src/configs'
import { AxiosResponse } from 'axios'

/**
 * Status of the notification, whether it's a new notif', or already read, or when for Archived purposes we send the archived status.
 */
export const notificationStatus = { NEW: 'NEW', READ: 'READ', ARCHIVED: 'ARCHIVED' }

/**
 * Notification Model.
 */
export interface INotification {
    /**
     * Id of the notification.
     */
    id: number
    /**
     * Title of notification.
     */
    title: string
    /**
     * Content of notification.
     */
    content: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    status: keyof typeof notificationStatus
    /**
     * Redirection of the notification , example: notification for new installationRequest of customer redirect to /installationsRequests.
     */
    redirectTo: string
    /**
     * Creation Date.
     */
    createdAt: string
}

/**
 * Notifications url endpoint.
 */
export const NOTIFICATIONS_BASE_URL = `${API_RESOURCES_URL}/notifications`

/**
 * UseNotifications hook.
 *
 * @returns Progress state and notificationsList.
 */
export const useNotifications = () => {
    const [notificationsList, setNotificationsList] = useState<INotification[] | null>(null)
    const [isNotificationsLoading, setIsNotificationsLoading] = useToggle(false)
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()
    const size = 5
    // Indicates if there are no more notifications to fetch using pagination.
    const [noMoreNotificationsToLoad, setNoMoreNotificationsToLoad] = useState(false)
    const [page, setPage] = useState(0)
    /**
     * Function to fetch notificationsList.
     *
     * @param additionalParams Additional queryParams to load.
     * @returns The total items or Throws error message if fail and shows snackbar indicating success or error message.
     */
    const loadPaginatedNotifications = async (additionalParams?: string) => {
        setIsNotificationsLoading(true)
        let endpointUrl = `${NOTIFICATIONS_BASE_URL}`
        if (additionalParams) endpointUrl = `${endpointUrl}${additionalParams}`
        try {
            const { data: responseData } = await axios.get<ILoadDataPagination<INotification[]>>(`${endpointUrl}`)
            // If page is not 0, we will fetch the new page and add the fetched data to the list.
            if (page === 0) {
                setNotificationsList(responseData.items)
            } else {
                setNotificationsList((prevNotificationsList: INotification[] | null) => {
                    const newNotificationsList = prevNotificationsList ? [...prevNotificationsList] : []
                    return newNotificationsList.concat(responseData.items)
                })
            }
            setPage(responseData.page)
            if (responseData.page * responseData.size >= responseData.total) {
                setNoMoreNotificationsToLoad(true)
            } else {
                setNoMoreNotificationsToLoad(false)
            }
            setIsNotificationsLoading(false)
            return responseData.total
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur lors du chargement des notifications',
                    defaultMessage: 'Erreur lors du chargement des notifications',
                }),
                { variant: 'error' },
            )
            setIsNotificationsLoading(false)
            throw catchError(error)
        }
    }

    /**
     * Function that returns the new notifications count.
     *
     * @returns The new Notifiations Count.
     */
    const getNewNotificationsCount = async () => {
        return await loadPaginatedNotifications(`?size=100&status=${notificationStatus.NEW}`)
    }

    /**
     * Load notifications following pagination.
     */
    const loadNotifications = async () => {
        if (!noMoreNotificationsToLoad)
            await loadPaginatedNotifications(
                `?size=${size}&page=${page + 1}&status=${notificationStatus.NEW}&status=${notificationStatus.READ}`,
            )
    }

    /**
     * Function to update notification.
     *
     * @param id Id of the notification.
     * @param status New Status of the updated Notification.
     * @returns Throws error message if fail and shows snackbar indicating success or error message.
     */
    const updateNotification = async (id: number, status: keyof typeof notificationStatus) => {
        setIsNotificationsLoading(true)
        const body = { status: notificationStatus[status as keyof typeof notificationStatus] }
        try {
            const { data: responseData } = await axios.put<typeof body, AxiosResponse<INotification>>(
                `${NOTIFICATIONS_BASE_URL}/${id}`,
                body,
            )
            setIsNotificationsLoading(false)
            return responseData
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Oops, une erreur est survenue',
                    defaultMessage: 'Oops, une erreur est survenue',
                }),
                { variant: 'error' },
            )
            setIsNotificationsLoading(false)
            throw catchError(error)
        }
    }

    /**
     * Function to update notification status to archived.
     *
     * @param id Id of the notification.
     * @returns Throws error message if fail and shows snackbar indicating success or error message.
     */
    const archiveNotification = (id: number) => {
        return updateNotification(id, notificationStatus.ARCHIVED as keyof typeof notificationStatus)
    }

    /**
     * Function to update notification status to read.
     *
     * @param id Id of the notification.
     * @returns Throws error message if fail and shows snackbar indicating success or error message.
     */
    const setReadNotification = (id: number) => {
        return updateNotification(id, notificationStatus.READ as keyof typeof notificationStatus)
    }
    return {
        isNotificationsLoading,
        noMoreNotificationsToLoad,
        notificationsList,
        getNewNotificationsCount,
        archiveNotification,
        setReadNotification,
        loadNotifications,
    }
}
