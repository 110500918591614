import { rest } from 'msw'
import {
    NOTIFICATIONS_BASE_URL,
    notificationStatus,
} from 'src/modules/Layout/Toolbar/components/Notifications/notificationsHooks'

const TEST_NOTIFICATION_TITLE = 'Nouvelle demande d’installation'
const TEST_NOTIFICATION_CONTENT = 'Vous avez reçu une nouvelle demande d’installation de Medi MEDIMES'
const TEST_NOTIFICATION_REDIRECT = 'https://staging.telso.myem.fr/installations-requests'
/**
 *
 */
export var TEST_NOTIFICATIONS = [
    {
        id: 1,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'NEW',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-12T09:11:37',
    },

    {
        id: 2,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'READ',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-12T10:11:37',
    },
    {
        id: 3,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'READ',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-12T11:11:37',
    },

    {
        id: 4,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'READ',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-12T12:11:37',
    },
    {
        id: 5,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'NEW',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-14T12:11:37',
    },
    {
        id: 6,
        title: TEST_NOTIFICATION_TITLE,
        content: TEST_NOTIFICATION_CONTENT,
        status: 'NEW',
        redirect_to: TEST_NOTIFICATION_REDIRECT,
        created_at: '2022-01-13T12:11:37',
    },
]

/**
 *
 */
const notificationsREST = [
    // Get notifications with pagination.
    rest.get(NOTIFICATIONS_BASE_URL, (req, res, ctx) => {
        const pageParam = req.url.searchParams.get('page')
        const sizeParam = req.url.searchParams.get('size')
        const statusParam = req.url.searchParams.getAll('status')
        let page = 1
        let size = 500
        if (pageParam && parseInt(pageParam)) page = parseInt(pageParam)
        if (sizeParam && parseInt(sizeParam)) size = parseInt(sizeParam)
        let total = TEST_NOTIFICATIONS.length
        let TEST_NOTIFICATION_RESPONSE = TEST_NOTIFICATIONS
        if (statusParam.length !== 0) {
            TEST_NOTIFICATION_RESPONSE = TEST_NOTIFICATION_RESPONSE.filter((notification) =>
                statusParam.includes(notification.status),
            )
            total = TEST_NOTIFICATION_RESPONSE.length
        }
        TEST_NOTIFICATION_RESPONSE = TEST_NOTIFICATION_RESPONSE.slice((page - 1) * size, page * size)

        return res(
            ctx.status(200),
            ctx.delay(1000),
            ctx.json({
                items: TEST_NOTIFICATION_RESPONSE,
                total: total,
                page: page,
                size: size,
            }),
        )
    }),

    // Update notification
    rest.put</**
     *
     */
    {
        /**
         *
         */
        status: keyof typeof notificationStatus
    }>(`${NOTIFICATIONS_BASE_URL}/:id`, (req, res, ctx) => {
        const { id } = req.params
        const { status } = req.body
        if (parseInt(id) && Object.keys(notificationStatus).includes(status)) {
            let indexOfNotification = TEST_NOTIFICATIONS.findIndex((notification) => notification.id === parseInt(id))
            let newNotification = { ...TEST_NOTIFICATIONS[indexOfNotification], status }
            TEST_NOTIFICATIONS[indexOfNotification] = newNotification
            return res(ctx.status(200), ctx.delay(1000), ctx.json(newNotification))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),
]

export default notificationsREST
