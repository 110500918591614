import React, { useEffect, useState } from 'react'
import './equipmentsTable.scss'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import {
    useEquipments,
    IEquipment,
} from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/equipmentsTable/equipmentsHooks'
import { useIntl } from 'react-intl'
import { Typography } from 'src/common/ui-kit'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import { useParams } from 'react-router'
import capitalize from '@mui/utils/capitalize'
import { equipmentsTypeList } from 'src/modules/InstallationsRequests/components/InstallationsRequestsTable'
import EmptyTableMessage from 'src/common/ui-kit/components/Table/EmptyTableMessage'
import Table from 'src/common/ui-kit/components/Table/Table'

dayjs.extend(utc)

/**
 * EquipmentsTable component to display equipmentsList in a table.
 *
 * @returns EquipmentsTable component.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const EquipmentsTable = () => {
    const { equipmentsList, loadEquipments, isEquipmentsListLoading } = useEquipments()
    /**
     * Hook useParams to get the params of the current route url. /customers/:id/:tab.
     */
    const { customerId: customerIdParam } = useParams</**
     *
     */
    {
        /**
         *
         */
        customerId: string
    }>()
    const customerId = parseInt(customerIdParam)
    const { formatMessage } = useIntl()
    const [isLoadedEquipmentsListWhenMount, setIsLoadedEquipmentsListWhenMount] = useState(false)

    useEffect(() => {
        if (isLoadedEquipmentsListWhenMount === false) {
            loadEquipments(customerId)
            setIsLoadedEquipmentsListWhenMount(true)
        }
    }, [loadEquipments, isLoadedEquipmentsListWhenMount, customerId])

    /**
     * Row containing the Cells of the Equipments Table.
     */
    const equipmentsCells = [
        {
            id: 'type',
            headCellLabel: 'Type',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IEquipment) => equipmentsTypeList[row.type as keyof typeof equipmentsTypeList].label,
        },
        {
            id: 'brand',
            headCellLabel: 'Marque',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IEquipment) => capitalize(row.brand),
        },
        {
            id: 'reference',
            headCellLabel: 'Modèle',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IEquipment) => row.reference,
        },
        {
            id: 'installedAt',
            headCellLabel: "Date d'installation",
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IEquipment) => dayjs.utc(row.installedAt).local().format('DD/MM/YYYY'),
        },
    ]

    return (
        <div className="EquipmentsTable">
            <Typography className="text-12 sm:text-18 mb-10  font-semibold">
                {formatMessage({ id: 'Ses équipements :', defaultMessage: 'Ses équipements :' })}
            </Typography>
            {isEquipmentsListLoading || equipmentsList === null ? (
                <FuseLoading />
            ) : equipmentsList.length === 0 ? (
                <EmptyTableMessage
                    message={formatMessage({
                        id: "Il n'y a pas d'équipements",
                        defaultMessage: "Il n'y a pas d'équipements",
                    })}
                />
            ) : (
                <div className="w-full flex flex-col rounded-16 border-2 shadow">
                    <Table<IEquipment> cells={equipmentsCells} rows={equipmentsList} />
                </div>
            )}
        </div>
    )
}

export default EquipmentsTable
