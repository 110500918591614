import { NavLink } from 'react-router-dom'
import React from 'react'
import RoomIcon from '@mui/icons-material/Room'
import PhoneIcon from '@mui/icons-material/Phone'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Chip from '@mui/material/Chip'
import { customerT } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { CustomerDetailsContextType } from 'src/modules/DetailsPages/CustomerDetails/CustomerDetailsT'

/**
 * The type of the Props that we are passing via NavLink.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export type customerCardNavProps = {
    /**
     *
     */
    returnURL?: string
    /**
     *
     */
    returnLabel?: string
} & CustomerDetailsContextType

/**.
 * Represent the component UI that render the item data, all the inputs represents add ons to make our Card better
 *
 * @param props N/A.
 * @param props.element Element Object.
 * @param props.returnLabel Override Label that will be shown in CustomerDetailsHeader.
 * @param props.returnURL Override URL of redirection when clicking on returnLabel in cutomerDetailsHeader.
 * @param props.customerDetailsContextValue Indicates if the CustomerDetails should be viewed in customerDetailsContextValue.
 * @returns Card that contains the item data with the different properties given.
 */
const CustomerCard = ({
    element: customer,
    returnURL,
    returnLabel,
    customerDetailsContextValue,
}: // eslint-disable-next-line jsdoc/require-jsdoc
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    element: customerT
    // eslint-disable-next-line jsdoc/require-jsdoc
    returnURL?: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    returnLabel?: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    customerDetailsContextValue?: CustomerDetailsContextType
}) => {
    const name = `${customer.firstName} ${customer.lastName}`

    const [raisedState, setRaisedState] = React.useState(false)
    return (
        <NavLink
            className="w-full"
            to={{
                pathname: `/customers/${customer.id}/customer-record`,
                state: {
                    returnURL,
                    returnLabel,
                    ...customerDetailsContextValue,
                },
            }}
            key={customer.id}
        >
            <Card
                className="relative cursor-pointer flex-wrap rounded-16"
                onMouseOver={() => setRaisedState(true)}
                onMouseOut={() => setRaisedState(false)}
                raised={raisedState}
            >
                <div className={clsx('px-24 pt-16 pb-0 flex items-center justify-between flex-wrap')}>
                    <Typography className="font-bold text-20 whitespace-normal">{name}</Typography>
                </div>

                <CardContent className="px-32 py-0">
                    <Divider className="my-16" />

                    <div className="flex flex-col">
                        <Typography variant="subtitle1" className="mb-10 text-13 flex ">
                            <span className="font-semibold mr-4 whitespace-normal">
                                <RoomIcon></RoomIcon>
                            </span>
                            {customer.address && customer.address.name ? customer.address.name : null}
                        </Typography>

                        <Typography variant="subtitle1" className="mb-10 text-13 whitespace-normal">
                            <span className="font-semibold mx-4">
                                <PhoneIcon></PhoneIcon>
                            </span>
                            {customer.phone}
                        </Typography>
                        <Typography variant="subtitle1" className="text-13">
                            <span className="font-semibold mx-4">
                                <MailOutlineIcon></MailOutlineIcon>
                            </span>
                            {customer.email}
                        </Typography>
                    </div>
                    {(customer.interests.includes('installation') || customer.interests.includes('supplier')) && (
                        <>
                            <Divider className="my-16" />
                            <div className="flex justify-center flex-wrap pb-0 wrap no-underline">
                                {customer.interests.includes('installation') && (
                                    <Chip
                                        size="small"
                                        label="Installation"
                                        color="primary"
                                        className="fitterChip mr-5"
                                    />
                                )}
                                {customer.interests.includes('supplier') && (
                                    <Chip size="small" label="Fournisseur" color="secondary" className="providerChip" />
                                )}
                            </div>
                        </>
                    )}
                </CardContent>
            </Card>
        </NavLink>
    )
}

export default CustomerCard
