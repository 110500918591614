import React, { useState, useCallback, useEffect } from 'react'
import Badge from '@mui/material/Badge'
import IconButton from '@mui/material/IconButton'
import { useNotifications } from './notificationsHooks'
import NotificationsPanel from 'src/modules/Layout/Toolbar/components/Notifications/NotificationsPanel'
import NotificationIcon from '@mui/icons-material/Notifications'

/**
 * Notification Component, that represents the notification icon in the toolbar.
 *
 * @returns Notification Component.
 */
const Notifications = () => {
    const [isNotificationPanel, setIsNotificationPanel] = useState(false)
    const { getNewNotificationsCount } = useNotifications()
    const [notificationsCount, setNotificationsCount] = useState(0)

    const [isLoadedNotificationsListWhenMountedComponent, setIsLoadedNotificationsListWhenMountedComponent] =
        useState(false)

    /**
     * Function for calling the getNewNotifications Count from useNotification.
     */
    const fetchNewNotificationsCount = useCallback(async () => {
        const notificationsCount = await getNewNotificationsCount()
        setNotificationsCount(notificationsCount)
    }, [getNewNotificationsCount])

    useEffect(() => {
        if (isLoadedNotificationsListWhenMountedComponent === false) {
            fetchNewNotificationsCount()
            setIsLoadedNotificationsListWhenMountedComponent(true)
        }
    }, [fetchNewNotificationsCount, isLoadedNotificationsListWhenMountedComponent])

    /**
     * Close the notification panel.
     */
    const closeNotificationPanel = () => {
        fetchNewNotificationsCount()
        setIsNotificationPanel(false)
    }
    return (
        <>
            {/* NotificationPanelToggleButton. */}
            <IconButton
                className="w-40 h-40"
                onClick={(ev: React.SyntheticEvent) => setIsNotificationPanel(true)}
                size="large"
            >
                <Badge color="error" badgeContent={notificationsCount}>
                    <NotificationIcon />
                </Badge>
            </IconButton>
            {isNotificationPanel && <NotificationsPanel closeNotificationPanel={closeNotificationPanel} />}
        </>
    )
}

export default Notifications
