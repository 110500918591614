import { IUser } from 'src/modules/User/model'
import { installerUpdateType } from 'src/modules/DetailsPages/InstallerDetails/InstallerDetails.d'
import { useCallback, useState } from 'react'
import { INSTALLER_API } from 'src/modules/MapPages/InstallerList/installerListHook'
import { axios } from 'src/common/react-platform-components'
import { useIntl } from 'src/common/react-platform-translation'
import { useSnackbar } from 'notistack'
import { getMsgFromAxiosError } from 'src/modules/utils'
import { AxiosResponse } from 'axios'
import { API_RESOURCES_URL } from 'src/configs'
import { useHistory } from 'react-router-dom'

//eslint-disable-next-line jsdoc/require-jsdoc
export const BACKEND_EMAIL_EXIST_ERROR_MESSAGE = 'UPDATE_USER_EMAIL_ALREADY_EXISTS'
//eslint-disable-next-line jsdoc/require-jsdoc
export const EMAIL_ALREADY_EXIST_SNACKBAR_MESSAGE = "L'email inséré existe déjà"

/**
`* Hooks for Installer.
 *
 * @param installerId The Id of the Installer.
 * @returns UseInstallerDetails hook.
 */
export const useInstallerDetails = (installerId: string) => {
    const [installerDetails, setInstallerDetails] = useState<IUser | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const [isInstallerInProgress, setIsInstallerInProgress] = useState(false)
    const { formatMessage } = useIntl()
    const history = useHistory()

    /**
     * Update installer function.
     *
     * @param body N/A.
     * @param body.email  Potentially the new email of the installer.
     * @param body.phone Potentially the new phone of the installer.
     * @param body.address Potentially the new address of the installer.
     * @returns Message Succes or Fail.
     */
    const updateInstaller = async (body: installerUpdateType) => {
        setIsInstallerInProgress(true)
        try {
            await axios.patch<installerUpdateType, AxiosResponse<IUser>>(`${INSTALLER_API}/${installerId}`, body)
            enqueueSnackbar(
                formatMessage({
                    id: 'Vos modifications ont été sauvegardées.',
                    defaultMessage: 'Vos modifications ont été sauvegardées.',
                }),
                { variant: 'success' },
            )
            setIsInstallerInProgress(false)
        } catch (error) {
            const message = handleUpdateInstallerError(error)
            enqueueSnackbar(formatMessage({ id: message, defaultMessage: message }), { variant: 'error' })
            setIsInstallerInProgress(false)
            throw message
        }
    }

    /**
     * Function hook responsible for installerDetails.
     *
     * @returns The function throw an error, and show snackbar message containing successful and errors message.
     */
    const loadInstallerDetails = useCallback(async () => {
        setIsInstallerInProgress(true)
        try {
            const { data: responseData } = await axios.get<IUser>(`${INSTALLER_API}/${installerId}`)
            setInstallerDetails(responseData)
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur de chargement du détail du client',
                    defaultMessage: 'Erreur de chargement du détail du client',
                }),
                { variant: 'error' },
            )
        }
        setIsInstallerInProgress(false)
    }, [installerId, enqueueSnackbar, formatMessage])

    /**
     * Handler for loading installerDetails.
     *
     * @returns LoadInstallerDetails.
     */
    const handleLoadInstallerDetails = useCallback(async () => {
        await loadInstallerDetails()
    }, [loadInstallerDetails])

    const deleteInstaller = useCallback(async () => {
        setIsInstallerInProgress(true)
        try {
            const { status } = await axios.delete(`${API_RESOURCES_URL}/installers/${installerId}`)
            if (status === 200) {
                enqueueSnackbar(
                    formatMessage({
                        id: 'Installateur est supprimé avec succès',
                        defaultMessage: 'Installateur est supprimé avec succès',
                    }),
                    { autoHideDuration: 5000, variant: 'success' },
                )
                history.push('/installers')
            }
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: "Erreur lors de la suppression de l'installateur",
                    defaultMessage: "Erreur lors de la suppression de l'installateur",
                }),
                {
                    autoHideDuration: 5000,
                    variant: 'error',
                },
            )
        }
        setIsInstallerInProgress(false)
    }, [enqueueSnackbar, formatMessage, history, installerId])

    return {
        isInstallerInProgress,
        installerDetails,
        loadInstallerDetails,
        updateInstaller,
        handleLoadInstallerDetails,
        deleteInstaller,
    }
}

/**
 * Handle message to show in snackbar when update is wrong.
 *
 * @param error Error from Axios.
 * @returns Message.
 */
export const handleUpdateInstallerError = (error: unknown) => {
    const message = getMsgFromAxiosError(error)
    if (message === BACKEND_EMAIL_EXIST_ERROR_MESSAGE) {
        return EMAIL_ALREADY_EXIST_SNACKBAR_MESSAGE
    } else {
        return message
    }
}
