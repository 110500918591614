import { useState } from 'react'
import { API_RESOURCES_URL } from 'src/configs'
import { axios } from 'src/common/react-platform-components'
import { useIntl } from 'src/common/react-platform-translation'
import { useSnackbar } from 'notistack'
import { getMsgFromAxiosError } from 'src/modules/utils'
import { AxiosResponse } from 'axios'
import { addCustomerInputType } from 'src/modules/MapPages/CustomerList/customerListHook'

/**
 * Url for customers (clients webservice) endpoints.
 */
export const CUSTOMER_API = `${API_RESOURCES_URL}/clients`

/**
 * Updatable customer fields.
 */
//eslint-disable-next-line jsdoc/require-jsdoc
export type updateCustomerInputType = {
    //eslint-disable-next-line jsdoc/require-jsdoc
    phone: string

    //eslint-disable-next-line jsdoc/require-jsdoc
    email: string

    //eslint-disable-next-line jsdoc/require-jsdoc
    address: customerAddressType
    //eslint-disable-next-line jsdoc/require-jsdoc
    interests: interestValuesType[]
}

/**
 * Interests values type.
 */
export type interestValuesType = 'installation' | 'supplier'

/**
 * Represent the model for the customer (firstName, ...etc).
 */
//eslint-disable-next-line jsdoc/require-jsdoc
export type customerT = addCustomerInputType & {
    //eslint-disable-next-line jsdoc/require-jsdoc
    id: number

    //eslint-disable-next-line jsdoc/require-jsdoc
    createdAt: string

    // eslint-disable-next-line jsdoc/require-jsdoc
    interests: interestValuesType[]

    // eslint-disable-next-line jsdoc/require-jsdoc
    hasSensor?: boolean
}

/**
 * Customer address format.
 */
export type customerAddressType =
    //eslint-disable-next-line jsdoc/require-jsdoc
    {
        //eslint-disable-next-line jsdoc/require-jsdoc
        name: string

        //eslint-disable-next-line jsdoc/require-jsdoc
        zipCode: string | null

        //eslint-disable-next-line jsdoc/require-jsdoc
        city: string

        //eslint-disable-next-line jsdoc/require-jsdoc
        country: string

        //eslint-disable-next-line jsdoc/require-jsdoc
        lat: number

        //eslint-disable-next-line jsdoc/require-jsdoc
        lng: number

        //eslint-disable-next-line jsdoc/require-jsdoc
        addressAddition?: string

        //eslint-disable-next-line jsdoc/require-jsdoc
        extraData?: /**
         *
         */
        { [key: string]: any }
    }
/**
`* Hooks for customers.
 *
 * @returns UseCustomersDetails hook.
 */
export function useCustomerDetails() {
    const [customerDetails, setCustomerDetails] = useState<customerT | null>(null)
    const { enqueueSnackbar } = useSnackbar()
    const [isCustomerInProgress, setIsCustomerInProgress] = useState(false)
    const { formatMessage } = useIntl()

    /**
     * Update Customer function.
     *
     * @param customerDetails The current customer to be updated (needed for changing address, to remove with google maps address).
     * @param body N/A.
     * @param body.email  Potentially the new email of the customer.
     * @param body.phone Potentially the new phone of the customer.
     * @param body.address Potentially the new address of the customer.
     * @param body.isInterestedByInstallation Potentially a change of the interest by installation of the customer.
     * @param body.isInterestedBySupplier Potentially a changer of the inetrest by supplier of the customer.
     * @returns Message Succes or Fail.
     */
    const updateCustomer = async (customerDetails: customerT, body: updateCustomerInputType) => {
        setIsCustomerInProgress(true)
        try {
            await axios.put<updateCustomerInputType, AxiosResponse<customerT>>(
                `${CUSTOMER_API}/${customerDetails.id}`,
                body,
            )
            enqueueSnackbar(
                formatMessage({
                    id: 'Vos modifications ont été sauvegardées',
                    defaultMessage: 'Vos modifications ont été sauvegardées',
                }),
                { variant: 'success' },
            )
            setIsCustomerInProgress(false)
        } catch (error) {
            const message = getMsgFromAxiosError(error)
            enqueueSnackbar(message, { variant: 'error' })
            setIsCustomerInProgress(false)
            throw message
        }
    }

    /**
     * Function hook responsible for fetching the function responsible for fetching customerDetails.
     *
     * @param customerId Represent the customerId of the customer to be fetched.
     * @returns The function throw an error, and show snackbar message containing successful and errors message.
     */
    const loadCustomerDetails = async (customerId: number) => {
        setIsCustomerInProgress(true)
        try {
            const { data: responseData } = await axios.get<customerT>(`${CUSTOMER_API}/${customerId}`)
            setCustomerDetails(responseData)
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur de chargement du détail du client',
                    defaultMessage: 'Erreur de chargement du détail du client',
                }),
                { variant: 'error' },
            )
        }
        setIsCustomerInProgress(false)
    }

    return {
        isCustomerInProgress,
        customerDetails,
        loadCustomerDetails,
        updateCustomer,
    }
}
