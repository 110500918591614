import { useState } from 'react'
import { AxiosResponse } from 'axios'
import { axios, catchError } from 'src/common/react-platform-components'
import { useIntl } from 'src/common/react-platform-translation'
import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { searchFilterType } from 'src/modules/utils'
import { IUser } from 'src/modules/User'
import { INSTALLER_API } from 'src/modules/MapPages/InstallerList/installerListHook'
import { formatMessageType } from 'src/common/react-platform-translation'
import { updateInstalerRequestInputType } from 'src/modules/RegistrationRequests/InstallerRequests/installerRequests'
import { useSnackbar } from 'notistack'

/**
 * Activate request Endpoint that will be from InstallerAPI with activate-inscription-request.
 */
const ACTIVATE_REQUEST_ENDPOINT = `${INSTALLER_API}/activate-inscription-request`

// eslint-disable-next-line jsdoc/require-jsdoc
export const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Erreur lors du chargement des demandes',
        defaultMessage: 'Erreur lors du chargement des demandes',
    })
}

// eslint-disable-next-line jsdoc/require-jsdoc
const activateInstallerRequestErrorMSG = (formatMessage: formatMessageType) => {
    return formatMessage({
        id: "Echec lors de la validation de l'installateur",
        defaultMessage: "Echec lors de la validation de l'installateur",
    })
}
/**
     `* Hooks for InstallerRequests for registration requests of installer.
     *
     * @param sizeParam Indicates the default sizeParam for loadElementList.
     * @returns UseInstallerRequestList Hook.
     */
export const useInstallerRequestList = (sizeParam?: number) =>
    // eslint-disable-next-line jsdoc/require-jsdoc
    BuilderUseElementList<IUser, undefined, searchFilterType & { is_verified?: boolean }>({
        API_ENDPOINT: INSTALLER_API,
        sizeParam: sizeParam ? sizeParam : 10,
        snackBarMessage0verride: { loadElementListError },
    })(true, { is_verified: false, role_type: 'installer' })

/**
 * Hooks for InstallerRequestDetails for activating registration request.
 *
 * @param installerId Id of the registered installer.
 * @returns Hook useInstallerRequesDetails.
 */
export const useInstallerRequestDetails = (installerId: string) => {
    const { formatMessage } = useIntl()
    const { enqueueSnackbar } = useSnackbar()
    const [loadingActivateRequest, setLoadingActivateRequest] = useState(false)
    /**.
     * Function that will make backend call to for accepting installerRequest.
     */
    const acceptInstallerRequest = async () => {
        const body = {
            userId: installerId,
            inscriptionAccepted: true,
        }
        setLoadingActivateRequest(true)
        try {
            await axios.post<updateInstalerRequestInputType, AxiosResponse<IUser>>(`${ACTIVATE_REQUEST_ENDPOINT}`, body)
            enqueueSnackbar(
                formatMessage({
                    id: "Opération réussie, l'installateur a accès à la plateforme",
                    defaultMessage: "Opération réussie, l'installateur a accès à la plateforme",
                }),
                { variant: 'success' },
            )
            setLoadingActivateRequest(false)
        } catch (error) {
            enqueueSnackbar(activateInstallerRequestErrorMSG(formatMessage), { variant: 'error' })
            setLoadingActivateRequest(false)
            throw catchError(error)
        }
    }

    /**.
     * Function that will make backend call to for refusing installerRequest.
     */
    const refuseInstallerRequest = async () => {
        const body = {
            userId: installerId,
            inscriptionAccepted: false,
        }
        setLoadingActivateRequest(true)
        try {
            await axios.post<updateInstalerRequestInputType, AxiosResponse<IUser>>(`${ACTIVATE_REQUEST_ENDPOINT}`, body)
            enqueueSnackbar(
                formatMessage({
                    id: "Opération réussie, l'installateur a été refusé",
                    defaultMessage: "Opération réussie, l'installateur a été refusé",
                }),
                { variant: 'success' },
            )
            setLoadingActivateRequest(false)
        } catch (error) {
            enqueueSnackbar(activateInstallerRequestErrorMSG(formatMessage), { variant: 'error' })
            setLoadingActivateRequest(false)
            throw catchError(error)
        }
    }

    return {
        loadingActivateRequest,
        acceptInstallerRequest,
        refuseInstallerRequest,
    }
}
