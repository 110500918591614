import React, { FC } from 'react'
import { customerFiltersT, useCustomersList } from 'src/modules/MapPages/CustomerList/customerListHook'
import CustomerListHeader from 'src/modules/MapPages/CustomerList/components/CustomerListHeader/CustomerListHeader'
import MapElementList from 'src/common/ui-kit/components/MapElementList'
import { customerT } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import CustomerCard from 'src/modules/MapPages/CustomerList/components/CustomerCard/CustomerCard'
import PageSimple from 'src/common/ui-kit/fuse/components/PageSimple'
import { useAuth } from 'src/modules/User/authentication/useAuth'
import { filterDetailsTypePropertyT } from 'src/common/ui-kit/components/MapElementList/components/Filters/FilterButton'
import { GOOGLE_MAPS_API_KEY } from 'src/configs'

/**
 * Filters Params.
 */
export const filters = [
    {
        label: 'Intérêt',
        name: 'interests',
        type: 'select' as filterDetailsTypePropertyT,
        options: [
            {
                name: '__novalue__',
                label: 'Aucun',
            },
            {
                name: 'installation',
                label: 'Installation',
            },
            {
                name: 'supplier',
                label: 'Fournisseur',
            },
        ],
    },

    {
        label: "Demande d'Installation",
        name: 'status',
        type: 'select' as filterDetailsTypePropertyT,
        options: [
            {
                name: 'NEW',
                label: 'Nouvelles',
            },
            {
                name: 'PENDING',
                label: 'En cours',
            },
        ],
    },
]

/**
 * Customers component props.
 */
export interface CustomersListProps {
    /**
     * Logo to dislay.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Url of the logo.
         */
        url: string
    }
}

/**
 * CustomersList view component containing Map & CustomersList Items with Filters.
 *
 * @returns CustomersList page component.
 */
const CustomersList: FC<CustomersListProps> = (): JSX.Element => {
    const {
        elementList: customersList,
        loadingInProgress: isCustomerInProgress,
        reloadElements: getCustomersList,
        updateFilters,
        loadMoreElements: loadMoreCustomers,
        noMoreElementToLoad: noMoreCustomersToLoad,
        addElement: addCustomer,
    } = useCustomersList(10)

    const { hasRole } = useAuth()

    return (
        <PageSimple
            header={
                <CustomerListHeader
                    updateFilters={updateFilters}
                    getCustomersList={getCustomersList}
                    addCustomer={addCustomer}
                    isCustomerInProgress={isCustomerInProgress}
                    headerTitle={hasRole(['distributor']) ? 'Mes lead potentiels' : undefined}
                />
            }
            content={
                <MapElementList<customerT, customerFiltersT>
                    data={customersList}
                    googleMapsApiKey={GOOGLE_MAPS_API_KEY}
                    filters={filters}
                    onConfirmFilter={updateFilters}
                    latLngElementAddressOverride={(element: customerT) => ({
                        lat: element.address.lat,
                        lng: element.address.lng,
                    })}
                    loadingData={isCustomerInProgress}
                    loadMoreData={loadMoreCustomers}
                    elementCard={CustomerCard}
                    noMoreDataToLoad={noMoreCustomersToLoad}
                />
            }
        />
    )
}

export default CustomersList
