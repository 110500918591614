import React from 'react'
import './metersTable.scss'
import { useDownloadCSV } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/metersHooks'
import {
    SgeConsentType,
    EnphaseConsentType,
    IMeterT,
    NrlinkConsentType,
} from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/meters.d'
import { useIntl } from 'react-intl'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import Table from 'src/common/ui-kit/components/Table/Table'
import EmptyTableMessage from 'src/common/ui-kit/components/Table/EmptyTableMessage'
import Tooltip from '@mui/material/Tooltip'
import CircularProgress from '@mui/material/CircularProgress'

/**
 * Handle Nrlink State Icon that will be Displayed.
 *
 * @param consent State of Consentement.
 * @returns JSX Element.
 */
export const handleNrlinkStateIcon = (consent: NrlinkConsentType) => {
    if (consent.state === 'CONNECTED') {
        return <Icon color="success">check_circle</Icon>
    } else if (consent?.state === 'DISCONNECTED') {
        return (
            <Tooltip title="Wifi OFF">
                <Icon>help_outline</Icon>
            </Tooltip>
        )
    } else {
        return <Icon color="error">cancel</Icon>
    }
}
/**
 * Handle Enedis (SGE) State Icon that will be Displayed.
 *
 * @param consent State of Consentement.
 * @returns JSX Element.
 */
export const handleStateIcon = (consent: SgeConsentType) => {
    if (consent.state === 'CONNECTED') return <Icon color="success">check_circle</Icon>
    return <Icon color="error">cancel</Icon>
}

/**
 * Handle Enphase State Icon that will be Displayed.
 *
 * @param consent State of Consentement.
 * @returns JSX Element.
 */
export const handleEnphaseConsent = (consent: EnphaseConsentType) => {
    if (consent.state === 'ACTIVE') return <Icon color="success">check_circle</Icon>
    return <Icon color="error">cancel</Icon>
}

/**
 * MeterTable component to display metersList in a table.
 *
 * @param root0 N/A.
 * @param root0.metersList Meters List.
 * @param root0.totalMeterList Totale of Meters List.
 * @param root0.isMetersListInProgress Progress state when loading meters.
 * @param root0.loadPage Function that load pages.
 * @param root0.page Page number.
 * @returns MeterTable component.
 */
export default function MeterTable({
    metersList,
    totalMeterList,
    isMetersListInProgress,
    loadPage,
    page,
}: // eslint-disable-next-line jsdoc/require-jsdoc
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    metersList: IMeterT[] | null
    // eslint-disable-next-line jsdoc/require-jsdoc
    totalMeterList: number
    // eslint-disable-next-line jsdoc/require-jsdoc
    isMetersListInProgress: boolean
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadPage: (page: number) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    page: number
}) {
    const { formatMessage } = useIntl()

    const { getCSV, isDownloadLoading } = useDownloadCSV()

    /**
     * Row containing the Cells of the Meters Table.
     */
    const metersCells = [
        {
            id: 'meterGuid',
            headCellLabel: 'Numéro du PDL',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IMeterT) => row.meterGuid,
        },
        {
            id: 'sgeConsent',
            headCellLabel: 'Connexion Enedis',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IMeterT) => handleStateIcon(row.consents.sge),
        },
        {
            id: 'nrlinkConsent',
            headCellLabel: 'Connexion nrLINK',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IMeterT) => (
                <Tooltip
                    title={row.consents.nrlink.nrlinkGuid ? `${row.consents.nrlink.nrlinkGuid}` : ''}
                    placement="right"
                    arrow
                >
                    {handleNrlinkStateIcon(row.consents.nrlink)}
                </Tooltip>
            ),
        },
        {
            id: 'enphaseConsent',
            headCellLabel: 'Connexion Enphase',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IMeterT) => handleEnphaseConsent(row.consents.enphase),
        },
        {
            id: 'exportCSV',
            headCellLabel: 'Export',
            /**
             * Table row cell.
             *
             * @param row Table row.
             * @returns Download CSV Icon.
             */
            rowCell: (row: IMeterT) => {
                if (isDownloadLoading) return <CircularProgress size={18} />

                return (
                    <>
                        <Tooltip title="Export des données de consommation en kWh" placement="top" arrow>
                            <Icon color="secondary" onClick={() => getCSV(row.housingId)} data-testid="download-icon">
                                download_icon
                            </Icon>
                        </Tooltip>
                    </>
                )
            },
        },
    ]

    return (
        <div className="MetersTable">
            <Typography className="text-12 sm:text-18 mb-10  font-semibold">
                {formatMessage({ id: 'Ses compteurs :', defaultMessage: 'Ses compteurs :' })}
            </Typography>
            {isMetersListInProgress || metersList === null ? (
                <FuseLoading />
            ) : metersList.length === 0 ? (
                <EmptyTableMessage
                    message={formatMessage({
                        id: "Il n'y a pas de compteurs",
                        defaultMessage: "Il n'y a pas de compteurs",
                    })}
                />
            ) : (
                <div className="w-full flex flex-col justify-center rounded-16 border-2 shadow">
                    <Table<IMeterT>
                        cells={metersCells}
                        rows={metersList}
                        totalRows={totalMeterList}
                        pageProps={page}
                        onPageChange={loadPage}
                    />
                </div>
            )}
        </div>
    )
}
