import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { formatMessageType } from 'src/common/react-platform-translation'
import { customerT } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { CUSTOMER_API } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { customerFiltersT } from 'src/modules/MapPages/CustomerList/customerListHook'

// eslint-disable-next-line jsdoc/require-jsdoc
const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: "Erreur lors du chargement des clients de l'installateur",
        defaultMessage: "Erreur lors du chargement des clients de l'installateur",
    })
}

/**
 * Type of InstallerCustomers.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
type installerCustomersFilterType = customerFiltersT & { installer_clients_id?: boolean }

/**
`* Hooks for customersList of Installers.
 *
 * @param installerId ID relevant to an installateur.
 * @returns UseInstallerCustomers Hook.
 */
export const useInstallerCustomers = (installerId: string) =>
    // eslint-disable-next-line jsdoc/require-jsdoc
    BuilderUseElementList<customerT, {}, installerCustomersFilterType>({
        API_ENDPOINT: CUSTOMER_API,
        sizeParam: 10,
        snackBarMessage0verride: { loadElementListError },
        // Default query params related to the endpoint to be passed.
    })(false, { installer_clients_id: installerId })
