import { authTypes } from 'src/common/react-platform-components'
import InstallerRequestsTable from 'src/modules/RegistrationRequests/InstallerRequests'
import { IRouteNavigationConfig } from 'src/routes'

const URL_INSTALLER_REQUESTS = '/registration-requests'
/**
 * Interface .
 *
 */
export interface InstallerRequestsProps {
    /**
     * Logo to dislay.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Url of the logo.
         */
        url: string
    }
}
const InstallerRequestsConfig = [
    {
        path: URL_INSTALLER_REQUESTS,
        component: InstallerRequestsTable,
        auth: { authType: authTypes.loginRequired, roles: ['distributor'] },
        settings: {
            layout: {
                navbar: {
                    UINavbarItem: {
                        id: 'registration_requests',
                        label: 'Demandes',
                        type: 'item',
                        iconLabel: 'list_alt',
                        url: URL_INSTALLER_REQUESTS,
                    },
                },
            },
        },
    } as IRouteNavigationConfig<InstallerRequestsProps>,
]

export { InstallerRequestsConfig, URL_INSTALLER_REQUESTS }
