import { useState } from 'react'
import { useToggle } from 'react-use'
import { axios, catchError, ILoadDataPagination } from 'src/common/react-platform-components'
import { useSnackbar } from 'notistack'
import { useIntl } from 'src/common/react-platform-translation'
import { API_RESOURCES_URL } from 'src/configs'

/**
 * Equipment url.
 */
export const EQUIPMENT_BASE_URL = `${API_RESOURCES_URL}/equipments/user`

/**
 * The type of the equipmentType.
 */
export type equipmentTypeT = 'INVERTER' | 'DEMOTIC' | 'OTHER' | 'SOLAR'
/**
 *
 */
export interface IEquipment {
    /**
     * Identifier of the equipment.
     */
    id: number
    /**
     * Type of the equipment.
     */
    type: equipmentTypeT
    /**
     * Brand of the equipment.
     */
    brand: string
    /**
     * Reference of the equipment.
     */
    reference: string
    /**
     * Installation date of the equipment.
     */
    installedAt: string
}

/**
 * Get equipmentsList hook.
 *
 * @returns Progress state and equipmentsList.
 */
export const useEquipments = () => {
    const [equipmentsList, setEquipmentsList] = useState<IEquipment[] | null>(null)
    const [isEquipmentsListLoading, setIsEquipmentsListLoading] = useToggle(false)
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()

    /**
     * Function to fetch equipmentsList.
     *
     * @param customerId The comments to be load for the specified customerId.
     * @returns The function throws errors message.
     */
    const loadEquipments = async (customerId: number) => {
        setIsEquipmentsListLoading(true)
        try {
            const { data: responseData } = await axios.get<ILoadDataPagination<IEquipment[]>>(
                `${EQUIPMENT_BASE_URL}/${customerId}?size=100`,
            )
            setEquipmentsList(responseData.items)
            setIsEquipmentsListLoading(false)
        } catch (error) {
            await enqueueSnackbar(
                formatMessage({
                    id: 'Erreur lors du chargement des équipements',
                    defaultMessage: 'Erreur lors du chargement des équipements',
                }),
                { variant: 'error' },
            )
            setIsEquipmentsListLoading(false)
            throw catchError(error)
        }
    }

    return { isEquipmentsListLoading, equipmentsList, loadEquipments }
}
