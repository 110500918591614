import { useTheme } from '@mui/material/styles'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import React, { useState } from 'react'
import Box from '@mui/material/Box'
import { chartsPeriodData } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/utils/ChartsVariables'
import { getRange } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/utils/ChartsFunctions'
import { IChartPeriod } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/Charts.d'
/**
 * ConsumptionPeriod Component allows to change the time period.
 *
 * @param param0 N/A.
 * @param param0.setPeriod SetPeriod function.
 * @param param0.setRange SetRange function.
 * @param param0.setMetricsInterval SetMetricsInterval function.
 * @returns ConsumptionPeriod.
 */
export const ChartsPeriod = ({ setRange, setPeriod, setMetricsInterval }: IChartPeriod) => {
    const theme = useTheme()
    const [tabValue, setTabValue] = useState(0)
    return (
        <div className="flex flex-row items-center">
            <Tabs
                value={tabValue}
                onChange={(event, value) => {
                    setTabValue(value)
                    setRange(getRange(chartsPeriodData[value].period))
                    setMetricsInterval(chartsPeriodData[value].interval)
                    setPeriod(chartsPeriodData[value].period)
                }}
                indicatorColor="primary"
                textColor="inherit"
                variant="scrollable"
                scrollButtons={false}
                className="w-full mx-4 min-h-40"
                classes={{
                    indicator: 'flex justify-center bg-transparent w-full h-full  ',
                    flexContainer: 'flex justify-center',
                }}
                TabIndicatorProps={{
                    children: (
                        <Box sx={{ bgcolor: theme.palette.secondary.main }} className="w-full h-full rounded-full " />
                    ),
                }}
            >
                {chartsPeriodData.map((item) => (
                    <Tab
                        key={item.name}
                        className="text-14 font-semibold min-h-40 min-w-64 mx-4 px-12 capitalize opacity-70"
                        disableRipple
                        label={item.name}
                        style={{
                            color: theme.palette.primary.dark,
                            zIndex: 1,
                            borderRadius: '35px',
                        }}
                    />
                ))}
            </Tabs>
        </div>
    )
}
