import { customerAddressType } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { GoogleMapsAddressAutoCompleteFieldBuilder } from 'src/common/ui-kit/form-fields/GoogleMapsAddressAutoComplete/GoogleMapsAddressAutoCompleteField'
import { GeocodeResult, getLatLng, getZipCode } from 'use-places-autocomplete'
import { extractFromGeocode } from 'src/common/ui-kit/form-fields/GoogleMapsAddressAutoComplete/utils'

/**
 * Type customer address used in the form (the same as cusomer address type without the place id).
 */
type customerAddressTypeField = customerAddressType & /**
 *
 */ /**
 *
 */ /**
 *
 */ {
    /**
     *
     */
    placeId: string
}

/**
 *
 */
export const GoogleMapsAddressAutoCompleteFieldFormCustomer =
    GoogleMapsAddressAutoCompleteFieldBuilder<customerAddressTypeField>()

/**
 * Function used to extract customer address field value from geocode result.
 *
 * @param geocode Geocode result.
 * @returns {customerAddressTypeField} Customer address field value.
 */
export const getFormattedValueForCustomerAddress = async (geocode: GeocodeResult) => {
    const zipCode = await getZipCode(geocode, false)
    const country = await extractFromGeocode(geocode, false, 'country')
    const city = await extractFromGeocode(geocode, false, 'locality')
    const latLng = await getLatLng(geocode)
    return {
        name: geocode.formatted_address,
        city: city,
        country: country,
        zipCode: zipCode,
        lat: latLng.lat,
        lng: latLng.lng,
        placeId: geocode.place_id,
    }
}

/**
 * Get value to display for customer address field.
 *
 * @param value Customer address field value.
 * @returns {string} Formatted value label.
 */
export const getDisplayedLabelFromValueForCustomerAddress = (value: customerAddressTypeField) => {
    return value?.name ?? null
}

/**
 * Get placeId to fit options and value in customer address field.
 *
 * @param value Customer address field value.
 * @returns {string} PlaceId.
 */
export const getPlaceIdFromValueForCustomerAddress = (value: customerAddressTypeField) => {
    return value.placeId
}

/**
 * This function return the address addition extracted from value.
 *
 * @param value Formatted value.
 * @returns Return label or null.
 */
export const getAdditionAddressValue = (value: customerAddressTypeField): string => {
    return value?.addressAddition ?? ''
}

/**
 * This function set the address addition extracted in value.
 *
 * @param addressValue Formatted value.
 * @param newValue New value to set.
 * @returns Return value with address addition.
 */
export const setAdditionAddressValue = (
    addressValue: customerAddressTypeField,
    newValue: string,
): customerAddressTypeField => {
    return {
        ...addressValue,
        addressAddition: newValue,
    }
}
