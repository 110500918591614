import { useState } from 'react'
import { axios, catchError, ILoadDataPagination } from 'src/common/react-platform-components'
import { customerT } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { useIntl } from 'src/common/react-platform-translation'
import { useSnackbar } from 'notistack'
import { AxiosResponse } from 'axios'
import { getMsgFromAxiosError } from '../utils'
import { capitalize } from '@mui/material'
import { API_RESOURCES_URL } from 'src/configs'
import { equipmentTypeT } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/equipmentsTable/equipmentsHooks'

/**
 * Installers Installations Request URL.
 */
export const INSTALLATIONS_REQUESTS_URL = `${API_RESOURCES_URL}/installers/installation-requests`
/**
 * Lead Exchanges Request URL.
 */
export const LEAD_EXCHANGES_URL = `${API_RESOURCES_URL}/installers/leads/installation-requests`

/**
 * Load Customer Installations Requests which represents All Installations Requests of a particular customer.
 *
 * @param idInstallationRequest The id of the selected InstallationRequest.
 * @param newStatus The newStatus to update the selected InstallationRequest.
 * @returns Return message of success installationsRequestsList or error.
 */
export type updateInstallationsRequestsType = (idInstallationRequest: number, newStatus: statusType) => Promise<void>

/**
 * Installation Request Status type.
 */
export type statusType = 'NEW' | 'PENDING' | 'CLOSED' | 'CANCELED'

/**
 * InstallationsRequests type.
 */
export type IInstallationsRequests = IInstallationRequest[] | []

/**
 * Installation Request Interface.
 */
export interface IInstallationRequest {
    /**
     * Id of the Installation Request.
     */
    id: number
    /**
     * Equipment Type of the installation request.
     */
    equipmentType: equipmentTypeT
    /**
     * Equipment Brand of the installation request.
     */
    equipmentBrand: string
    /**
     * Equipment Model of the installation request.
     */
    equipmentModel: string
    /**
     * Creation Date of the installation request.
     */
    createdAt: string
    /**
     * Update Date of the installation request.
     */
    updatedAt: string
    /**
     * Budget Date of the installation request.
     */
    budget: string
    /**
     * Comment of the installation request.
     */
    comment: string
    /**
     * Status of the installation request.
     */
    status: statusType
    /**
     * Customer of the installation request (it's the same type as customerT without createdAt).
     */
    user?: Exclude<customerT, 'createdAt'>
}

const ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE = "Erreur lors du chargement des demandes d'installations"
/**
 * Installations Requests hook.
 *
 * @returns Installations Requests manipulation methods and variables.
 */
export function useInstallationsRequests() {
    const [isInstallationsRequestsInProgress, setIsInstallationsRequestsInProgress] = useState<boolean>(false)
    const [installationsRequestsList, setInstallationsRequestsList] = useState<IInstallationsRequests | null>(null)
    const { formatMessage } = useIntl()
    const { enqueueSnackbar } = useSnackbar()

    /**
     * Load Installations Requests.
     *
     * @returns Return message of success installationsRequestsList or error.
     */
    const loadLeadExchanges = async () => {
        setIsInstallationsRequestsInProgress(true)
        try {
            const { data: responseData } = await axios.get<ILoadDataPagination<IInstallationsRequests>>(
                `${LEAD_EXCHANGES_URL}?size=100`,
            )
            setInstallationsRequestsList(responseData.items)
            setIsInstallationsRequestsInProgress(false)
        } catch (e) {
            enqueueSnackbar(
                formatMessage({
                    id: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                    defaultMessage: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                }),
                { variant: 'error' },
            )
            setIsInstallationsRequestsInProgress(false)
            throw catchError(e)
        }
    }

    /**
     * Load Installations Requests.
     *
     * @returns Return message of success installationsRequestsList or error.
     */
    const loadAllCustomersInstallationsRequests = async () => {
        setIsInstallationsRequestsInProgress(true)
        try {
            const { data: responseData } = await axios.get<ILoadDataPagination<IInstallationsRequests>>(
                `${INSTALLATIONS_REQUESTS_URL}?size=100`,
            )
            setInstallationsRequestsList(responseData.items)
            setIsInstallationsRequestsInProgress(false)
        } catch (e) {
            enqueueSnackbar(
                formatMessage({
                    id: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                    defaultMessage: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                }),
                { variant: 'error' },
            )
            setIsInstallationsRequestsInProgress(false)
            throw catchError(e)
        }
    }

    /**
     * Load Customer Installations Requests which represents All Installations Requests of a particular customer.
     *
     * @param customerId The Id of the customerDetails.
     * @returns Return message of success installationsRequestsList or error.
     */
    const loadCustomerInstallationsRequests = async (customerId: number) => {
        setIsInstallationsRequestsInProgress(true)
        try {
            const { data: responseData } = await axios.get<ILoadDataPagination<IInstallationsRequests>>(
                `${INSTALLATIONS_REQUESTS_URL}?user_id=${customerId}&size=100`,
            )
            setInstallationsRequestsList(responseData.items)
            setIsInstallationsRequestsInProgress(false)
        } catch (e) {
            enqueueSnackbar(
                formatMessage({
                    id: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                    defaultMessage: ERROR_LOAD_INSTALLATIONS_REQUESTS_MESSAGE,
                }),
                { variant: 'error' },
            )
            setIsInstallationsRequestsInProgress(false)
            throw catchError(e)
        }
    }

    /**
     * Update Installation Request, the status will update.
     *
     * @param idInstallationRequest The id of the selected InstallationRequest.
     * @param status The newStatus to update the selected InstallationRequest.
     * @returns Show snackbar if success or fail.
     */
    const updateInstallationRequest = async (idInstallationRequest: number, status: statusType) => {
        setIsInstallationsRequestsInProgress(true)

        try {
            const { data: responseData } = await axios.put<statusType, AxiosResponse<IInstallationRequest>>(
                `${INSTALLATIONS_REQUESTS_URL}/${idInstallationRequest}`,
                {
                    status,
                },
            )
            enqueueSnackbar(
                formatMessage({
                    id: "L'état de la demande a été modifié avec succès",
                    defaultMessage: "L'état de la demande a été modifié avec succès",
                }),
                { variant: 'success' },
            )
            setIsInstallationsRequestsInProgress(false)
            return responseData
        } catch (e) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur lors de la modification de la demande',
                    defaultMessage: 'Erreur lors de la modification de la demande',
                }),
                { variant: 'error' },
            )
            setIsInstallationsRequestsInProgress(false)
            throw catchError(e)
        }
    }

    /**
     * Update Installation Request, the status will update.
     *
     * @param idInstallationRequest The id of the selected InstallationRequest.
     * @returns Show snackbar if success or fail.
     */
    const updateLeadExchanges = async (idInstallationRequest: number) => {
        setIsInstallationsRequestsInProgress(true)

        try {
            await axios.get(`${LEAD_EXCHANGES_URL}/${idInstallationRequest}/link`)
            const { data: responseData } = await axios.get<IInstallationRequest>(
                `${INSTALLATIONS_REQUESTS_URL}/${idInstallationRequest}`,
            )
            const customerName = `${capitalize(
                responseData.user!.firstName,
            )} ${responseData.user!.lastName.toUpperCase()}`
            enqueueSnackbar(
                formatMessage(
                    {
                        id: `Félicitations, vous avez acquis un nouveau lead : {name}`,
                        defaultMessage: `Félicitations, vous avez acquis un nouveau lead : {name}`,
                    },
                    {
                        name: customerName,
                    },
                ),
                { variant: 'success' },
            )
            setIsInstallationsRequestsInProgress(false)
        } catch (e) {
            enqueueSnackbar(getMsgFromAxiosError(e), { variant: 'error' })
            setIsInstallationsRequestsInProgress(false)
            throw catchError(e)
        }
    }

    return {
        isInstallationsRequestsInProgress,
        loadAllCustomersInstallationsRequests,
        installationsRequestsList,
        loadCustomerInstallationsRequests,
        updateInstallationRequest,
        loadLeadExchanges,
        updateLeadExchanges,
    }
}
