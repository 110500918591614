import { useTheme, Icon } from '@mui/material'
import TypographyFormatMessage from 'src/common/ui-kit/components/TypographyFormatMessage/TypographyFormatMessage'
import {
    NRLINK_ENEDIS_OFF_MESSAGE,
    ENPHASE_OFF_MESSAGE,
} from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/utils/ChartsVariables'
import { ChartErrorMessageProps } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ChartErrorMessage/ChartErrorMessage.d'

/**
 * ConsumptionErrorMessage component shows an error message depending on the received parameters nrLinkEnedisOff and enphaseOff.
 *
 * @param root0 N/A.
 * @param root0.nrLinkEnedisOff Nrlink and enedis are off.
 * @param root0.enphaseOff Enphase is off.
 * @returns ConsumptionErrorMessage component.
 */
export const ChartErrorMessage = ({ nrLinkEnedisOff, enphaseOff }: ChartErrorMessageProps) => {
    const theme = useTheme()
    return (
        <div style={{ background: theme.palette.primary.main }} className="p-16">
            <div className="container relative  p-16 sm:p-24 flex-col text-center flex items-center justify-center">
                <Icon style={{ fontSize: '4rem', marginBottom: '1rem', color: theme.palette.warning.light }}>
                    error_outline_outlined
                </Icon>
                {nrLinkEnedisOff && (
                    <TypographyFormatMessage style={{ color: theme.palette.warning.light }}>
                        {NRLINK_ENEDIS_OFF_MESSAGE}
                    </TypographyFormatMessage>
                )}
                {enphaseOff && (
                    <TypographyFormatMessage style={{ color: theme.palette.warning.light }}>
                        {ENPHASE_OFF_MESSAGE}
                    </TypographyFormatMessage>
                )}
            </div>
        </div>
    )
}
