import { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { useIntl } from 'react-intl'
import {
    getMetricType,
    IMetric,
    metricFiltersType,
    metricIntervalType,
    metricRangeType,
    metricTargetsType,
} from 'src/modules/Metrics/Metrics.d'
import { axios } from 'src/common/react-platform-components'
import { API_RESOURCES_URL } from 'src/configs'

/**
 * Metrics endpoint.
 */
export const METRICS_API = `${API_RESOURCES_URL}/ned_query_enduser_metrics`

/**
 * Consumption Metrics hook.
 *
 * @param initialState Initial State of the hook.
 * @returns Consumption metrics hook.
 */
export function useMetrics(initialState: getMetricType) {
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()
    const [isMetricsLoading, setIsMetricsLoading] = useState(true)
    const [data, setData] = useState<IMetric[] | []>([])
    const [range, setRange] = useState<metricRangeType>(initialState.range)
    const [metricsInterval, setMetricsInterval] = useState<metricIntervalType>(initialState.interval)
    const [targets, setTargets] = useState<metricTargetsType[]>(initialState.targets)
    const [filters, setFilters] = useState<metricFiltersType[]>(initialState.filters)

    useEffect(() => {
        /**
         * Get Metrics function: Everytime filters or range or targets or metricsInterval has changed, it triggers the function call.
         */
        ;(async () => {
            setIsMetricsLoading(true)
            try {
                const response = await axios.post(METRICS_API, {
                    interval: metricsInterval,
                    range,
                    targets,
                    adhocFilters: filters,
                })
                setData(response.data)
            } catch (error) {
                setIsMetricsLoading(false)
                enqueueSnackbar(
                    formatMessage({
                        id: 'Erreur de chargement des données de consommation du client',
                        defaultMessage: 'Erreur de chargement des données de consommation du client',
                    }),
                    {
                        variant: 'error',
                        autoHideDuration: 5000,
                    },
                )
            }
            setIsMetricsLoading(false)
        })()
    }, [enqueueSnackbar, filters, formatMessage, metricsInterval, range, targets])

    return {
        isMetricsLoading,
        data,
        range,
        metricsInterval,
        targets,
        filters,
        setRange,
        setMetricsInterval,
        setTargets,
        setFilters,
    }
}
