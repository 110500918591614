import { IRoute } from 'src/common/react-platform-components/utils/mm'
import { authTypes } from 'src/common/react-platform-components'
import ModifyProfile from './ModifyProfile'

const URL_MODIFY_PROFILE = '/modify-profile'

const ModifyProfileConfig = [
    {
        path: URL_MODIFY_PROFILE,
        component: ModifyProfile,
        auth: { authType: authTypes.loginRequired },
    } as IRoute</**
     *
     */
    {}>,
]

export { ModifyProfileConfig, URL_MODIFY_PROFILE }
