import React from 'react'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router'
import RoomIcon from '@mui/icons-material/Room'
import PhoneIcon from '@mui/icons-material/Phone'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import clsx from 'clsx'
import { IUser } from 'src/modules/User'

/**.
 * Represent the component UI that render the item data, all the inputs represents add ons to make our Card better
 *
 * @param props N/A.
 * @param props.element Element Object.
 * @returns Card that contains the item data with the different properties given.
 */
const InstallerCard = ({
    element: installer,
}: // eslint-disable-next-line jsdoc/require-jsdoc
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    element: IUser
}) => {
    const companyName = `${installer.companyName ? installer.companyName : ''}`
    const location = useLocation()

    const [raisedState, setRaisedState] = React.useState(false)
    return (
        <NavLink className="w-full" to={`${location.pathname}/${installer.id}/installer-record`} key={installer.id}>
            <Card
                className="relative cursor-pointer flex-wrap rounded-16"
                onMouseOver={() => setRaisedState(true)}
                onMouseOut={() => setRaisedState(false)}
                raised={raisedState}
            >
                <div className={clsx('px-24 pt-16 pb-0 flex items-center justify-between flex-wrap')}>
                    <Typography className="font-bold text-20 whitespace-normal">{companyName}</Typography>
                </div>

                <CardContent className="px-32 py-0">
                    <Divider className="my-16" />

                    <div className="flex flex-col">
                        <Typography variant="subtitle1" className="mb-10 text-13 flex ">
                            <span className="font-semibold mr-4 whitespace-normal">
                                <RoomIcon></RoomIcon>
                            </span>
                            {installer.address.name ? installer.address.name : ''}
                        </Typography>

                        <Typography variant="subtitle1" className="mb-10 text-13 whitespace-normal">
                            <span className="font-semibold mx-4">
                                <PhoneIcon></PhoneIcon>
                            </span>
                            {installer.phone}
                        </Typography>
                        <Typography variant="subtitle1" className="text-13">
                            <span className="font-semibold mx-4">
                                <MailOutlineIcon></MailOutlineIcon>
                            </span>
                            {installer.email}
                        </Typography>
                    </div>
                </CardContent>
            </Card>
        </NavLink>
    )
}

export default InstallerCard
