import { useState } from 'react'
import { ChartsPeriod } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ChartsPeriod'
import { periodType } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/Charts.d'
import {
    formatMetricFilter,
    getRange,
} from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/utils/ChartsFunctions'
import { IMeterT } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/meters'
import { getMetricType, IMetric, metricTargetsEnum } from 'src/modules/Metrics/Metrics.d'
import { useMetrics } from 'src/modules/Metrics/metricsHook'
import { SelectMeters } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/SelectMeters/SelectMeters'
import { SelectChangeEvent } from '@mui/material'
import { ConsumptionContainer } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ConsumptionContainer'
import { ProductionContainer } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ProductionContainer'

/**
 * Container component for all the charts.
 *
 * @param root0 N/A.
 * @param root0.metersList MetersList.
 * @returns ChartsContainer components.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export const ChartsContainer = ({ metersList }: { metersList: IMeterT[] }) => {
    const [period, setPeriod] = useState<periodType>('daily')

    /**
     * InitialMetricsStates for useMetrics.
     * ! The order of the targets is important!
     */
    const initialMetricsHookValues: getMetricType = {
        interval: '30m',
        range: getRange(period),
        targets: [
            {
                target: metricTargetsEnum.autoconsumption,
                type: 'timeserie',
            },
            {
                target: metricTargetsEnum.enedisConsumption,
                type: 'timeserie',
            },
            {
                target: metricTargetsEnum.globalConsumtpion,
                type: 'timeserie',
            },
            {
                target: metricTargetsEnum.injectedProduction,
                type: 'timeserie',
            },
            {
                target: metricTargetsEnum.totalProduction,
                type: 'timeserie',
            },
        ],
        filters: [formatMetricFilter(metersList[0].housingId)],
    }

    const { data, range, isMetricsLoading, setMetricsInterval, setRange, setFilters, filters } =
        useMetrics(initialMetricsHookValues)

    // Finds the current meter and checks the consents for it.
    const currentHousingFilterValue = filters.map((item) => item.value) // item.value reprensent the value of the select ( in our case the housingId)
    const selectedMeter = metersList.filter(({ housingId }) => currentHousingFilterValue.includes(housingId))[0]
    /**
     * Nrlink off.
     */
    const nrLinkOff = selectedMeter.consents.nrlink.state !== 'CONNECTED'
    /**
     * Enedis (SGE) off.
     */
    const enedisOff = selectedMeter.consents.sge.state !== 'CONNECTED'
    /**
     * Enphase off.
     */
    const enphaseOff = selectedMeter.consents.enphase.state !== 'ACTIVE'
    /**
     * Function to get consumption targets.
     *
     * @param data Metric data.
     * @returns All targets related to Consumption.
     */
    function getConsumptionTargets(data: IMetric[]) {
        return data.filter(
            (targetItem) =>
                targetItem.target !== metricTargetsEnum.injectedProduction &&
                targetItem.target !== metricTargetsEnum.totalProduction,
        )
    }

    /**
     * Function to get production targets.
     *
     * @param data Metric data.
     * @returns All targets related to Production.
     */
    function getProductionTargets(data: IMetric[]) {
        return data.filter(
            (targetItem) =>
                targetItem.target !== metricTargetsEnum.globalConsumtpion &&
                targetItem.target !== metricTargetsEnum.enedisConsumption,
        )
    }
    /**
     * HandleOnMeterSelect function.
     *
     * @param event HandleOnMeterSelect event.
     * @param setSelectedMeter Set Selected Meter on value change.
     */
    const handleOnMeterSelect = (event: SelectChangeEvent, setSelectedMeter: (value: string) => void) => {
        setSelectedMeter(event.target.value)
        setFilters([formatMetricFilter(event.target.value)]) // value will be housingId
    }

    // TODO: Improve ProductionContainer and ConsumptionContainer to have one ChartContainer compoent with different props, instead of duplicating code in both component (ConsumptionChart and ProductionChart have the same code)!
    const chartContainersProps = { enedisOff, nrLinkOff, enphaseOff, data, period, range, isMetricsLoading }

    return (
        <div className="mb-16">
            <div className="flex flex-col md:flex-row justify-between items-center w-full py-8">
                <div className="mb-10 md:mb-0">
                    <SelectMeters metersList={metersList} handleOnMeterSelect={handleOnMeterSelect} />
                </div>
                <ChartsPeriod setMetricsInterval={setMetricsInterval} setPeriod={setPeriod} setRange={setRange} />
            </div>
            <ConsumptionContainer {...chartContainersProps} data={getConsumptionTargets(data)} />
            <div className="py-10"></div>
            <ProductionContainer {...chartContainersProps} data={getProductionTargets(data)} />
        </div>
    )
}
