import { useIntl } from 'src/common/react-platform-translation'
import { useToggle } from 'react-use'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'src/redux'
import { IUser } from '../model'
import { useSnackbar } from 'notistack'
import { isMatch } from 'lodash'
import { handleUpdateInstallerError as handleUpdateUserError } from 'src/modules/DetailsPages/InstallerDetails/installerDetailsHooks'

/**
 * Hook for ModifyProfile.
 *
 * @returns UseModifyProfile.
 */
export const useModifyProfile = () => {
    const dispatch = useDispatch<Dispatch>()
    const [isModifyInProgress, setIsModifyInProgress] = useToggle(false)
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()
    const { user } = useSelector(({ userModel }: RootState) => userModel)

    /**
     * Submit function.
     *
     * @param data Data for modification in my profile.
     */
    const onSubmit = async (data: IUser) => {
        const dataIsNotModified = isMatch(user as IUser, data)
        if (dataIsNotModified) return
        setIsModifyInProgress(true)
        try {
            await dispatch.userModel.updateCurrentUser({ data })
            await dispatch.userModel.fetchCurrentUser({})
            setIsModifyInProgress(false)
            enqueueSnackbar(
                formatMessage({
                    id: 'Profil modifié avec succès',
                    defaultMessage: 'Profil modifié avec succès',
                }),
                { variant: 'success', autoHideDuration: 8000 },
            )
        } catch (error) {
            setIsModifyInProgress(false)
            const errorMessage = handleUpdateUserError(error)
            enqueueSnackbar(formatMessage({ id: errorMessage, defaultMessage: errorMessage }), { variant: 'error' })
            throw error
        }
    }

    return { isModifyInProgress, onSubmit }
}
