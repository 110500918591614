import { rest } from 'msw'
import { statusType } from 'src/modules/InstallationsRequests/installationsRequestsHooks'
import {
    INSTALLATIONS_REQUESTS_URL,
    LEAD_EXCHANGES_URL,
} from 'src/modules/InstallationsRequests/installationsRequestsHooks'

const statusArray: statusType[] = ['NEW', 'PENDING', 'CANCELED', 'CLOSED']
/**
 *
 */
const CREATED_AT_DATA = '2021-12-15T14:07:38.138000'

/**
 * The api endpoint url of the Installations requests of a specific customer.
 */

/**
 * Mocked data for Installations Requests.
 */
export var TEST_INSTALLATIONS_REQUESTS = [
    /** INSTALLATIONS REQUESTS OF Current Authenticated Installer. */
    // LEANE GRAHAM.
    {
        id: 9,
        budget: 1000.0,
        user: {
            id: 1,
            email: 'Sincere@april.biz',
            phone: '1-770-736-8031 x56442',
            first_name: 'Leanne',
            last_name: 'Graham',
            address: {
                name: 'Apt. 556, Gwenborough. 92998-3874, LYON 690001',
                city: 'Gwenborough',
                zip_code: '92998-3874',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.706877,
                lng: -74.011265,
            },
        },
        equipment_brand: 'Inspirion',
        status: 'NEW',
        comment: 'Besoin urgent',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'SOLAR',
        equipment_model: 'AZS',
    },
    {
        id: 10,
        budget: 260.0,
        user: {
            id: 1,
            email: 'Sincere@april.biz',
            phone: '1-770-736-8031 x56442',
            first_name: 'Leanne',
            last_name: 'Graham',
            address: {
                name: 'Apt. 556, Gwenborough. 92998-3874, LYON 690001',
                city: 'Gwenborough',
                zip_code: '92998-3874',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.706877,
                lng: -74.011265,
            },
        },
        equipment_brand: 'Inspirion',
        status: 'NEW',
        comment:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer eleifend, ante id hendrerit efficitur, ligula arcu laoreet mi, vel sagittis diam felis sed tellus. Mauris congu',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'SOLAR',
        equipment_model: 'AZS',
    },
    // ERVIN HOWELL
    {
        id: 1,
        budget: 1010.0,
        user: {
            id: 2,
            first_name: 'Ervin',
            phone: '010-692-6593 x09125',
            last_name: 'Howell',
            email: 'Shanna@melissa.tv',
            address: {
                name: 'Suite 879, Wisokyburgh. 90566-7771',
                city: 'Wisokyburgh',
                zip_code: '90566-7771',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7168,
                lng: -74.0465,
            },
        },
        equipment_brand: 'Azero',
        status: 'CLOSED',
        comment: 'je recherche une installation rapide.',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'OTHER',
        equipment_model: 'P90X',
    },
    {
        id: 8,
        budget: 430.0,
        user: {
            id: 2,
            first_name: 'Ervin',
            last_name: 'Howell',
            email: 'Shanna@melissa.tv',
            phone: '010-692-6593 x09125',
            address: {
                name: 'Suite 879, Wisokyburgh. 90566-7771',
                city: 'Wisokyburgh',
                zip_code: '90566-7771',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7168,
                lng: -74.0465,
            },
        },
        equipment_brand: 'Brand',
        status: 'CANCELED',
        comment: '',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'DEMOTIC',
        equipment_model: 'BSZ',
    },
    // Patricia Lesback
    {
        id: 11,
        budget: 2000.0,
        user: {
            id: 4,
            first_name: 'Patricia',
            last_name: 'Lebsack',
            phone: '493-170-9623 x156',
            email: 'Julianne.OConner2@kory.org',
            address: {
                name: 'Apt. 692, South2 Elvis. 53919-4257',
                city: 'McKenziehaven',
                zip_code: '595190-4157',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7405,
                lng: -73.9878,
            },
        },
        equipment_brand: 'T-invert8',
        status: 'PENDING',
        comment: '',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'INVERTER',
        equipment_model: 'T-C',
    },
    {
        id: 12,
        budget: 19879.0,
        user: {
            id: 4,
            first_name: 'Patricia',
            last_name: 'Lebsack',
            phone: '(775)976-6794 x41206',
            email: 'Julianne.OConner@kory.org',
            address: {
                name: 'Apt. 692, South Elvis. 53919-4257',
                city: 'South Elvis',
                zip_code: '53919-4257',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7673,
                lng: -74.0462,
            },
        },
        equipment_brand: 'T-invert8',
        status: 'NEW',
        comment: 'Qualité exigée',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'INVERTER',
        equipment_model: 'ref',
    },

    // Chelsey Dietrich
    {
        id: 13,
        budget: 888.0,
        user: {
            id: 5,
            first_name: 'Chelsey',
            last_name: 'Dietrich',
            phone: '(254)954-1289',
            email: 'Lucio_Hettinger@annie.ca',
            address: {
                name: 'Suite 351, Roscoeview. 33263',
                city: 'Roscoeview',
                zip_code: '33263',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.9003,
                lng: -74.5117,
            },
        },
        equipment_brand: 'axionz',
        status: 'CANCELED',
        comment: '',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'OTHER',
        equipment_model: 'axxa12',
    },
    {
        id: 6,
        budget: 1000.0,
        user: {
            id: 5,
            first_name: 'Chelsey',
            last_name: 'Dietrich',
            phone: '(254)954-1289',
            email: 'Lucio_Hettinger@annie.ca',
            address: {
                name: 'Suite 351, Roscoeview. 33263',
                city: 'Roscoeview',
                zip_code: '33263',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.9003,
                lng: -74.5117,
            },
        },
        equipment_brand: 'axionz',
        status: 'NEW',
        comment: 'new demand',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'DEMOTIC',
        equipment_model: 'axdemo12',
    },

    /** Installations Requests of customer that don't have installers. */
    // Customer 1: Clementine DuPont.
    {
        id: 7,
        budget: 999999.0,
        user: {
            id: 123,
            first_name: 'Clementine',
            installer: null,
            last_name: 'DuPont',
            phone: '210.067.6132',
            email: 'Nathan@yesenia.net',
            address: {
                name: 'Suite 847, McKenziehaven. 59590-4157',
                city: 'McKenziehaven',
                zip_code: '59590-4157',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7405,
                lng: -73.9878,
            },
        },
        equipment_brand: 'Nami',
        status: 'NEW',
        comment: 'Site panneau solaire',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'SOLAR',
        equipment_model: 'TESLA',
    },
    {
        id: 5,
        budget: 12000.0,
        user: {
            id: 123,
            first_name: 'Clementine',
            installer: null,
            last_name: 'DuPont',
            phone: '210.067.6132',
            email: 'Nathan@yesenia.net',
            address: {
                name: 'Suite 847, McKenziehaven. 59590-4157',
                city: 'McKenziehaven',
                zip_code: '59590-4157',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7405,
                lng: -73.9878,
            },
        },
        equipment_brand: 'Nami-Dem23',
        status: 'NEW',
        comment: 'Demotic site',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'DEMOTIC',
        equipment_model: 'TESLA',
    },

    // Customer 3: Pierre Jitek
    {
        id: 19,
        budget: 3600.0,
        user: {
            id: 124,
            installer: null,
            first_name: 'Pierre',
            last_name: 'Jitek',
            phone: '024-648-3804',
            email: 'Julianne.OConner@kory.org',
            address: {
                name: 'Apt. 692, South Elvis. 53919-4257',
                city: 'South Elvis',
                zip_code: '53919-4257',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.7673,
                lng: -74.0462,
            },
        },
        equipment_brand: 'brand',
        status: 'NEW',
        comment: 'comment',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'SOLAR',
        equipment_model: 'AZS',
    },
    // CUSTOMER 4: Dennis Shulist
    {
        id: 15,
        budget: 15000.0,
        user: {
            id: 125,
            installer: null,
            first_name: 'Dennis',
            last_name: 'Schulist',
            phone: '(614) 857-6338',
            email: 'Karley_Dach@jasper.info',
            address: {
                name: '8424 Padberg Flats',
                city: 'South Christy',
                zip_code: '23505-1337',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.8214,
                lng: -75.2052,
            },
        },
        equipment_brand: 'Teski',
        status: 'NEW',
        comment: '',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'INVERTER',
        equipment_model: 'Teskinv',
    },
    {
        id: 16,
        budget: 1000.0,
        user: {
            id: 125,
            installer: null,
            first_name: 'Dennis',
            last_name: 'Schulist',
            phone: '1-764-337-4085',
            email: 'Karley_Dach@jasper.info',
            address: {
                name: '8424 Padberg Flats',
                city: 'South Christy',
                zip_code: '23505-1337',
                address_addition: 'testFDF',
                country: 'France',
                lat: 40.8214,
                lng: -75.2052,
            },
        },
        equipment_brand: 'Brand',
        status: 'NEW',
        comment: 'comment',
        created_at: CREATED_AT_DATA,
        updated_at: CREATED_AT_DATA,
        equipment_type: 'OTHER',
        equipment_model: 'SZA',
    },
]

const installationsRequestsRest = [
    // GET Lead Exchanges. (through Token)
    rest.get(LEAD_EXCHANGES_URL, (req, res, ctx) => {
        const items = TEST_INSTALLATIONS_REQUESTS.filter((c) => c.user.installer === null)
        return res(
            ctx.status(200),
            ctx.delay(1000),
            ctx.json({
                items,
                size: 100,
                page: 1,
                total: items.length,
            }),
        )

        // Generate error to force hook to be correct.
    }),
    // GET Installations Requests of Current Installer. (through Token)
    rest.get(`${INSTALLATIONS_REQUESTS_URL}`, (req, res, ctx) => {
        const customerId = req.url.searchParams.get('user_id')
        // GET All Customers Installations Requests of Current Installer. (through Token)
        let items = TEST_INSTALLATIONS_REQUESTS.filter((c) => c.user.installer !== null)
        // GET Installations Requests of specified Customer.
        if (customerId) {
            if (!parseInt(customerId))
                // Generate error to force hook to be correct.
                return res(ctx.status(400), ctx.delay(1000))
            items = TEST_INSTALLATIONS_REQUESTS.filter((c) => parseInt(customerId) === c.user.id)
        }

        return res(
            ctx.status(200),
            ctx.delay(1000),
            ctx.json({
                items,
                size: 100,
                page: 1,
                total: items.length,
            }),
        )

        // Generate error to force hook to be correct.
    }),

    // Update Status of the Installation Request, this request can be whether (specified customer) or (current installer).
    rest.put</**
     *
     */
    {
        /**
         *
         */
        status: statusType
    }>(`${INSTALLATIONS_REQUESTS_URL}/:id`, (req, res, ctx) => {
        const { id: installationRequestId } = req.params
        const { status } = req.body

        if (statusArray.includes(status) && parseInt(installationRequestId)) {
            let indexOfInstallationRequest = TEST_INSTALLATIONS_REQUESTS.findIndex(
                (c) => c.id === parseInt(installationRequestId),
            )
            let newInstallationRequest = {
                ...TEST_INSTALLATIONS_REQUESTS[indexOfInstallationRequest],
                status: status,
            }
            TEST_INSTALLATIONS_REQUESTS[indexOfInstallationRequest] = newInstallationRequest

            return res(ctx.status(200), ctx.delay(1000), ctx.json(newInstallationRequest))
        } else {
            return res(ctx.status(400), ctx.delay(1000))
        }
    }),

    // Get One the Installation Request.
    rest.get(`${INSTALLATIONS_REQUESTS_URL}/:id`, (req, res, ctx) => {
        const { id: installationRequestId } = req.params

        if (parseInt(installationRequestId)) {
            let indexOfInstallationRequest = TEST_INSTALLATIONS_REQUESTS.findIndex(
                (c) => c.id === parseInt(installationRequestId),
            )
            return res(
                ctx.status(200),
                ctx.delay(1000),
                ctx.json(TEST_INSTALLATIONS_REQUESTS[indexOfInstallationRequest]),
            )
        } else {
            return res(ctx.status(400), ctx.delay(1000))
        }
    }),

    // Update LeadExchange.
    rest.get(`${LEAD_EXCHANGES_URL}/:id/link`, (req, res, ctx) => {
        const { id: installationRequestId } = req.params

        if (parseInt(installationRequestId)) {
            let indexOfInstallationRequest = TEST_INSTALLATIONS_REQUESTS.findIndex(
                (c) => c.id === parseInt(installationRequestId),
            )
            const customerId = TEST_INSTALLATIONS_REQUESTS[indexOfInstallationRequest].user.id
            // Assign that customer's installations requests to the installer, and thus assign the customer to the installer.
            TEST_INSTALLATIONS_REQUESTS.forEach((installationRequest) => {
                if (installationRequest.user.id === customerId) {
                    delete installationRequest.user.installer
                }
            })
            let newInstallationRequest = TEST_INSTALLATIONS_REQUESTS[indexOfInstallationRequest]

            return res(ctx.status(200), ctx.delay(1000), ctx.json(newInstallationRequest))
        } else {
            return res(ctx.status(400), ctx.delay(1000))
        }
    }),
]
export default installationsRequestsRest
