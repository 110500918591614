import { SyntheticEvent } from 'react'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from 'src/redux'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import ToolbarMenuItem from './ToolbarMenuItem'

/**
 * FAQ URL.
 */
export const FAQ_URL = 'https://www.notion.so/Base-de-connaissance-nrLINK-NED-f1885b925caa4eb6b5d3d8487ea0af4b?pvs=4'

// TODO This is not a generic component to share with medialem, to update.
/**
 * UserMenu UI Component placed usually in Toolbar.
 *
 * @returns UserMenu UI Component.
 */
function UserMenu() {
    /**
     * Fake UserData (which will be in the future got from the rematch state).
     */
    const { user } = useSelector(({ userModel }: RootState) => userModel)
    const [userMenu, setUserMenu] = useState<Element | null>(null)
    const dispatch = useDispatch<Dispatch>()
    const history = useHistory()
    const { formatMessage } = useIntl()

    /**
     * Handler for opening the UserMenu when clicking on UserProfile.
     *
     * @param event Event.
     */
    const userMenuClick = (
        event: SyntheticEvent & /**
         *
         */ /**
         *
         */ /**
         *
         */ {
            /**
             *
             */
            currentTarget: Element
        },
    ) => {
        setUserMenu(event.currentTarget)
    }

    /**
     * Handler for closing the userMenu.
     */
    const userMenuClose = () => {
        setUserMenu(null)
    }
    return (
        <>
            <Button className="min-h-40 min-w-40 px-0 md:px-16 py-0 md:py-6" onClick={userMenuClick} color="inherit">
                <div className="hidden md:flex flex-col mx-4 items-end">
                    <Typography component="span" className="font-semibold flex">
                        {`${user?.firstName} ${user?.lastName}`}
                    </Typography>
                    <Typography className="text-11 font-medium capitalize" color="textSecondary">
                        {user?.role !== undefined ? formatMessage({ id: user?.role, defaultMessage: user?.role }) : ''}
                    </Typography>
                </div>

                <Avatar className="md:mx-4">{user?.firstName !== undefined ? user?.firstName[0] : 'W'}</Avatar>
            </Button>

            <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                classes={{
                    paper: 'py-8',
                }}
            >
                <>
                    <ToolbarMenuItem
                        onMenuItemClick={() => {
                            history.push('/modify-profile')
                        }}
                        iconLabel="account_box"
                        idLabel="Mon profil"
                        defaultMessageLabel="Mon profil"
                    />
                    <ToolbarMenuItem
                        onMenuItemClick={() => {
                            window.open(FAQ_URL, '_blank')
                            userMenuClose()
                        }}
                        iconLabel="help_center"
                        idLabel="FAQ"
                        defaultMessageLabel="FAQ"
                    />
                    <ToolbarMenuItem
                        onMenuItemClick={() => {
                            dispatch.userModel.logout()
                            history.replace('/login')
                        }}
                        iconLabel="exit_to_app"
                        idLabel="Déconnexion"
                        defaultMessageLabel="Déconnexion"
                    />
                </>
            </Popover>
        </>
    )
}

export default UserMenu
