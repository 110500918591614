import { metricTargetsEnum } from 'src/modules/Metrics/Metrics.d'
import { periodType } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/Charts.d'
import { Theme } from '@mui/material/styles/createTheme'
import { isNil } from 'lodash'
import convert, { Unit } from 'convert-units'

/**
 * Data Consumption Period.
 */
export const chartsPeriodData = [
    {
        name: 'Jour',
        interval: '30m',
        period: 'daily' as periodType,
    },
    {
        name: 'Semaine',
        interval: '1d',
        period: 'weekly' as periodType,
    },
    {
        name: 'Mois',
        interval: '1d',
        period: 'monthly' as periodType,
    },

    {
        name: 'Année',
        interval: '1M',
        period: 'yearly' as periodType,
    },
]

/**
 * Properties for the different metricTarget apexCharts of Consumption Chart.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export const chartSpecificities: {
    /**
     * For each metric target, we can store color, label and additional apexCharts ApexYAxis options such as seriesNames, show, ..etc.
     */
    // eslint-disable-next-line jsdoc/require-jsdoc
    [key in metricTargetsEnum]: ApexYAxis & { label?: string }
} = {
    [metricTargetsEnum.globalConsumtpion]: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label: 'Consommation totale',
        show: true,
        seriesName: 'Autoconsommation',
    },
    [metricTargetsEnum.enedisConsumption]: {
        label: 'Electricité achetée sur le réseau',
        seriesName: 'Autoconsommation',
    },
    [metricTargetsEnum.autoconsumption]: {
        label: 'Autoconsommation',
        // seriesName: 'Autoconsommation',
    },
    [metricTargetsEnum.totalProduction]: {
        // eslint-disable-next-line sonarjs/no-duplicate-string
        label: 'Production totale',
        show: true,
        seriesName: 'Autoconsommation',
    },
    [metricTargetsEnum.injectedProduction]: {
        label: 'Electricité redistribuée sur le réseau',
        seriesName: 'Autoconsommation',
    },
}

/**
 * Function that returns the color for each metricTarget Chart.
 *
 * @param chartName MetricTarget Chart.
 * @param theme Current MUI Theme Applied.
 * @returns Color of the chartName.
 */
export const getChartColor = (chartName: metricTargetsEnum, theme: Theme) => {
    switch (chartName) {
        case metricTargetsEnum.globalConsumtpion:
        case metricTargetsEnum.totalProduction:
            return theme.palette.secondary.main
        case metricTargetsEnum.enedisConsumption:
        case metricTargetsEnum.injectedProduction:
            return '#6E9A8B'
        case metricTargetsEnum.autoconsumption:
            return '#B8E1D9'
        default:
            return theme.palette.primary.light
    }
}

/**
 * Function that returns the text shown (in yAxisLabels and tooltip) in chart for each y value point.
 *
 * For Example: Consumption chart for a given y datapoint will show.
 *  - "{value} W" when period is day.
 *  - And "{value} {unit}" depends on the unit of the maximum value, whether (W, kWh, MWh) so that we have one synchronized unit depending on the maxValue.
 *
 * @param yValue Given Y value datapoint in the chart (we'll go through all the y values).
 * @param chartName MetricTarget Chart.
 * @param unit The unit for consumption, it's given outside as its related with the unit of maximum value to have one unit for all values in consumption chart.
 * @returns Text shown for each y value datapoint.
 */
export const getYPointValueLabel = (
    yValue: number | null | undefined,
    chartName: metricTargetsEnum,
    unit?: Unit,
): string => {
    // IsNill check that value is undefined or null.
    const value = isNil(yValue) ? '' : yValue

    if (value === '') {
        return ` ${unit}`
    }
    return `${convert(value).from('Wh').to(unit!).toFixed(2)} ${unit}`
}
/**
 * Graph consumption name.
 */
export const GRAPH_CONSUMPTION_TITLE = 'Graphique de Consommation'

/**
 * Graph production title.
 */
export const GRAPH_PRODUCTION_TITLE = 'Graphique de Production'

/**
 * Message to show if Nrlink and enedis are off.
 */
export const NRLINK_ENEDIS_OFF_MESSAGE = "L'utilisateur n'a pas connecté Enedis, ni son nrLink"
/**
 * Message to show if Enphase is off.
 */
export const ENPHASE_OFF_MESSAGE = "L'utilisateur n'a pas connecté son onduleur"
