import React, { useState, useEffect } from 'react'
import Icon from '@mui/material/Icon'
import { useIntl } from 'react-intl'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import { INotification, useNotifications } from './notificationsHooks'
import FuseScrollbars from 'src/common/ui-kit/fuse/components/FuseScrollbars'
import { styled } from '@mui/material/styles'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import { ButtonLoader } from 'src/common/ui-kit'
import NotificationCard from './NotificationCard'

const StyledSwipeableDrawer = styled(SwipeableDrawer)(({ theme }) => ({
    '& .MuiDrawer-paper': {
        backgroundColor: theme.palette.background.default,
        width: 320,
    },
}))

/**
 * Notifications Panel that is rendered on the right side to read detailed notifications.
 *
 * @param props N/A.
 * @param props.closeNotificationPanel Callback function when closing NotificationsPanel.
 * @returns Notifications Panel Component.
 */
const NotificationsPanel = ({
    closeNotificationPanel,
}: /**
 *
 */
{
    /**
     *
     */
    closeNotificationPanel: () => void
}) => {
    const { notificationsList, loadNotifications, isNotificationsLoading, noMoreNotificationsToLoad } =
        useNotifications()
    const { formatMessage } = useIntl()

    const [isLoadedNotificationsListWhenMountedComponent, setIsLoadedNotificationsListWhenMountedComponent] =
        useState(false)

    useEffect(() => {
        if (isLoadedNotificationsListWhenMountedComponent === false) {
            loadNotifications()
            setIsLoadedNotificationsListWhenMountedComponent(true)
        }
    }, [loadNotifications, isLoadedNotificationsListWhenMountedComponent])

    return (
        <StyledSwipeableDrawer
            open={true}
            anchor="right"
            // onOpen is a required prop to add in SwipeableDrawer even if it's empty.
            onOpen={(ev) => {}}
            onClose={closeNotificationPanel}
            disableSwipeToOpen
        >
            <IconButton className="m-4 absolute top-0 right-0 z-999" onClick={closeNotificationPanel} size="large">
                <Icon color="action">close</Icon>
            </IconButton>
            {notificationsList === null ? (
                <FuseLoading />
            ) : notificationsList.length === 0 ? (
                <div className="flex flex-1 items-center justify-center p-16">
                    <Typography className="text-24 text-center" color="textSecondary">
                        {formatMessage({
                            id: "Il n'y a pas de notifications.",
                            defaultMessage: "Il n'y a pas de notifications.",
                        })}
                    </Typography>
                </div>
            ) : (
                <FuseScrollbars className="p-16">
                    <div className="flex flex-col">
                        <div className="flex justify-between items-end pt-32 mb-36">
                            <Typography className="text-28 font-semibold leading-none">
                                {formatMessage({
                                    id: 'Notifications',
                                    defaultMessage: 'Notifications',
                                })}
                            </Typography>
                        </div>
                        {notificationsList.map((notification: INotification) => (
                            <React.Fragment key={notification.id}>
                                <NotificationCard notification={notification} />
                            </React.Fragment>
                        ))}
                    </div>
                    {noMoreNotificationsToLoad ? (
                        <></>
                    ) : (
                        <ButtonLoader
                            className="text-12 underline cursor-pointer"
                            color="secondary"
                            variant="outlined"
                            inProgress={isNotificationsLoading}
                            onClick={loadNotifications}
                        >
                            {formatMessage({
                                id: 'Voir plus de notifications',
                                defaultMessage: 'Voir plus de notifications',
                            })}
                        </ButtonLoader>
                    )}
                </FuseScrollbars>
            )}
        </StyledSwipeableDrawer>
    )
}
export default NotificationsPanel
