import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { ThemeProvider } from '@mui/material/styles'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import InstallerCustomersTab from 'src/modules/DetailsPages/InstallerDetails/InstallerCustomersTab/index'
import InstallerDetailsForm from 'src/modules/DetailsPages/InstallerDetails/InstallerRecordTab/InstallerDetailsForm'
import MultiTab from 'src/common/ui-kit/components/MultiTab/MultiTab'
import InstallerDetailsHeader from 'src/modules/DetailsPages/InstallerDetails/components/InstallerDetailsHeader'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import { useInstallerDetails } from 'src/modules/DetailsPages/InstallerDetails/installerDetailsHooks'
import { INSTALLER_API } from 'src/modules/MapPages/InstallerList/installerListHook'
import Comments from 'src/modules/DetailsPages/components/Comments/Comments'

/**
 * The InstallerDetails UI after clicking on a User Card Map, it contains Tabs (Fiche Installateur, ...etc).
 *
 * @returns InstallerDetails UI.
 */
// TODO This component should be refactored with client details one.
const InstallerDetails: FC = () => {
    /**
     * Hook useParams to get the params of the current route url. /installer/:id/:tab.
     */
    const { installerId } = useParams</**
     *
     */
    {
        // eslint-disable-next-line jsdoc/require-jsdoc
        installerId: string
    }>()

    const theme = selectTheme()
    const [isLoadedInstallerDetailsWhenMount, setIsLoadedInstallerDetailsWhenMount] = useState(false)
    const { installerDetails, handleLoadInstallerDetails, deleteInstaller } = useInstallerDetails(installerId)
    const [isEdit, setIsEdit] = React.useState(false)

    const API_ENDPOINT = `${INSTALLER_API}/${installerId}/comments`

    /**
     * Handler function to setIsEdit(true).
     */
    const editInstallerDetails = () => {
        setIsEdit(true)
    }
    /**
     * Handler function to setIsEdit(false).
     */
    const disableEditForm = () => {
        setIsEdit(false)
    }

    useEffect(() => {
        if (!isLoadedInstallerDetailsWhenMount) {
            handleLoadInstallerDetails()
            setIsLoadedInstallerDetailsWhenMount(true)
        }
    }, [handleLoadInstallerDetails, isLoadedInstallerDetailsWhenMount])

    const tabsContent = [
        {
            tabTitle: 'Fiche Installateur',
            tabSlug: 'installer-record',
            tabContent:
                installerDetails != null ? (
                    <div className="Tab-InstallerRecord">
                        <InstallerDetailsForm
                            isEdit={isEdit}
                            installerDetails={installerDetails}
                            loadInstallerDetails={handleLoadInstallerDetails}
                            disableEditForm={disableEditForm}
                            editInstallerDetails={editInstallerDetails}
                            deleteInstaller={deleteInstaller}
                        />
                        <Comments apiEndpoint={API_ENDPOINT} />
                    </div>
                ) : (
                    <div></div>
                ),
        },
        {
            tabTitle: 'Mes Utilisateurs',
            tabSlug: 'installer-customers',
            tabContent: installerDetails ? (
                <div className="Tab-InstallerCustomers">
                    <InstallerCustomersTab installerDetails={installerDetails} />
                </div>
            ) : (
                <div></div>
            ),
        },
    ]

    return (
        <ThemeProvider theme={theme}>
            {installerDetails === null ? (
                <FuseLoading />
            ) : (
                <MultiTab
                    header={<InstallerDetailsHeader isEdit={isEdit} installerDetails={installerDetails} />}
                    content={tabsContent}
                />
            )}
        </ThemeProvider>
    )
}

export default InstallerDetails
