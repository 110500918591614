import capitalize from '@mui/utils/capitalize'
import { statusType } from 'src/modules/InstallationsRequests/installationsRequestsHooks'
import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { formatMessageType } from 'src/common/react-platform-translation'
import {
    updateCustomerInputType,
    customerT,
    CUSTOMER_API,
    interestValuesType,
} from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'

/**
 * Information to be passed when adding a customer.
 */
//eslint-disable-next-line jsdoc/require-jsdoc
export type addCustomerInputType = updateCustomerInputType & {
    //eslint-disable-next-line jsdoc/require-jsdoc
    firstName: string

    //eslint-disable-next-line jsdoc/require-jsdoc
    lastName: string

    //eslint-disable-next-line jsdoc/require-jsdoc
    hasSensor: boolean
}

//eslint-disable-next-line jsdoc/require-jsdoc
export type userSpecificitiesT = {
    //eslint-disable-next-line jsdoc/require-jsdoc
    hasSensor: boolean
    //eslint-disable-next-line jsdoc/require-jsdoc
    interests: interestValuesType[]
}

/**
 * Type of the filters state for the customerHooks. The final type can be an object with all the properties {search: , status: ...etc} or it can be one of them separately, It's easier to put it this way to be able to export this type.
 */
export type customerFiltersT =
    // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Value of searchbar, when filtering customers with (firstname, lastname, email).
         */
        search?: string
        // eslint-disable-next-line jsdoc/require-jsdoc
        /**
         * Filter of customers are that interested by installation or supplier.
         */
        interests?: interestValuesType[]
        // eslint-disable-next-line jsdoc/require-jsdoc
        /**
         * Filter for customers that already have an installation. (mostly for NEW & PENDING) cases, it'll is considered as select so it'll be handled as an array.
         */
        status?: statusType[]
    }

// eslint-disable-next-line jsdoc/require-jsdoc
export type updateFiltersType = (newFilters: customerFiltersT) => void

// eslint-disable-next-line jsdoc/require-jsdoc
const addElementSuccess = (responseData: customerT, formatMessage: formatMessageType) => {
    const name = `${capitalize(responseData.firstName)} ${responseData.lastName.toUpperCase()}`
    return formatMessage(
        {
            id: `L'utilisateur {name} a été créé`,
            defaultMessage: `L'utilisateur {name} a été créé`,
        },
        { name },
    )
}

/**
 * Handle retured error from axios add client request, (Message handled From Custom IFG FormatError).
 *
 * @param error Axios error object.
 * @param formatMessage FormatMessage intl object from (react-intl package).
 * @returns {string} Error message.
 */
const addElementError = (error: any, formatMessage: formatMessageType) => {
    if (error.response.status === 409)
        return formatMessage({
            id: 'Cet email est déjà utilisé.',
            defaultMessage: 'Cet email est déjà utilisé.',
        })
    else
        return formatMessage({
            id: "Erreur lors de l'ajout du client",
            defaultMessage: "Erreur lors de l'ajout du client",
        })
}

// eslint-disable-next-line jsdoc/require-jsdoc
const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Erreur lors du chargement des clients',
        defaultMessage: 'Erreur lors du chargement des clients',
    })
}

/**
`* Hooks for customersList of Installers.
 *
 * @param sizeParam Indicates the default size when loadElement.
 * @returns UseCustomersList Hook.
 */
export const useCustomersList = (sizeParam?: number) =>
    BuilderUseElementList<customerT, addCustomerInputType, customerFiltersT>({
        API_ENDPOINT: CUSTOMER_API,
        sizeParam,
        snackBarMessage0verride: { addElementError, loadElementListError, addElementSuccess },
    })()
