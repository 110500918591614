import React, { FC } from 'react'
import { Card, Typography } from 'src/common/ui-kit'
import { useIntl } from 'src/common/react-platform-translation'
import { ForgotPasswordForm as DefaultForgotPasswordForm } from './ForgotPasswordForm'
import { Link } from 'react-router-dom'
import './ForgotPassword.scss'
import { LOGO_URL, API_BASE_URL } from 'src/configs'
import CardContent from '@mui/material/CardContent'
import { motion } from 'framer-motion'

/**
 * Props of the forget password component.
 */
export interface ForgotPasswordProps {
    /**
     * Logo of the company. This field is not required.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Destination link if the logo is clickable.
         */
        href?: string
        /**
         * Url of the logo.
         */
        url: string
    }
    /**
     * Forget password component. This is a rendered component.
     */
    forgotPasswordForm?: JSX.Element
}

/**
 * Forget password component.
 *
 * @param props N/A.
 * @param props.forgotPasswordForm Forget password form. This field has a default value.
 * @param props.logo Logo of the company. This field has a default value.
 * @returns Forget password component.
 */
export const ForgotPassword: FC<ForgotPasswordProps> = ({
    forgotPasswordForm = <DefaultForgotPasswordForm />,
    logo = { href: API_BASE_URL, url: LOGO_URL },
}): JSX.Element => {
    const { formatMessage } = useIntl()
    return (
        <div className="forgot-password-container">
            <div className="forgot-password-content">
                <motion.div initial={{ opacity: 0, scale: 0.6 }} animate={{ opacity: 1, scale: 1 }}>
                    <Card>
                        <CardContent className="flex flex-col items-center justify-center p-16 sm:p-24 md:p-32">
                            <img className="w-128 m-32" src="assets/images/logos/ned.svg" alt="logo" />
                            <Typography variant="h6" className="mt-16 mb-24 text-center font-semibold sm:text-20">
                                {formatMessage({
                                    id: 'Récupérez votre mot de passe',
                                    defaultMessage: 'Récupérez votre mot de passe',
                                })}
                            </Typography>
                            {forgotPasswordForm}

                            <div className="login-container">
                                <Link to={`/login`}>
                                    {formatMessage({
                                        id: 'Revenir à la connexion',
                                        defaultMessage: 'Revenir à la connexion',
                                    })}
                                </Link>
                            </div>
                        </CardContent>
                    </Card>
                </motion.div>
            </div>
        </div>
    )
}
