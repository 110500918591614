import { rest } from 'msw'
import { API_RESOURCES_URL } from 'src/configs'
import Chance from 'chance'
import { getPaginationFromElementList } from 'src/mocks/utils'
import {
    customerT,
    interestValuesType,
    updateCustomerInputType,
} from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { SnakeCasedPropertiesDeep } from 'type-fest'
import { addCustomerInputType } from 'src/modules/MapPages/CustomerList/customerListHook'

const chance = new Chance()

/**
 *
 */
export const URL_CUSTOMERS = `${API_RESOURCES_URL}/clients`

/**
 * Mock for customer data to be added.
 */
export const TEST_ADD_CUSTOMER = {
    email: 'test@test.com',
    phone: '+33 1 23 56 98 56',
    firstName: 'firstname',
    lastName: 'lastname',
    address: {
        name: 'street, @dz 213, 2134',
        addressAddition: '',
        zipCode: '',
        lat: 45.764,
        lng: 4.83,
        city: '',
        country: '',
    },
    hasSensor: false,
    interests: ['installation', 'supplier'],
    createdAt: '2021-12-15T14:07:38.138000',
}

// eslint-disable-next-line jsdoc/require-jsdoc
export const CREATED_AT_DATA = '2021-12-15T14:07:38.138000'

/**
 *
 */
export const TEST_FAIL_MAIL = 'error@error.com'

/**
 * Mock of customers/clients list data.
 */
export var TEST_CUSTOMERS: SnakeCasedPropertiesDeep<customerT>[] = [
    {
        id: 1,
        email: 'Sincere@april.biz',
        phone: '+33 1 23 45 67 89',
        first_name: 'Leanne',
        last_name: 'Graham',
        address: {
            name: 'Apt. 556, Gwenborough. 92998-3874',
            city: 'Gwenborough',
            zip_code: '92998-3874',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.706877,
            lng: 5.011265,
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
    },
    {
        id: 2,
        first_name: 'Ervin',
        last_name: 'Howell',
        email: 'Shanna@melissa.tv',
        created_at: CREATED_AT_DATA,
        address: {
            name: 'Suite 879, Wisokyburgh. 90566-7771',
            city: 'Wisokyburgh',
            zip_code: '90566-7771',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.7168,
            lng: 5.0465,
        },
        has_sensor: false,
        interests: ['supplier'],
        phone: '1106926593',
    },
    {
        id: 3,
        first_name: 'Clementine',
        last_name: 'Bauch',
        email: 'Nathan@yesenia.net',
        created_at: CREATED_AT_DATA,
        address: {
            name: 'Suite 847, McKenziehaven. 59590-4157',
            city: 'McKenziehaven',
            zip_code: '59590-4157',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.7405,
            lng: 5.9878,
        },
        has_sensor: false,
        interests: [],
        phone: '1463123447',
    },
    {
        id: 4,
        first_name: 'Patricia',
        last_name: 'Lebsack',
        email: 'Julianne.OConner@kory.org',
        created_at: CREATED_AT_DATA,
        address: {
            name: 'Apt. 692, South Elvis. 53919-4257',
            city: 'South Elvis',
            zip_code: '53919-4257',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.7673,
            lng: 5.0462,
        },
        has_sensor: false,
        interests: [],
        phone: '4931709623',
    },
    {
        id: 5,
        first_name: 'Chelsey',
        last_name: 'Dietrich',
        email: 'Lucio_Hettinger@annie.ca',
        created_at: CREATED_AT_DATA,
        address: {
            name: 'Suite 351, Roscoeview. 33263',
            city: 'Roscoeview',
            zip_code: '33263',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.9003,
            lng: 5.5117,
        },
        has_sensor: false,
        interests: [],
        phone: '2549541289',
    },
    {
        id: 6,
        first_name: 'Mrs. Dennis',
        last_name: 'Schulist',
        email: 'Karley_Dach@jasper.info',
        created_at: CREATED_AT_DATA,
        address: {
            name: '8424 Padberg Flats',
            city: 'South Christy',
            zip_code: '23505-1337',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.8214,
            lng: 5.2052,
        },
        has_sensor: false,
        interests: [],
        phone: '1477935478',
    },
    {
        id: 7,
        first_name: 'Kurtis',
        last_name: 'Weissnat',
        email: 'Telly.Hoeger@billy.biz',
        created_at: CREATED_AT_DATA,
        address: {
            name: '43299 Murazik Extension',
            city: 'Howemouth',
            zip_code: '58804-1099',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.8453,
            lng: 5.0512,
        },
        has_sensor: false,
        interests: [],
        phone: '2100676132',
    },
    {
        id: 8,
        first_name: 'Nicholas',
        last_name: 'Runolfsdottir V',
        email: 'Sherwood@rosamond.me',
        created_at: CREATED_AT_DATA,
        address: {
            name: '73311 Freida Point',
            city: 'Aliyaview',
            zip_code: '45169',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.4451,
            lng: 5.9184,
        },
        has_sensor: false,
        interests: ['supplier'],
        phone: '5864936943',
    },
    {
        id: 9,
        first_name: 'Glenna',
        last_name: 'Reichert',
        email: 'Chaim_McDermott@dana.io',
        created_at: CREATED_AT_DATA,
        address: {
            name: '303 Volkman Lakes',
            city: 'Bartholomebury',
            zip_code: '76495-3109',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.0543,
            lng: 5.393,
        },
        has_sensor: false,
        interests: ['supplier'],
        phone: '7759766794',
    },
    {
        id: 10,
        first_name: 'Clementina',
        last_name: 'DuBuque',
        email: 'Rey.Padberg@karina.biz',
        created_at: CREATED_AT_DATA,
        address: {
            name: '368 Fadel Isle',
            city: 'Lebsackbury',
            zip_code: '31428-2261',
            address_addition: 'testFDF',
            country: 'France',
            lat: 45.0992,
            lng: 5.9296,
        },
        has_sensor: false,
        interests: ['supplier'],
        phone: '1246483804',
    },
    {
        id: 84557,
        first_name: 'Unbranded Concrete',
        last_name: 'Towels',
        phone: '3247314990',
        email: 'fakez@fkae.use',
        address: {
            name: 'Apt5, Supra Kimga',
            zip_code: '93251',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Hahnhaven',
            lat: 45.0941,
            lng: 5.81,
        },
        has_sensor: false,
        interests: ['supplier'],
        created_at: CREATED_AT_DATA,
    },
    {
        first_name: 'Doris',
        last_name: 'Carney',
        phone: '6148576338',
        email: 'luctus@google.org',
        address: {
            name: '6254 Parturient Rd.',
            zip_code: '84355',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Lake Wilhelm',
            lat: 35.1804,
            lng: 5.7082,
        },
        has_sensor: false,
        interests: ['supplier'],
        created_at: CREATED_AT_DATA,
        id: 11,
    },
    {
        first_name: 'Fulton',
        last_name: 'Pratt',
        phone: '2565660127',
        email: 'donec.est.nunc@aol.couk',
        address: {
            name: '544-1163 Donec Ave',
            lat: 35.7899,
            lng: 5.2797,
            zip_code: '90145',
            address_addition: 'testFDF',
            country: 'France',
            city: 'South Rowan',
        },
        has_sensor: false,
        interests: ['supplier'],
        created_at: CREATED_AT_DATA,
        id: 12,
    },
    {
        first_name: 'Alvin',
        last_name: 'Church',
        phone: '1764334085',
        email: 'mus.proin@google.org',
        address: {
            name: '758-4763 Rutrum Av.',
            lat: 35.3597,
            lng: 5.6191,
            zip_code: '64830',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Port Ashleyland',
        },
        has_sensor: false,
        interests: ['supplier'],
        created_at: CREATED_AT_DATA,
        id: 13,
    },
    {
        first_name: 'Jerry',
        last_name: 'Oneil',
        phone: '1323509959',
        email: 'feugiat@icloud.edu',
        address: {
            name: 'P.O. Box 450, 5191 Dolor. Street',
            lat: 35.9146,
            lng: 5.6196,
            zip_code: '76935',
            address_addition: 'testFDF',
            country: 'France',
            city: 'West Rebafurt',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 14,
    },
    {
        first_name: 'Acton',
        last_name: 'Whitaker',
        phone: '1968743158',
        email: 'urna@google.net',
        address: {
            name: 'P.O. Box 908, 5927 At Av.',
            lat: 35.5449,
            lng: 5.5434,
            zip_code: '81798-5585',
            address_addition: 'testFDF',
            country: 'France',
            city: 'New Afton',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 15,
    },
    {
        first_name: 'Bertha',
        last_name: 'Summers',
        phone: '1621848120',
        email: 'eros.non@icloud.edu',
        address: {
            name: 'Ap #854-8349 Sagittis Avenue',
            lat: 35.1639,
            lng: 5.0937,
            zip_code: '82838-5412',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Wardside',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 16,
    },
    {
        first_name: 'Grady',
        last_name: 'Yates',
        phone: '1713437164',
        email: 'donec.porttitor@icloud.couk',
        address: {
            name: 'Ap #455-8734 In Rd.',
            lat: 35.0698,
            lng: 5.6757,
            zip_code: '57895-7706',
            address_addition: 'testFDF',
            country: 'France',
            city: 'South Clementinachester',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 17,
    },
    {
        first_name: 'Barrett',
        last_name: 'Valentine',
        phone: '1815598617',
        email: 'mus.proin@icloud.edu',
        address: {
            name: 'Ap #463-8098 Rutrum Rd.',
            lat: 35.3966,
            lng: 5.2797,
            zip_code: '58095',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Spinkastad',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 18,
    },
    {
        first_name: 'Rama',
        last_name: 'Cooke',
        phone: '1235496437',
        email: 'nunc.in@aol.net',
        address: {
            name: 'Ap #547-4916 Vivamus Ave',
            lat: 35.7225,
            lng: 5.3461,
            zip_code: '34377-5',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Zemlakview',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 19,
    },
    {
        first_name: 'Kirestin',
        last_name: 'Mejia',
        phone: '1547578986',
        email: 'morbi.neque.tellus@aol.edu',
        address: {
            name: 'Ap #923-7153 Suspendisse Rd.',
            lat: 35.3133,
            lng: 5.3354,
            zip_code: '23722-5772',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Autumnfort',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 20,
    },
    {
        first_name: 'Jason',
        last_name: 'Wright',
        phone: '1377746557',
        email: 'malesuada.fringilla.est@google.org',
        address: {
            name: '6817 Nullam Rd.',
            lat: 45.6716,
            lng: 5.0937,
            zip_code: '49722-7358',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Donnellyton',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 21,
    },
    {
        first_name: 'Gil',
        last_name: 'Watts',
        phone: '4738445181',
        email: 'consectetuer@protonmail.ca',
        address: {
            name: '4778 Risus. St.',
            lat: 45.0595,
            lng: 5.5341,
            zip_code: '05377-1615',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Lilianebury',
        },
        has_sensor: false,
        interests: ['installation'],
        created_at: CREATED_AT_DATA,
        id: 22,
    },
    {
        first_name: 'Hector',
        last_name: 'Zimmerman',
        phone: '1657446788',
        email: 'amet.luctus.vulputate@protonmail.edu',
        address: {
            name: 'P.O. Box 899, 7944 Mi. St.',
            lat: 45.3916,
            lng: 5.0292,
            zip_code: '90342-4263',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Eldredchester',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 23,
    },
    {
        first_name: 'Christopher',
        last_name: 'Hahn',
        phone: '2312735563',
        email: 'ligula.consectetuer@protonmail.org',
        address: {
            name: 'P.O. Box 120, 5376 Sed Street',
            lat: 45.2268,
            lng: 5.4359,
            zip_code: '53022',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Asiashire',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 24,
    },
    {
        first_name: 'Hoyt',
        last_name: 'Keith',
        phone: '1248878800',
        email: 'dictum.ultricies.ligula@outlook.edu',
        address: {
            name: '674-5820 A Av.',
            lat: 45.3144,
            lng: 5.1627,
            zip_code: '52085-2972',
            address_addition: 'testFDF',
            country: 'France',
            city: 'South Dejamouth',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 25,
    },
    {
        first_name: 'Anthony',
        last_name: 'Tanner',
        phone: '4722172206',
        email: 'eget.metus@aol.net',
        address: {
            name: '379-4229 Nunc. St.',
            lat: 45.4277,
            lng: 5.4256,
            zip_code: '37725',
            address_addition: 'testFDF',
            country: 'France',
            city: 'New Mervin',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 26,
    },
    {
        first_name: 'Hedda',
        last_name: 'Sharpe',
        phone: '1263454637',
        email: 'vulputate.ullamcorper@google.edu',
        address: {
            name: '413-3007 Leo Avenue',
            lat: 45.6201,
            lng: 5.272,
            zip_code: '34707-9449',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Christatown',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 27,
    },
    {
        first_name: 'Driscoll',
        last_name: 'Wise',
        phone: '1771987019',
        email: 'ante.blandit@aol.couk',
        address: {
            name: '3915 Ultrices Avenue',
            lat: 45.3395,
            lng: 5.9199,
            zip_code: '60974-8729',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Smithchester',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 28,
    },
    {
        first_name: 'Imani',
        last_name: 'King',
        phone: '8477455313',
        email: 'egestas.duis.ac@protonmail.net',
        address: {
            name: 'P.O. Box 132, 136 Tellus Street',
            lat: 45.7534,
            lng: 5.8205,
            zip_code: '76861-4936',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Effertzfurt',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 29,
    },
    {
        first_name: 'Chancellor',
        last_name: 'Blackwell',
        phone: '5313243505',
        email: 'metus.facilisis.lorem@google.ca',
        address: {
            name: '296-8911 Fusce Street',
            lat: 45.011,
            lng: 5.9528,
            zip_code: '45834-5086',
            address_addition: 'testFDF',
            country: 'France',
            city: 'Wildermanfurt',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 30,
    },
    {
        first_name: 'Candace',
        last_name: 'Kim',
        phone: '6384686347',
        email: 'nulla@outlook.com',
        address: {
            name: '302-2546 Nunc St.',
            lat: 45.1047,
            lng: 5.5795,
            zip_code: '84657',
            address_addition: 'testFDF',
            country: 'France',
            city: 'East Bettye',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 31,
    },
    {
        first_name: 'Boris',
        last_name: 'Prince',
        phone: '2417644236',
        email: 'nulla.tempor@protonmail.couk',
        address: {
            name: '419 Magna. Av.',
            lat: 45.5514,
            lng: 5.3927,
            zip_code: '842259',
            address_addition: 'testFDF',
            country: 'France',
            city: 'New Damian',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 32,
    },
    {
        first_name: 'Arden',
        last_name: 'Strickland',
        phone: '5527321692',
        email: 'magna.malesuada.vel@outlook.org',
        address: {
            name: 'Ap #869-1419 Ante St.',
            lat: 45.5151,
            lng: 5.9086,
            zip_code: null,
            address_addition: 'testFDF',
            country: 'France',
            city: 'North Krisville',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 33,
    },
    {
        first_name: 'Winter',
        last_name: 'Jacobs',
        phone: '6717874921',
        email: 'congue.a@google.com',
        address: {
            name: '879-258 Ornare Rd.',
            lat: 45.1603,
            lng: 5.3153,
            zip_code: '50304-4290',
            address_addition: 'testFDF',
            country: 'France',
            city: 'VonRuedenhaven',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 34,
    },
    {
        first_name: 'Michelle',
        last_name: 'Hardy',
        phone: '3528602853',
        email: 'lacus.cras.interdum@google.couk',
        address: {
            name: '627-7286 Amet Ave',
            lat: 45.8995,
            lng: 5.7106,
            zip_code: '63059-0222',
            address_addition: 'testFDF',
            country: 'France',
            city: 'East Oran',
        },
        has_sensor: false,
        interests: ['installation', 'supplier'],
        created_at: CREATED_AT_DATA,
        id: 35,
    },
]

const customersREST = [
    // Get All customers
    rest.get(URL_CUSTOMERS, (req, res, ctx) => {
        const installerClientsId = req.url.searchParams.get('installer_clients_id')

        const TEST_CUSTOMERS_RESPONSE = getPaginationFromElementList<SnakeCasedPropertiesDeep<customerT>>(
            req,
            TEST_CUSTOMERS as [],
            ['first_name', 'last_name', 'email'],
        )
        if (installerClientsId === '') {
            return res(ctx.status(404), ctx.delay(1000), ctx.json('error'))
        }

        return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_CUSTOMERS_RESPONSE))
    }),

    // Get selected customer by id.
    rest.get(`${URL_CUSTOMERS}/:id`, (req, res, ctx) => {
        const { id } = req.params
        if (parseInt(id)) {
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_CUSTOMERS[0]))
        } else {
            return res(ctx.status(404), ctx.delay(1000), ctx.json({ message: 'Customer not to be found!' }))
        }
    }),

    // Add Customer Post request
    rest.post<SnakeCasedPropertiesDeep<addCustomerInputType>>(URL_CUSTOMERS, (req, res, ctx) => {
        if (req.body.email !== TEST_FAIL_MAIL) {
            const lengthBefore = TEST_CUSTOMERS.length
            const newCustomer = {
                ...req.body,
                id: lengthBefore + 1,
                address: {
                    name: req.body.address.name,
                    city: 'Kazeliek',
                    zip_code: '12134-3874',
                    address_addition: 'testFDF',
                    country: 'France',
                    // Using Math.random is detected as security hotspot by sonarqube, because it has a weak cryptography.
                    lat: chance.floating({ fixed: 3, min: 44, max: 46 }),
                    lng: chance.floating({ fixed: 3, min: 4, max: 5 }),
                },
                has_sensor: true,
                interests: ['installation', 'supplier'] as interestValuesType[],
                created_at: CREATED_AT_DATA,
            }
            TEST_CUSTOMERS.unshift(newCustomer)
            return res(ctx.status(200), ctx.delay(1000), ctx.json(newCustomer))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),

    // Update Customer Post request
    rest.put<SnakeCasedPropertiesDeep<updateCustomerInputType>>(`${URL_CUSTOMERS}/:id`, (req, res, ctx) => {
        const { id } = req.params

        if (parseInt(id) && req.body.email !== 'error') {
            let indexOfCustomer = TEST_CUSTOMERS.findIndex((c) => c.id === parseInt(id))
            let newCustomer = {
                ...TEST_CUSTOMERS[indexOfCustomer],
                ...req.body,
                created_at: CREATED_AT_DATA,
            }
            TEST_CUSTOMERS[indexOfCustomer] = newCustomer
            return res(ctx.status(200), ctx.delay(1000), ctx.json(newCustomer))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),

    // Remove Customer request
    rest.delete(`${URL_CUSTOMERS}/:id`, (req, res, ctx) => {
        const { id } = req.params
        if (parseInt(id)) {
            TEST_CUSTOMERS = TEST_CUSTOMERS.filter((c) => c.id !== parseInt(id))
            return res(ctx.status(200), ctx.delay(1000))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),
]

export default customersREST
