import React, { useState, useContext } from 'react'
import Typography from '@mui/material/Typography'
import { useIntl } from 'src/common/react-platform-translation'
import CommentsTable from 'src/modules/DetailsPages/components/Comments/CommentsTable'
import CommentsForm from 'src/modules/DetailsPages/components/Comments/CommentsForm'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Icon from '@mui/material/Icon'
import { useCommentsList } from 'src/modules/DetailsPages/components/Comments/CommentsHooks'
import { elementCommentType } from 'src/modules/DetailsPages/components/Comments/CommentsType'
import Button from '@mui/material/Button'
import { CustomerDetailsContext } from 'src/modules/DetailsPages/CustomerDetails/index'

/**
 * Element Comments components Rendering the Comment Section of Fiche Client.
 *
 * @param props N/A.
 * @param props.apiEndpoint The base path of the apiEndpoint URL.
 * @returns Comments Section.
 */
const Comments = ({
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint,
}: /**
 *
 */
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint: string
}) => {
    const { formatMessage } = useIntl()
    const [commentDetails, setCommentDetails] = useState<elementCommentType | null>(null)
    const [openCommentPopup, setOpenCommentPopup] = useState(false)

    const {
        elementList: commentsList,
        reloadElements: handleReloadComments,
        totalElementList: totalCommentsList,
        loadingInProgress,
        loadPage,
        page,
    } = useCommentsList(apiEndpoint)

    /**
     * Handler for closing comment popup.
     */
    const closeOpenCommentPopup = () => {
        setOpenCommentPopup(false)
        setCommentDetails(null)
    }

    /**
     * EditTable handler for opening the edit comment popup.
     *
     * @param comment Comment to be edited.
     */
    const onClickEditComment = async (comment: elementCommentType) => {
        setCommentDetails(comment)
        setOpenCommentPopup(true)
    }

    /**
     * Consume the CustomerDetailsContext.
     */
    const { readOnly } = useContext(CustomerDetailsContext)

    return (
        <>
            {/* Popup for Adding or Editing Comment */}
            <Dialog
                open={openCommentPopup}
                onClose={closeOpenCommentPopup}
                disableEnforceFocus
                maxWidth="md"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle className="text-18 mb-4" id="alert-dialog-title">
                    {!commentDetails
                        ? formatMessage({
                              id: 'Ajout du Commentaire',
                              defaultMessage: 'Ajout du Commentaire',
                          })
                        : formatMessage({
                              id: 'Modification du Commentaire',
                              defaultMessage: 'Modification du Commentaire',
                          })}
                </DialogTitle>

                <CommentsForm
                    closeOpenCommentPopup={closeOpenCommentPopup}
                    apiEndpoint={apiEndpoint}
                    commentDetails={commentDetails}
                    loadComments={handleReloadComments}
                />
            </Dialog>

            <div>
                <div className="flex my-20 flex-row items-center justify-between">
                    <div className="flex mb-10 sm:mb-0">
                        <Icon className="hidden sm:inline text-20 sm:text-32 mr-8">chat_bubble_outline</Icon>
                        <Typography className="text-12 sm:text-18 font-semibold">
                            {formatMessage({ id: 'Commentaires', defaultMessage: 'Commentaires' })}
                        </Typography>
                    </div>
                    <div>
                        {!readOnly && (
                            <Button variant="contained" onClick={() => setOpenCommentPopup(true)}>
                                {formatMessage({ id: 'Ajouter Commentaire', defaultMessage: 'Ajouter Commentaire' })}
                            </Button>
                        )}
                    </div>
                </div>
                <CommentsTable
                    apiEndpoint={apiEndpoint}
                    loadComments={handleReloadComments}
                    onClickEditComment={onClickEditComment}
                    rows={commentsList}
                    page={page}
                    loadPage={loadPage}
                    totalComments={totalCommentsList}
                    loadingInProgress={loadingInProgress}
                />
            </div>
        </>
    )
}

export default Comments
