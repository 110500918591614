import Hidden from '@mui/material/Hidden'

/**
 * Component displays the icon.
 *
 * @returns Returns icon.
 */
const ToolbarIcon = () => {
    return (
        <Hidden lgUp>
            <img className="ml-8 py-8" src={`./assets/images/logos/ned.svg`} alt="logo" width="45" />
        </Hidden>
    )
}

export default ToolbarIcon
