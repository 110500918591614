import { authTypes } from 'src/common/react-platform-components'
import LeadExchanges from 'src/modules/LeadExchanges'
import { IRouteNavigationConfig } from 'src/routes'

const URL_LEAD_EXCHANGES = '/lead-exchanges'
/**
 * Interface .
 *
 */
export interface LeadExchangesProps {
    /**
     * Logo to dislay.
     */
    logo?: // eslint-disable-next-line jsdoc/require-jsdoc
    {
        /**
         * Url of the logo.
         */
        url: string
    }
}
const LeadExchangesConfig = [
    {
        path: URL_LEAD_EXCHANGES,
        component: LeadExchanges,
        auth: { authType: authTypes.freeAccess },
        settings: {
            layout: {
                navbar: {
                    UINavbarItem: {
                        id: 'lead_exchanges',
                        label: 'Bourses aux leads',
                        labelAbbreviation: 'Leads',
                        type: 'item',
                        iconLabel: 'show_chart',
                        url: URL_LEAD_EXCHANGES,
                    },
                },
            },
        },
    } as IRouteNavigationConfig<LeadExchangesProps>,
]

export { LeadExchangesConfig, URL_LEAD_EXCHANGES }
