import { Button, Icon } from '@mui/material'
import { motion } from 'framer-motion'
import { useIntl } from 'react-intl'
import { EditButtonProps } from 'src/modules/utils/EditButton/props.d'

/**
 * EditButton component.
 *
 * @param param0 N/A.
 * @param param0.onClick Onclick function.
 * @param param0.isEdit Edit state.
 * @returns Edit Button component JSX.
 */
export default function EditButton({ onClick, isEdit }: EditButtonProps) {
    const { formatMessage } = useIntl()

    return (
        <motion.div
            initial={{ y: -20, opacity: 0 }}
            animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
            className="px-0 sm:px-12"
        >
            <Button
                variant="contained"
                color="secondary"
                onClick={onClick}
                sx={
                    isEdit
                        ? {
                              pointerEvents: 'none',
                              backgroundColor: 'text.disabled',
                          }
                        : {}
                }
            >
                <Icon component={motion.span} initial={{ scale: 0 }} animate={{ scale: 1, transition: { delay: 0.2 } }}>
                    create
                </Icon>
                <span className="hidden sm:inline">
                    {formatMessage({ id: 'Modifier', defaultMessage: 'Modifier' })}
                </span>
            </Button>
        </motion.div>
    )
}
