import React from 'react'
import Icon from '@mui/material/Icon'
import Input from '@mui/material/Input'
import Paper from '@mui/material/Paper'
import { ThemeProvider } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { motion } from 'framer-motion'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import { useIntl } from 'src/common/react-platform-translation'

/**
 * Header component.
 *
 * @returns InstallationsRequestsHeader component.
 */
const InstallationsRequestsHeader: React.FC = () => {
    const mainTheme = selectTheme()
    const { formatMessage } = useIntl()

    return (
        <div className="flex flex-1 w-full items-center justify-between">
            <div className="flex items-center">
                <Icon
                    component={motion.span}
                    initial={{ scale: 0 }}
                    animate={{ scale: 1, transition: { delay: 0.2 } }}
                    className="text-24 md:text-32"
                >
                    list_alt
                </Icon>
                <Typography
                    component={motion.span}
                    initial={{ x: -20 }}
                    animate={{ x: 0, transition: { delay: 0.2 } }}
                    className="text-16 md:text-24 mx-12 font-semibold"
                >
                    <span className="hidden sm:flex">
                        {formatMessage({
                            id: "Demandes d'installations",
                            defaultMessage: "Demandes d'installations",
                        })}
                    </span>
                    <span className="flex sm:hidden">
                        {formatMessage({
                            id: 'Demandes',
                            defaultMessage: 'Demandes',
                        })}
                    </span>
                </Typography>
            </div>

            <div className="flex flex-1 items-center justify-center px-12">
                <ThemeProvider theme={mainTheme}>
                    <Paper
                        component={motion.div}
                        initial={{ y: -20, opacity: 0 }}
                        animate={{ y: 0, opacity: 1, transition: { delay: 0.2 } }}
                        className="flex items-center w-full max-w-512 px-8 py-4 rounded-16 shadow"
                    >
                        <Icon color="action">search</Icon>

                        <Input
                            placeholder="Rechercher"
                            className="flex flex-1 mx-8"
                            disableUnderline
                            fullWidth
                            value={null}
                            inputProps={{
                                'aria-label': 'Search',
                            }}
                            onChange={() => {}}
                        />
                    </Paper>
                </ThemeProvider>
            </div>
        </div>
    )
}

export default InstallationsRequestsHeader
