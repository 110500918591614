import React, { useState } from 'react'
import { GoogleMapsAddressAutoCompleteField } from 'src/common/ui-kit/form-fields/GoogleMapsAddressAutoComplete/GoogleMapsAddressAutoCompleteField'
import { ButtonLoader, TextField } from 'src/common/ui-kit'
import { Form, requiredBuilder, email, regex } from 'src/common/react-platform-components'
import { PhoneNumber } from 'src/common/ui-kit/form-fields/phoneNumber/PhoneNumber'
import { useIntl } from 'src/common/react-platform-translation'
import { useModifyProfile } from './ModifyProfileHooks'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux'
import { Button } from '@mui/material'
import { ButtonResetForm } from 'src/common/ui-kit/components/ButtonResetForm/ButtonResetForm'
import { IUser } from 'src/modules/User'
import { sirenFieldRegex } from 'src/modules/User/Register/utils'
import { useAuth } from 'src/modules/User/authentication/useAuth'
/**
 * Form used for modify user profile.
 *
 * @returns Modify form component.
 */
export const ModifyProfileForm = () => {
    const { user } = useSelector(({ userModel }: RootState) => userModel)
    const { hasRole } = useAuth()
    const { isModifyInProgress, onSubmit } = useModifyProfile()
    const { formatMessage } = useIntl()
    const [isEdit, setIsEdit] = useState(false)
    const disabledField = !isEdit

    const formInitialValues = {
        firstName: user?.firstName,
        lastName: user?.lastName,
        companyName: user?.companyName,
        siren: user?.siren,
        email: user?.email,
        phone: user?.phone,
        address: user?.address,
    } as const

    /**
     * Handler function to setIsEdit.
     */
    const toggleEditFormDisable = () => {
        setIsEdit((prevIsEdit) => !prevIsEdit)
    }

    return (
        <Form
            defaultValues={formInitialValues}
            onSubmit={async (data: IUser) => {
                await onSubmit(data)
                setIsEdit(false)
            }}
        >
            <div className="flex flex-col justify-center p-16 sm:p-24 md:p-32 ">
                <TextField
                    name="firstName"
                    label="Prénom"
                    validateFunctions={[requiredBuilder()]}
                    disabled={disabledField}
                />
                <TextField
                    name="lastName"
                    label="Nom"
                    validateFunctions={[requiredBuilder()]}
                    disabled={disabledField}
                />
                {hasRole(['installer']) && (
                    <>
                        <TextField
                            name="companyName"
                            label="Raison sociale"
                            validateFunctions={[requiredBuilder()]}
                            variant="outlined"
                            style={{ marginBottom: '20px' }}
                            disabled={disabledField}
                        />
                        <TextField
                            name="siren"
                            label="Siren"
                            validateFunctions={[
                                requiredBuilder(),
                                regex(sirenFieldRegex, 'Le numéro Siren doit être composé de 9 chiffres'),
                            ]}
                            variant="outlined"
                            style={{ marginBottom: '20px' }}
                            disabled={disabledField}
                        />
                    </>
                )}
                <TextField
                    name="email"
                    label="Email"
                    validateFunctions={[requiredBuilder(), email()]}
                    disabled={disabledField}
                />
                <PhoneNumber
                    name="phone"
                    label="Numéro de téléphone"
                    type="tel"
                    sx={{ margin: '0 0 1.25rem 0' }}
                    validateFunctions={[requiredBuilder()]}
                    disabled={disabledField}
                />
                <GoogleMapsAddressAutoCompleteField
                    name="address"
                    validateFunctions={[requiredBuilder()]}
                    disabled={disabledField}
                />
                <div>
                    {isEdit ? (
                        <div>
                            <ButtonResetForm
                                initialValues={formInitialValues}
                                onClickButtonReset={toggleEditFormDisable}
                            />
                            <ButtonLoader
                                inProgress={isModifyInProgress}
                                variant="contained"
                                type="submit"
                                className="ml-8 mb-4 sm:mr-8 sm:mb-0"
                            >
                                {formatMessage({ id: 'Enregistrer', defaultMessage: 'Enregistrer' })}
                            </ButtonLoader>
                        </div>
                    ) : (
                        <Button variant={'contained'} className={'w-224 mx-auto mb-16'} onClick={toggleEditFormDisable}>
                            {formatMessage({ id: 'Modifier', defaultMessage: 'Modifier' })}
                        </Button>
                    )}
                </div>
            </div>
        </Form>
    )
}
