import { useToggle } from 'react-use'
import { axios, catchError } from 'src/common/react-platform-components'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { useIntl } from 'src/common/react-platform-translation'
import { formatMessageType } from 'src/common/react-platform-translation'
import { addCommentBodyType, elementCommentType } from 'src/modules/DetailsPages/components/Comments/CommentsType'
import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { searchFilterType } from 'src/modules/utils'

// eslint-disable-next-line jsdoc/require-jsdoc
export const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Erreur lors du chargement des commentaires',
        defaultMessage: 'Erreur lors du chargement des commentaires',
    })
}

// eslint-disable-next-line jsdoc/require-jsdoc
export const addElementSuccess = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Le commentaire a été ajouté',
        defaultMessage: 'Le commentaire a été ajouté',
    })
}

// eslint-disable-next-line jsdoc/require-jsdoc
export const addElementError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: "Erreur lors de l'ajout du commentaire",
        defaultMessage: "Erreur lors de l'ajout du commentaire",
    })
}

/**
     `* Hooks for InstallerRequests for registration requests of installer.
     *
     * @param apiEndpoint API ENDPOINT.
     * @param sizeParam Indicates the default sizeParam for loadElementList.
     * @returns UseInstallerRequestList Hook.
     */
export const useCommentsList = (apiEndpoint: string, sizeParam?: number) =>
    // eslint-disable-next-line jsdoc/require-jsdoc
    BuilderUseElementList<elementCommentType, addCommentBodyType, searchFilterType>({
        API_ENDPOINT: apiEndpoint,
        sizeParam: sizeParam,
        snackBarMessage0verride: { loadElementListError, addElementSuccess, addElementError },
    })(true, { is_verified: false })

/**
`* Hook for Comments.
 *
 * @param apiEndpoint The base path of the apiEndpoint URL.
 * @returns UseComments hook.
 */
export const useCommentsDetails = (apiEndpoint: string) => {
    const [loadingRequest, setLoadingRequest] = useToggle(false)
    const { enqueueSnackbar } = useSnackbar()
    const { formatMessage } = useIntl()

    /**
     * Remove Element Comment Handler.
     *
     * @param commentId Comment Id of the comment.
     * @returns The function returns a string message containing successful and errors message.
     */
    const removeComment = async (commentId: number) => {
        setLoadingRequest(true)
        try {
            await axios.delete<elementCommentType>(`${apiEndpoint}/${commentId}`)
            enqueueSnackbar(
                formatMessage({
                    id: 'Le commentaire a été supprimé',
                    defaultMessage: 'Le commentaire a été supprimé',
                }),
                { variant: 'success' },
            )
            setLoadingRequest(false)
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur lors de la Suppression du commentaire',
                    defaultMessage: 'Erreur lors de la Suppression du commentaire',
                }),
                { variant: 'error' },
            )
            setLoadingRequest(false)
            throw catchError(error)
        }
    }

    /**
     * Update the Element Comment.
     *
     * @param comment The updated Comment.
     * @returns The function returns a string message containing successful and errors message.
     */
    const updateComment = async (comment: elementCommentType) => {
        setLoadingRequest(true)
        const requestBody = {
            message: comment.message,
        }
        try {
            await axios.put<typeof requestBody, AxiosResponse<elementCommentType>>(
                `${apiEndpoint}/${comment.id}`,
                requestBody,
            )
            enqueueSnackbar(
                formatMessage({
                    id: 'Le commentaire a été modifié',
                    defaultMessage: 'Le commentaire a été modifié',
                }),
                { variant: 'success' },
            )
            setLoadingRequest(false)
        } catch (error) {
            enqueueSnackbar(
                formatMessage({
                    id: 'Erreur lors de la modification du commentaire',
                    defaultMessage: 'Erreur lors de la modification du commentaire',
                }),
                { variant: 'error' },
            )
            setLoadingRequest(false)
            throw catchError(error)
        }
    }

    return {
        loadingRequest,
        removeComment,
        updateComment,
    }
}
