import React from 'react'
import MapElementList from 'src/common/ui-kit/components/MapElementList'
import { useInstallerCustomers } from 'src/modules/DetailsPages/InstallerDetails/InstallerCustomersTab/installerCustomersTabHook'
import { customerT } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { filters } from 'src/modules/MapPages/CustomerList'
import CustomerCard from 'src/modules/MapPages/CustomerList/components/CustomerCard/CustomerCard'
import { IUser } from 'src/modules/User'
import { customerFiltersT } from 'src/modules/MapPages/CustomerList/customerListHook'
import { GOOGLE_MAPS_API_KEY } from 'src/configs'

/**
 * InstallerCustomersTabProps.
 */
//eslint-disable-next-line jsdoc/require-jsdoc
type InstallerCustomersTabProps = {
    //eslint-disable-next-line jsdoc/require-jsdoc
    installerDetails: IUser
}

/**
 * InstallerCustomers component to display clients relevant to an installateur.
 *
 * @param props N/A.
 * @param props.installerDetails Installer ID to be passed via the parent component.
 * @returns InstallerCustomers.
 */
export default function InstallerCustomersTab({ installerDetails }: InstallerCustomersTabProps) {
    const {
        elementList: installerCustomersList,
        loadingInProgress: loadingInstallerCustomers,
        noMoreElementToLoad: noMoreInstallerCustomers,
        loadMoreElements: loadMoreInstallerCustomers,
        updateFilters,
    } = useInstallerCustomers(installerDetails.id)

    // eslint-disable-next-line jsdoc/require-jsdoc
    const CustomerInstallerCard = ({ element }: { element: customerT }) => (
        <CustomerCard
            element={element}
            returnLabel={`Revenir à ${installerDetails.companyName}`}
            returnURL={`/installers/${installerDetails.id}/installer-customers`}
            customerDetailsContextValue={{ readOnly: true }}
        />
    )

    return (
        //eslint-disable-next-line jsdoc/require-jsdoc
        <MapElementList<customerT, customerFiltersT>
            data={installerCustomersList}
            loadingData={loadingInstallerCustomers}
            googleMapsApiKey={GOOGLE_MAPS_API_KEY}
            latLngElementAddressOverride={(element: customerT) => ({
                lat: element.address.lat,
                lng: element.address.lng,
            })}
            loadMoreData={loadMoreInstallerCustomers}
            elementCard={CustomerInstallerCard}
            noMoreDataToLoad={noMoreInstallerCustomers}
            filters={filters}
            onConfirmFilter={updateFilters}
            toolbarSearch
        />
    )
}
