import React from 'react'
import { useParams } from 'react-router-dom'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import EquipmentsTable from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/equipmentsTable/EquipmentsTable'
import { useMetersList } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/metersHooks'
import MetersTable from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/MetersTable'
import { ChartErrorMessage } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ChartErrorMessage'
import { ChartsContainer } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ChartsContainers'
import TypographyFormatMessage from 'src/common/ui-kit/components/TypographyFormatMessage/TypographyFormatMessage'
import { useTheme } from '@mui/material'
import { GRAPH_CONSUMPTION_TITLE, GRAPH_PRODUCTION_TITLE } from './charts/utils/ChartsVariables'

/**
 * Installation component to display client Installation.
 *
 * @returns Installation component.
 */
const Installation = () => {
    /**
     * Hook useParams to get the params of the current route url. /customers/:id/:tab.
     */
    const { customerId: customerIdParam } = useParams</**
     *
     */
    {
        /**
         * Customer id.
         */
        customerId: string
    }>()
    const theme = useTheme()
    const customerId = parseInt(customerIdParam)
    const {
        elementList: metersList,
        totalElementList: totalMeterList,
        loadingInProgress: isMetersListInProgress,
        loadPage,
        page,
    } = useMetersList(customerId, 5)

    /**
     * ChartTitle function shows graph title.
     *
     * @param title Chart title.
     * @returns Chart title.
     */
    const chartTitle = (title: string) => {
        return (
            <TypographyFormatMessage
                variant="body1"
                className="sm:mr-8 mb-10"
                style={{ color: theme.palette.primary.contrastText }}
            >
                {title}
            </TypographyFormatMessage>
        )
    }
    return (
        <div className="InstallationContainer">
            {isMetersListInProgress ? (
                <FuseLoading />
            ) : metersList?.length ? (
                <ChartsContainer metersList={metersList} />
            ) : (
                <>
                    <div style={{ background: theme.palette.primary.main }} className="p-16">
                        {chartTitle(GRAPH_CONSUMPTION_TITLE)}
                        <ChartErrorMessage nrLinkEnedisOff />
                    </div>
                    <div style={{ background: theme.palette.primary.main }} className="p-16 mt-16">
                        {chartTitle(GRAPH_PRODUCTION_TITLE)}
                        <ChartErrorMessage nrLinkEnedisOff enphaseOff />
                    </div>
                </>
            )}
            <EquipmentsTable />
            <MetersTable
                metersList={metersList}
                totalMeterList={totalMeterList}
                isMetersListInProgress={isMetersListInProgress}
                loadPage={loadPage}
                page={page}
            />
        </div>
    )
}

export default Installation
