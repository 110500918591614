import React, { useContext } from 'react'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import IconButton from '@mui/material/IconButton'
import { useIntl } from 'src/common/react-platform-translation'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContentText from '@mui/material/DialogContentText'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Tooltip from '@mui/material/Tooltip'
import DeleteIcon from '@mui/icons-material/Delete'
import CreateIcon from '@mui/icons-material/Create'
import { useCommentsDetails } from 'src/modules/DetailsPages/components/Comments/CommentsHooks'
import { elementCommentType } from 'src/modules/DetailsPages/components/Comments/CommentsType'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import { ButtonLoader } from 'src/common/ui-kit'
import Table from 'src/common/ui-kit/components/Table/Table'
import EmptyTableMessage from 'src/common/ui-kit/components/Table/EmptyTableMessage'
import { CustomerDetailsContext } from 'src/modules/DetailsPages/CustomerDetails/index'
import { dayjsUTC } from 'src/common/react-platform-components/utils/mm'

dayjs.extend(utc)

/**
 * Comments Table Component.
 *
 * @param props N/A.
 * @param props.apiEndpoint The base path of the apiEndpoint URL (exemple: /users/:id/comments).
 * @param props.loadComments Handler to be called when adding or updating comment infos, so that we load the commentDetails again.
 * @param props.onClickEditComment Responsible for when editing or adding a comment.
 * @param props.rows Comments Rows of the elementDetails.
 * @param props.loadPage  LoadPage function used by TablePagination to load elements at the given page.
 * @param props.page Elements to be loaded from the given page.
 * @param props.totalComments TotalElementList represent the total size of all data that can be return from backend, the filters query will affect the total possible returned.
 * @param props.loadingInProgress Loading In progress of the UseElementBuilder.
 * @returns Comments Table Component.
 */
export default function CommentsTable({
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint,
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadComments,
    // eslint-disable-next-line jsdoc/require-jsdoc
    onClickEditComment,
    // eslint-disable-next-line jsdoc/require-jsdoc
    rows,
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadPage,
    // eslint-disable-next-line jsdoc/require-jsdoc
    page,
    // eslint-disable-next-line jsdoc/require-jsdoc
    totalComments,
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadingInProgress,
}: /**
 *
 */
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadComments: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    onClickEditComment: (comment: elementCommentType) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    rows: elementCommentType[] | null
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadPage: (page: number) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    page: number
    // eslint-disable-next-line jsdoc/require-jsdoc
    totalComments: number
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadingInProgress: boolean
}) {
    const [isWarningRemoveCommentDialogComment, setIsWarningRemoveCommentDialogComment] = React.useState<number | null>(
        null,
    )
    const { formatMessage } = useIntl()

    /**
     * Consume the CustomerDetailsContext.
     */
    const { readOnly } = useContext(CustomerDetailsContext)

    const commentsTableCells = [
        {
            id: 'createdAt',
            headCellLabel: 'Date',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: elementCommentType) => dayjsUTC(row.createdAt).format('DD/MM/YYYY'),
        },
        {
            id: 'message',
            headCellLabel: 'Commentaire',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: elementCommentType) => row.message,
        },
        {
            id: 'actions',
            headCellLabel: '',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: elementCommentType) => (
                <>
                    {!readOnly && (
                        <>
                            <Tooltip title="Modifier">
                                <IconButton color="primary" onClick={() => onClickEditComment(row)} aria-label="Edit">
                                    <CreateIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Supprimer">
                                <IconButton
                                    color="error"
                                    aria-label="Delete"
                                    onClick={(e: React.SyntheticEvent) => {
                                        e.stopPropagation()
                                        setIsWarningRemoveCommentDialogComment(row.id)
                                    }}
                                >
                                    <DeleteIcon />
                                </IconButton>
                            </Tooltip>
                        </>
                    )}
                </>
            ),
        },
    ]
    /**
     * Handler for closing openDeleteWarningPopup state.
     */
    const closeWarningRemoveCommentDialog = () => {
        setIsWarningRemoveCommentDialogComment(null)
    }

    if (loadingInProgress || rows === null) {
        return <FuseLoading />
    }

    if (rows?.length === 0) {
        return (
            <EmptyTableMessage
                message={formatMessage({
                    id: "Il n'y a pas de commentaires.",
                    defaultMessage: "Il n'y a pas de commentaires.",
                })}
            />
        )
    }

    return (
        <div className="w-full flex flex-col rounded-16 border-2 shadow">
            {/* //TODO Make this behaviour generic (try using HOC). */}
            {/* Warning Remove Comment Popup */}
            <WarningRemoveCommentDialog
                loadComments={loadComments}
                apiEndpoint={apiEndpoint}
                commentId={isWarningRemoveCommentDialogComment}
                closeWarningRemoveCommentDialog={closeWarningRemoveCommentDialog}
                isLoading={loadingInProgress}
            />
            <Table<elementCommentType>
                cells={commentsTableCells}
                onRowClick={onClickEditComment}
                onPageChange={loadPage}
                rows={rows}
                totalRows={totalComments}
                pageProps={page}
            />
        </div>
    )
}

/**
 * Warning Remove Comment Popup.
 *
 * @param props N/A.
 * @param props.apiEndpoint The base path of the apiEndpoint URL.
 * @param props.loadComments Handler to be called when adding or updating comment infos, so that we load the commentDetails again.
 * @param props.closeWarningRemoveCommentDialog Handler to be called for closing the popup, (when clicking on Cancel Button) and (after Confirm Remove whether success or fail).
 * @param props.commentId The id of the comment to be removed.
 * @param props.isLoading Is Loading in progress.
 * @returns WarningRemoveCommentDialog.
 */
const WarningRemoveCommentDialog = ({
    loadComments,
    closeWarningRemoveCommentDialog,
    apiEndpoint,
    commentId,
    isLoading,
}: /**
 *
 */
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadComments: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    closeWarningRemoveCommentDialog: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    commentId: number | null
    // eslint-disable-next-line jsdoc/require-jsdoc
    isLoading: boolean
}) => {
    const { formatMessage } = useIntl()
    const { removeComment } = useCommentsDetails(apiEndpoint)

    return (
        /* WARNING DIALOG When clicking on Remove */
        <Dialog
            open={!!commentId}
            onClose={closeWarningRemoveCommentDialog}
            aria-labelledby="alert-dialog-title"
            fullWidth
            maxWidth="sm"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle className="text-18" id="alert-dialog-title">
                {formatMessage({
                    id: 'Confirmer la Suppression?',
                    defaultMessage: 'Confirmer la Suppression?',
                })}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description" className="text-14">
                    {formatMessage({
                        id: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
                        defaultMessage: 'Êtes-vous sûr de vouloir supprimer ce commentaire?',
                    })}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <ButtonLoader
                    inProgress={isLoading}
                    onClick={closeWarningRemoveCommentDialog}
                    color="inherit"
                    variant="outlined"
                    className="text-12"
                >
                    {formatMessage({
                        id: 'Annuler',
                        defaultMessage: 'Annuler',
                    })}
                </ButtonLoader>
                <ButtonLoader
                    inProgress={isLoading}
                    className="text-12"
                    variant="outlined"
                    color="error"
                    onClick={async () => {
                        await removeComment(commentId!)
                        loadComments()
                        closeWarningRemoveCommentDialog()
                    }}
                    autoFocus
                >
                    {formatMessage({
                        id: 'Supprimer',
                        defaultMessage: 'Supprimer',
                    })}
                </ButtonLoader>
            </DialogActions>
        </Dialog>
    )
}
