import { CircularProgress, useTheme } from '@mui/material'
import TypographyFormatMessage from 'src/common/ui-kit/components/TypographyFormatMessage/TypographyFormatMessage'
import { ChartErrorMessage } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ChartErrorMessage'
import { periodType } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/Charts'
import ProductionChart from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/ProductionChart'
import { GRAPH_PRODUCTION_TITLE } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/charts/utils/ChartsVariables'
import { IMetric, metricRangeType } from 'src/modules/Metrics/Metrics.d'

/**
 * ProductionContainer component.
 *
 * @param param0 N/A.
 * @param param0.enedisOff Enedis consent.
 * @param param0.nrLinkOff Nrlink consent.
 * @param param0.enphaseOff Enphase consent.
 * @param param0.data Metric data.
 * @param param0.period Interval period.
 * @param param0.range Range.
 * @param param0.isMetricsLoading Metric loading state.
 * @returns Production container JSX.
 */
export const ProductionContainer = ({
    enedisOff,
    nrLinkOff,
    enphaseOff,
    data,
    period,
    range,
    isMetricsLoading,
}: // eslint-disable-next-line jsdoc/require-jsdoc
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    enedisOff: boolean
    // eslint-disable-next-line jsdoc/require-jsdoc
    nrLinkOff: boolean
    // eslint-disable-next-line jsdoc/require-jsdoc
    enphaseOff: boolean
    // eslint-disable-next-line jsdoc/require-jsdoc
    data: IMetric[]
    // eslint-disable-next-line jsdoc/require-jsdoc
    period: periodType
    // eslint-disable-next-line jsdoc/require-jsdoc
    range: metricRangeType
    // eslint-disable-next-line jsdoc/require-jsdoc
    isMetricsLoading: boolean
    // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
    const theme = useTheme()

    return (
        <>
            <div style={{ background: theme.palette.primary.main }} className="p-16">
                <TypographyFormatMessage
                    variant="body1"
                    className="sm:mr-8 mb-10"
                    style={{ color: theme.palette.primary.contrastText }}
                >
                    {GRAPH_PRODUCTION_TITLE}
                </TypographyFormatMessage>
                {enedisOff && enphaseOff ? (
                    <ChartErrorMessage nrLinkEnedisOff={enedisOff} enphaseOff={enphaseOff} />
                ) : (
                    <>
                        <div
                            className="flex flex-col justify-center items-center w-full h-full"
                            style={{ height: '320px' }}
                        >
                            <div style={{ color: theme.palette.primary.contrastText }} className="w-full">
                                {isMetricsLoading ? (
                                    <div
                                        className="flex flex-col justify-center items-center w-full h-full"
                                        style={{ height: '320px' }}
                                    >
                                        <CircularProgress style={{ color: theme.palette.background.paper }} />
                                    </div>
                                ) : (
                                    <ProductionChart
                                        data={data}
                                        chartType={period === 'daily' ? 'area' : 'bar'}
                                        period={period}
                                        range={range}
                                    />
                                )}
                            </div>
                        </div>
                        {/* Case for nrLINK OFF Enedis OFF enphase ON  */}
                        {!enphaseOff && enedisOff && nrLinkOff && (
                            <ChartErrorMessage nrLinkEnedisOff={nrLinkOff && enedisOff} />
                        )}
                        {/* Case for nrLINK ON Enedis ON enphase OFF  */}
                        {enphaseOff && !enedisOff && !nrLinkOff && <ChartErrorMessage enphaseOff={enphaseOff} />}
                    </>
                )}
            </div>
        </>
    )
}
