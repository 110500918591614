import { rest } from 'msw'
import Chance from 'chance'
import { EQUIPMENT_BASE_URL } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/equipmentsTable/equipmentsHooks'
import { EquipmentsFactory } from 'src/mocks/factories/equipments.factory'

const chance = new Chance()

/**
 *
 */
export const equipments = { current: EquipmentsFactory.buildList(chance.integer({ min: 0, max: 5 })) }

const equipmentsEndpoints = [
    // Get All Equipments
    rest.get(`${EQUIPMENT_BASE_URL}/:user_id`, (req, res, ctx) => {
        const userId = req.params.user_id
        if (parseInt(userId))
            return res(
                ctx.status(200),
                ctx.delay(1000),
                ctx.json({
                    items: equipments.current,
                    page: 1,
                    size: 100,
                    total: equipments.current.length,
                }),
            )
        else return res(ctx.status(400), ctx.delay(1000))
    }),
]

export default equipmentsEndpoints
