import React, { FC, useEffect, useCallback, useState, createContext } from 'react'
import { useParams } from 'react-router'
import { CUSTOMER_API, useCustomerDetails } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import Comments from 'src/modules/DetailsPages/components/Comments/Comments'
import Installation from 'src/modules/DetailsPages/CustomerDetails/InstallationTab'
import { ThemeProvider } from '@mui/material/styles'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import CustomerDetailsHeader from 'src/modules/DetailsPages/CustomerDetails/components/CustomerDetailsHeader'
import InstallationsRequestsTable from 'src/modules/InstallationsRequests/components/InstallationsRequestsTable'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import PersonalDetailForm from 'src/modules/DetailsPages/CustomerDetails/CustomerRecordTab/PersonalDetail/PersonalDetailForm'
import MultiTab from 'src/common/ui-kit/components/MultiTab/MultiTab'
import { useHistory } from 'react-router'
import { customerCardNavProps } from 'src/modules/MapPages/CustomerList/components/CustomerCard/CustomerCard'
import { CustomerDetailsContextType } from 'src/modules/DetailsPages/CustomerDetails/CustomerDetailsT'

// Context lets us pass a value deep into the component tree, without explicitly threading it through every component.
// Create a CustomerDetails Context, that will indicates if CustomerDetails should be view in readOnly Mode.
// eslint-disable-next-line jsdoc/require-jsdoc
export const CustomerDetailsContext = createContext<CustomerDetailsContextType>({ readOnly: false })

/**
 * The CustomerDetails UI after clicking on a User Card Map, it contains Tabs (Fiche Client, ...etc).
 *
 * @returns CustomerDetails UI.
 */
// TODO This component should be refactored with installer Details one.
const CustomerDetails: FC = () => {
    const history = useHistory()
    const historyLocationState = history.location.state as customerCardNavProps
    let customerDetailsContextValue
    let returnDetailsHeaderState
    if (historyLocationState) {
        returnDetailsHeaderState = {
            returnLabel: historyLocationState.returnLabel,
            returnURL: historyLocationState.returnURL,
        }
        customerDetailsContextValue = {
            readOnly: historyLocationState.readOnly,
        }
    }
    const theme = selectTheme()
    const [isLoadedCustomerDetailsWhenMount, setIsLoadedCustomerDetailsWhenMount] = useState(false)
    const { customerDetails, loadCustomerDetails } = useCustomerDetails()

    /**
     * Hook useParams to get the params of the current route url. /customers/:id/:tab.
     */
    const { customerId: customerIdParam } = useParams</**
     *
     */
    {
        // eslint-disable-next-line jsdoc/require-jsdoc
        customerId: string
    }>()

    const customerId = parseInt(customerIdParam)

    const [isEdit, setIsEdit] = React.useState(false)

    const API_ENDPOINT = `${CUSTOMER_API}/${customerId}/comments`
    /**
     * Handler function to setIsEdit(true).
     */
    const editCustomerDetails = () => {
        setIsEdit(true)
    }
    /**
     * Handler function to setIsEdit(false).
     */
    const disableEditForm = () => {
        setIsEdit(false)
    }

    /**
     * Handler for loading customerDetails.
     *
     * @returns LoadCustomerDetails.
     */
    const handleLoadCustomerDetails = useCallback(async () => {
        await loadCustomerDetails(customerId)
    }, [customerId, loadCustomerDetails])

    useEffect(() => {
        if (!isLoadedCustomerDetailsWhenMount) {
            handleLoadCustomerDetails()
            setIsLoadedCustomerDetailsWhenMount(true)
        }
    }, [handleLoadCustomerDetails, isLoadedCustomerDetailsWhenMount])

    const tabsContent = [
        {
            tabTitle: 'Fiche Client',
            tabSlug: 'customer-record',
            tabContent:
                customerDetails != null ? (
                    <div className="Tab-CustomerRecord">
                        <PersonalDetailForm
                            customerDetails={customerDetails}
                            loadCustomerDetails={handleLoadCustomerDetails}
                            isEdit={isEdit}
                            disableEditForm={disableEditForm}
                            editCustomerDetails={editCustomerDetails}
                        />
                        <Comments apiEndpoint={API_ENDPOINT} />
                    </div>
                ) : (
                    <div></div>
                ),
        },
        {
            tabTitle: `Ses demandes d'installations`,
            tabSlug: 'installations-requests',
            tabContent: (
                <div className="Tab-InstallationsRequests">
                    <div className="h-full">
                        <InstallationsRequestsTable isPageCarded={false} customerId={customerId} />
                    </div>
                </div>
            ),
        },
        {
            tabTitle: 'Son installation',
            tabSlug: 'installation',
            tabContent: (
                <div className="Tab-Installation">
                    <Installation />
                </div>
            ),
        },
    ]

    return (
        <CustomerDetailsContext.Provider value={{ ...customerDetailsContextValue }}>
            <ThemeProvider theme={theme}>
                {customerDetails === null ? (
                    <FuseLoading />
                ) : (
                    <MultiTab
                        header={
                            <CustomerDetailsHeader
                                editCustomerDetails={
                                    customerDetailsContextValue && customerDetailsContextValue.readOnly
                                        ? undefined
                                        : editCustomerDetails
                                }
                                isEdit={isEdit}
                                customerDetails={customerDetails}
                                {...returnDetailsHeaderState}
                            />
                        }
                        content={tabsContent}
                        innerScroll
                    />
                )}
            </ThemeProvider>
        </CustomerDetailsContext.Provider>
    )
}

export default CustomerDetails
