import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Toolbar from '@mui/material/Toolbar'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import { VariantType } from 'notistack'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import { useIntl } from 'src/common/react-platform-translation'
import { ButtonLoader } from 'src/common/ui-kit'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import Icon from '@mui/material/Icon'
import InputAdornment from '@mui/material/InputAdornment'
import { capitalize } from '@mui/material'
import {
    IInstallationRequest,
    statusType,
    useInstallationsRequests,
} from 'src/modules/InstallationsRequests/installationsRequestsHooks'
import 'src/modules/InstallationsRequests/components/InstallationsRequestsPopUp.scss'
import {
    equipmentsTypeList,
    statusList,
    displayTypeT,
} from 'src/modules/InstallationsRequests/components/InstallationsRequestsTable'
import { CustomerDetailsContext } from 'src/modules/DetailsPages/CustomerDetails/index'
import { useAuth } from 'src/modules/User/authentication/useAuth'

dayjs.extend(utc)

/**
 *
 */
export interface InstallationsRequestsPopupPropsType {
    /**
     *
     */
    open: boolean
    /**
     *
     */
    handleClosePopup: () => void
    /**
     *
     */
    installationRequestDetails: IInstallationRequest | null
    /**
     *
     */
    onAfterCreateUpdateDeleteSuccess: (updatedInstallationRequest?: IInstallationRequest) => void
    /**
     *
     */
    displayType: displayTypeT
}
/**
 * Pop up component.
 *
 * @param props Passed props to the pop up component.
 * @returns InstallationsRequestsPopup.
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
const InstallationsRequestsPopup = (props: InstallationsRequestsPopupPropsType) => {
    const { hasRole } = useAuth()
    const theme = selectTheme()
    const { formatMessage } = useIntl()
    const { isInstallationsRequestsInProgress, updateInstallationRequest, updateLeadExchanges } =
        useInstallationsRequests()
    const { installationRequestDetails, displayType, open, handleClosePopup, onAfterCreateUpdateDeleteSuccess } = props

    /**
     * Consume the CustomerDetailsContext.
     */
    const { readOnly } = useContext(CustomerDetailsContext)

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                classes={{
                    paper: 'm-24',
                }}
                fullWidth
                maxWidth="md"
            >
                <AppBar position="static" elevation={0}>
                    <Toolbar className="flex w-full justify-between">
                        <Typography variant="h5" color="inherit" className="flex items-center justify-center p-32">
                            {formatMessage({
                                id: "Détail d'une demande",
                                defaultMessage: "Détail d'une demande",
                            })}
                        </Typography>
                        <IconButton
                            color="inherit"
                            data-testid="InstallationsRequestsPopupCloseIcon"
                            onClick={() => handleClosePopup()}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                {installationRequestDetails === null ? (
                    <DialogContent classes={{ root: `p-24` }}>
                        <FuseLoading />
                    </DialogContent>
                ) : (
                    <>
                        <DialogContent classes={{ root: `p-24` }}>
                            {/* Status */}
                            {displayType !== 'leadExchange' && (
                                <div className="flex flex-col mb-24 sm:flex-row sm:items-center">
                                    <Typography className="font-semibold text-12 sm:text-18 mb-14 whitespace-nowrap mr-12">
                                        {formatMessage({
                                            id: `Statut:`,
                                            defaultMessage: `Statut:`,
                                        })}
                                    </Typography>
                                    <div className="flex items-center flex-wrap">
                                        {Object.keys(statusList).map((status) => (
                                            // Change the color of the buttons, by createTheme that will handle all the hover, focus, ...etc state of the button according to the color given.
                                            <LoadingButton
                                                color={
                                                    statusList[status as keyof typeof statusList].color as Exclude<
                                                        VariantType,
                                                        'default'
                                                    >
                                                }
                                                loadingPosition="start"
                                                className="mb-8 rounded-8 mr-12 flex items-center justify-center"
                                                loading={isInstallationsRequestsInProgress}
                                                variant={
                                                    status === installationRequestDetails.status
                                                        ? 'contained'
                                                        : 'outlined'
                                                }
                                                onClick={async () => {
                                                    const result = await updateInstallationRequest(
                                                        installationRequestDetails.id,
                                                        status as statusType,
                                                    )
                                                    onAfterCreateUpdateDeleteSuccess(result)
                                                }}
                                                disabled={readOnly}
                                            >
                                                <span className={isInstallationsRequestsInProgress ? 'ml-20' : ''}>
                                                    {statusList[status as keyof typeof statusList].label}
                                                </span>
                                            </LoadingButton>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {/* User Info: Coordonnées */}
                            <div className="mb-24">
                                <div className="flex mb-16">
                                    <Icon className="sm:inline text-20 sm:text-32 mr-8">contacts</Icon>
                                    <Typography className="text-12 sm:text-18 font-semibold">
                                        {formatMessage({ id: 'Coordonnées', defaultMessage: 'Coordonnées' })}
                                    </Typography>
                                </div>
                                {/* Phone */}
                                <div className="flex items-center mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mr-4 whitespace-nowrap">
                                        <span className="hidden font-semibold mr-4 sm:inline">
                                            {formatMessage({
                                                id: `Numéro de téléphone:`,
                                                defaultMessage: `Numéro de téléphone:`,
                                            })}
                                        </span>
                                        <span className="inline font-semibold mr-4 sm:hidden">
                                            {formatMessage({
                                                id: `Téléphone:`,
                                                defaultMessage: `Téléphone:`,
                                            })}
                                        </span>
                                    </Typography>

                                    <TextField
                                        disabled
                                        className={`w-full ${
                                            displayType === 'leadExchange' && hasRole(['installer'])
                                                ? 'blurTextField'
                                                : ''
                                        }`}
                                        name="phone"
                                        value={
                                            displayType === 'leadExchange' && hasRole(['installer'])
                                                ? '15e2b0d3c33891ebb0f1ef609ec41'
                                                : installationRequestDetails.user!.phone
                                        }
                                        label=""
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>

                                {/* Email */}
                                <div className="flex items-center mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mr-4 whitespace-nowrap">
                                        <span className="font-semibold mr-4">
                                            {formatMessage({
                                                id: `Email:`,
                                                defaultMessage: `Email:`,
                                            })}
                                        </span>
                                    </Typography>
                                    <TextField
                                        disabled
                                        className={`w-full ${
                                            displayType === 'leadExchange' && hasRole(['installer'])
                                                ? 'blurTextField'
                                                : ''
                                        }`}
                                        name="email"
                                        value={
                                            displayType === 'leadExchange' && hasRole(['installer'])
                                                ? '5446e65241e5293f7c4a43883c289810299'
                                                : installationRequestDetails.user!.email
                                        }
                                        label=""
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>

                                {/* Addresse */}
                                <div className="flex items-center mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mr-4 whitespace-nowrap">
                                        <span className="inline font-semibold mr-4">
                                            {formatMessage({
                                                id: `Addresse:`,
                                                defaultMessage: `Addresse:`,
                                            })}
                                        </span>
                                    </Typography>
                                    <TextField
                                        disabled
                                        className="w-full"
                                        name="street"
                                        value={
                                            displayType === 'leadExchange' && hasRole(['installer'])
                                                ? `${capitalize(installationRequestDetails.user!.address.city)} ${
                                                      installationRequestDetails.user!.address.zipCode
                                                  }`
                                                : capitalize(installationRequestDetails.user!.address.name)
                                        }
                                        label=""
                                        InputProps={{
                                            readOnly: true,
                                            startAdornment: displayType === 'leadExchange' &&
                                                hasRole(['installer']) && (
                                                    <InputAdornment position="start">
                                                        <span className="blurText">{`09ec419420c20e320ce94c65fbc8c3312448eb225`}</span>
                                                    </InputAdornment>
                                                ),
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Installation Request Info: Demande */}
                            <div className="mb-24">
                                <div className="flex mb-16">
                                    <Icon className="sm:inline text-20 sm:text-32 mr-8">support</Icon>
                                    <Typography className="text-12 sm:text-18 font-semibold">
                                        {formatMessage({ id: 'Demande', defaultMessage: 'Demande' })}
                                    </Typography>
                                </div>

                                {/* Date */}
                                <div className="flex items-center mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mr-4 whitespace-nowrap">
                                        <span className="hidden font-semibold mr-4 sm:inline">
                                            {formatMessage({
                                                id: `Date de la demande:`,
                                                defaultMessage: `Date de la demande:`,
                                            })}
                                        </span>
                                        <span className="inline font-semibold mr-4 sm:hidden">
                                            {formatMessage({
                                                id: `Date:`,
                                                defaultMessage: `Date:`,
                                            })}
                                        </span>
                                    </Typography>
                                    <TextField
                                        disabled
                                        className="w-full"
                                        name="date"
                                        value={dayjs
                                            .utc(installationRequestDetails.createdAt)
                                            .local()
                                            .format('DD/MM/YYYY')}
                                        label=""
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                                {/* Matèriel demandé */}
                                <div className="flex flex-col mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mb-8 whitespace-nowrap">
                                        <span className="hidden font-semibold mr-4 sm:inline">
                                            {formatMessage({
                                                id: `Matériel demandé:`,
                                                defaultMessage: `Matériel demandé:`,
                                            })}
                                        </span>
                                        <span className="inline font-semibold mr-4 sm:hidden">
                                            {formatMessage({
                                                id: `Matériel:`,
                                                defaultMessage: `Matériel:`,
                                            })}
                                        </span>
                                    </Typography>
                                    <div className="flex items-center flex-wrap">
                                        {Object.keys(equipmentsTypeList).map((e) => (
                                            <Button
                                                className="mb-8 rounded-8 mr-12 flex items-center justify-center"
                                                variant="contained"
                                                disabled={installationRequestDetails.equipmentType !== e}
                                            >
                                                <Icon>
                                                    {equipmentsTypeList[e as keyof typeof equipmentsTypeList].icon}
                                                </Icon>
                                                <span className="ml-4">
                                                    {equipmentsTypeList[e as keyof typeof equipmentsTypeList].label}
                                                </span>
                                            </Button>
                                        ))}
                                    </div>
                                </div>

                                {/* Marque & Modèle */}
                                <div className="flex flex-col sm:flex-row sm:flex-wrap sm:items-center ">
                                    <div className="flex flex-col mr-12 sm:mb-12">
                                        <Typography
                                            variant="subtitle1"
                                            className="font-semibold mb-8 whitespace-nowrap"
                                        >
                                            <span className="font-semibold mr-4">
                                                {formatMessage({
                                                    id: `Marque:`,
                                                    defaultMessage: `Marque:`,
                                                })}
                                            </span>
                                        </Typography>
                                        <TextField
                                            disabled
                                            className="w-full"
                                            name="equipmentBrand"
                                            value={installationRequestDetails.equipmentBrand}
                                            label=""
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                    <div className="flex flex-col mr-12 sm:mb-12">
                                        <Typography
                                            variant="subtitle1"
                                            className="font-semibold mb-8 whitespace-nowrap"
                                        >
                                            <span className="font-semibold mr-4">
                                                {formatMessage({
                                                    id: `Modèle:`,
                                                    defaultMessage: `Modèle:`,
                                                })}
                                            </span>
                                        </Typography>
                                        <TextField
                                            disabled
                                            className="w-full"
                                            name="equipmentModel"
                                            value={installationRequestDetails.equipmentModel}
                                            label=""
                                            InputProps={{ readOnly: true }}
                                        />
                                    </div>
                                </div>

                                {/* Budget */}
                                <div className="flex flex-col mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mb-8 whitespace-nowrap">
                                        <span className="font-semibold mr-4">
                                            {formatMessage({
                                                id: `Budget:`,
                                                defaultMessage: `Budget:`,
                                            })}
                                        </span>
                                    </Typography>
                                    <TextField
                                        disabled
                                        className="w-full"
                                        name="budget"
                                        value={installationRequestDetails.budget}
                                        label=""
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>

                                {/* Commentaire */}
                                <div className="flex flex-col mb-12">
                                    <Typography variant="subtitle1" className="font-semibold mb-8 whitespace-nowrap">
                                        <span className="font-semibold mr-4">
                                            {formatMessage({
                                                id: `Commentaire`,
                                                defaultMessage: `Commentaire`,
                                            })}
                                        </span>
                                    </Typography>
                                    <TextField
                                        disabled
                                        className="w-full"
                                        name="budget"
                                        value={installationRequestDetails.comment}
                                        label=""
                                        InputProps={{ readOnly: true }}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                        {displayType === 'leadExchange' && !hasRole(['distributor']) && (
                            <DialogActions>
                                <div className="w-full flex justify-center">
                                    <ButtonLoader
                                        color="secondary"
                                        inProgress={isInstallationsRequestsInProgress}
                                        onClick={async () => {
                                            await updateLeadExchanges(installationRequestDetails.id)
                                            onAfterCreateUpdateDeleteSuccess()
                                        }}
                                    >
                                        {formatMessage({ id: 'Prendre', defaultMessage: 'Prendre' })}
                                    </ButtonLoader>
                                </div>
                            </DialogActions>
                        )}
                    </>
                )}
            </Dialog>
        </ThemeProvider>
    )
}

export default InstallationsRequestsPopup
