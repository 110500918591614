import React, { SyntheticEvent } from 'react'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableSortLabel from '@mui/material/TableSortLabel'

/**
 * Row containing the Head Cells of the InstallationsRequests Table.
 */
export const headRowCells = [
    {
        id: 'equipmentType',
        align: 'left',
        disablePadding: false,
        label: 'Type',
    },
    {
        id: 'createdAt',
        align: 'center',
        disablePadding: false,
        label: 'Date',
    },
    {
        id: 'user.address.city',
        align: 'left',
        disablePadding: false,
        label: 'Localisation',
    },
    {
        id: 'lastname',
        align: 'left',
        disablePadding: false,
        label: 'Nom',
        excludeCell: true,
    },
    {
        id: 'budget',
        align: 'left',
        disablePadding: false,
        label: 'Budget',
    },
    {
        id: 'status',
        align: 'left',
        disablePadding: false,
        label: 'Etat',
        excludeCell: true,
    },
    {
        id: 'Visibility',
        align: 'left',
        disablePadding: false,
        label: '',
    },
]

/**
 * Args passed as props.
 */
interface InstallationsRequestsHeadProps {
    /**
     *
     */
    transparent?: boolean
    /**
     *
     */
    excludeCells?: boolean
    /**
     *
     */
    onRequestSort: (event: SyntheticEvent, property: string) => void
    /**
     *
     */
    order: 'asc' | 'desc'
    /**
     *
     */
    orderBy: string
}
/**
 * All Installations Requests table head component (it can be found in /lead-exchanges or /installations-requests).
 *
 * @param props Passed props.
 * @param props.transparent Represent if the header row has a transparent background, which happens when cells have transparent background.
 * @param props.excludeCell Indicated the to excludeCells that have this flag, it's done when it's LeadExchange (example: when LeadExchange indicate excludeCells, then name cell should not show).
 * @param props.onRequestSort Function when clicking on icon sort of the column head cell.
 * @param props.order Order direction.
 * @param props.orderBy Indicating which column to orderBy.
 * @returns InstallationsRequests Table Head.
 */
function InstallationsRequestsTableHead(props: InstallationsRequestsHeadProps) {
    const { transparent, excludeCells, order, orderBy, onRequestSort } = props
    /**
     *  Create Sort Handler for a particular column on click on its cell header.
     *
     * @param property Headcell id.
     * @returns Function to handle the onRequestSort.
     */
    const createSortHandler = (property: string) => (event: SyntheticEvent) => {
        onRequestSort(event, property)
    }

    let cells = headRowCells
    if (excludeCells) {
        cells = headRowCells.filter((cell) => !cell.excludeCell)
    }

    return (
        <TableHead>
            <TableRow className="h-48 sm:h-64">
                {cells.map((cell) => {
                    return (
                        <TableCell
                            className="p-4 md:p-16"
                            key={cell.id}
                            padding={cell.disablePadding ? 'none' : 'normal'}
                            align="left"
                            sortDirection={orderBy === cell.id ? order : false}
                            style={transparent ? { backgroundColor: 'transparent' } : {}}
                        >
                            <TableSortLabel
                                active={orderBy === cell.id}
                                direction={orderBy === cell.id ? order : 'asc'}
                                className="font-semibold"
                                onClick={createSortHandler(cell.id)}
                            >
                                {cell.label}
                            </TableSortLabel>
                        </TableCell>
                    )
                })}
            </TableRow>
        </TableHead>
    )
}

export default InstallationsRequestsTableHead
