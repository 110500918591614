import { IRoute } from 'src/common/react-platform-components/utils/mm'
import InstallerDetails from 'src/modules/DetailsPages/InstallerDetails/index'
import { authTypes } from 'src/common/react-platform-components'

// TODO refactor in next task to implement installer details with tabs.
const URL_INSTALLER_DETAILS = '/installers/:installerId/:tab'

const InstallerDetailsConfig = [
    {
        path: URL_INSTALLER_DETAILS,
        component: InstallerDetails,
        auth: { authType: authTypes.loginRequired },
    } as IRoute</**
     *
     */
    {}>,
]

export { InstallerDetailsConfig, URL_INSTALLER_DETAILS }
