import React, { useContext } from 'react'
import { requiredBuilder, Form } from 'src/common/react-platform-components'
import { MuiTextField as TextField } from 'src/common/ui-kit'
import { useIntl } from 'src/common/react-platform-translation'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Button from '@mui/material/Button'
import { useCommentsDetails, useCommentsList } from 'src/modules/DetailsPages/components/Comments/CommentsHooks'
import { elementCommentType } from 'src/modules/DetailsPages/components/Comments/CommentsType'
import LinearProgress from '@mui/material/LinearProgress'
import { CustomerDetailsContext } from 'src/modules/DetailsPages/CustomerDetails/index'

/**
 * Form for the CommentsForm by the staller.
 *
 * @param props N/A.
 * @param props.apiEndpoint The base path of the apiEndpoint URL.
 * @param props.closeOpenCommentPopup Handler to for close comment popup when adding or updating comment infos, so that we load the commentDetails again.
 * @param props.commentDetails The commentDetails if selected as prop.
 * @param props.loadComments Handler to be called when adding or updating comment infos, so that we load the commentDetails again.
 * @returns CommentsForm Form.
 */
const CommentsForm = ({
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint,
    // eslint-disable-next-line jsdoc/require-jsdoc
    commentDetails,
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadComments,
    // eslint-disable-next-line jsdoc/require-jsdoc
    closeOpenCommentPopup,
}: /**
 *
 */
{
    // eslint-disable-next-line jsdoc/require-jsdoc
    apiEndpoint: string
    // eslint-disable-next-line jsdoc/require-jsdoc
    commentDetails: elementCommentType | null
    // eslint-disable-next-line jsdoc/require-jsdoc
    loadComments: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    closeOpenCommentPopup: () => void
}) => {
    const { formatMessage } = useIntl()
    const { updateComment, loadingRequest } = useCommentsDetails(apiEndpoint)

    /**
     * Consume the CustomerDetailsContext.
     */
    const { readOnly } = useContext(CustomerDetailsContext)

    const { addElement: addComment } = useCommentsList(apiEndpoint)
    return (
        <Form
            defaultValues={{
                message: commentDetails ? commentDetails.message : '',
            }}
            onSubmit={async (values: /**
             *
             */
            {
                // eslint-disable-next-line jsdoc/require-jsdoc
                message: string
            }) => {
                if (commentDetails) {
                    await updateComment({
                        ...commentDetails,
                        ...values,
                    })
                } else {
                    await addComment({ ...values })
                }
                loadComments()
                closeOpenCommentPopup()
            }}
        >
            <DialogContent className="pt-5">
                <TextField
                    name="message"
                    disabled={readOnly}
                    multiline
                    className="p-4 w-full"
                    rows={5}
                    label={formatMessage({
                        id: 'commentaire',
                        defaultMessage: 'commentaire',
                    })}
                    placeholder={formatMessage({
                        id: 'Veuillez entrer votre commentaire',
                        defaultMessage: 'Veuillez entrer votre commentaire',
                    })}
                    validateFunctions={[requiredBuilder()]}
                />
                {loadingRequest && <LinearProgress />}
            </DialogContent>
            {!loadingRequest && (
                <DialogActions>
                    <Button className="text-14" color="inherit" onClick={closeOpenCommentPopup} type="button">
                        {formatMessage({
                            id: 'Annuler',
                            defaultMessage: 'Annuler',
                        })}
                    </Button>
                    {!readOnly && (
                        <Button className="text-14" type="submit" color="secondary">
                            {formatMessage({
                                id: 'Enregistrer',
                                defaultMessage: 'Enregistrer',
                            })}
                        </Button>
                    )}
                </DialogActions>
            )}
        </Form>
    )
}

export default CommentsForm
