import { IMeterT } from 'src/modules/DetailsPages/CustomerDetails/InstallationTab/metersTable/meters.d'
import { rest } from 'msw'
import Chance from 'chance'
import { MeterFactory } from 'src/mocks/factories/meters.factory'
import { CUSTOMER_API } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import { SnakeCasedPropertiesDeep } from 'type-fest'
import { getPaginationFromElementList } from 'src/mocks/utils'

const chance = new Chance()

const METERS_BASE_URL = `${CUSTOMER_API}/:user_id/pdl`
/**
 *
 */
const meters = MeterFactory.buildList(chance.integer({ min: 0, max: 50 })) as []

const metersEndpoints = [
    // Get All Meters
    rest.get(`${METERS_BASE_URL}`, (req, res, ctx) => {
        const { user_id: userId } = req.params
        const RESPONSE_METERS = getPaginationFromElementList<SnakeCasedPropertiesDeep<IMeterT>>(req, meters)
        if (parseInt(userId)) return res(ctx.status(200), ctx.delay(1000), ctx.json(RESPONSE_METERS))
        else return res(ctx.status(400), ctx.delay(1000))
    }),
]

export default metersEndpoints
