import React from 'react'
import AddCustomerPopup from 'src/modules/MapPages/CustomerList/components/AddCustomerPopup/AddCustomerPopup'
import { addCustomerInputType, updateFiltersType } from 'src/modules/MapPages/CustomerList/customerListHook'
import PageHeaderSearch from 'src/common/ui-kit/fuse/components/PageHeaderSearch/PageHeaderSearch'

/**
 *
 */
type CustomersListHeaderProps = /**
 *
 */ /**
 *
 */ /**
 *
 */ {
    // eslint-disable-next-line jsdoc/require-jsdoc
    getCustomersList: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    updateFilters: updateFiltersType
    // eslint-disable-next-line jsdoc/require-jsdoc
    addCustomer: (input: addCustomerInputType) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    isCustomerInProgress: boolean
    // eslint-disable-next-line jsdoc/require-jsdoc
    headerTitle?: string
}
/**
 * CustomersListHeader component.
 *
 * @param props Containing {open, handleClose} to the AddCustomerPopup.
 * @param props.getCustomersList Function to be called when addCustomer success.
 * @param props.updateFilters Function to be called when submit search.
 * @param props.addCustomer Submit addCustomer.
 * @param props.isCustomerInProgress Progress for customer.
 * @param props.headerTitle Represent an override of Header title, to show a HaderTitle for the CustomerList Page based on the role of the connected user.
 * @returns AddCustomerPopup component.
 */
function CustomersListHeader(props: CustomersListHeaderProps) {
    // Importing all the user because typescript is making an error with type IUser.
    const [openAddPopup, setOpenAddPopup] = React.useState(false)
    const { getCustomersList, updateFilters, addCustomer, isCustomerInProgress, headerTitle } = props
    /**
     * Handler for the popup open.
     */
    const handleOpenAddPopup = () => {
        setOpenAddPopup(true)
    }

    /**
     * Handler for the popup close.
     */
    const handleCloseAddPopup = () => {
        setOpenAddPopup(false)
    }
    return (
        <>
            <AddCustomerPopup
                addCustomer={addCustomer}
                isCustomerInProgress={isCustomerInProgress}
                open={openAddPopup}
                handleCloseAddPopup={handleCloseAddPopup}
                getCustomersList={getCustomersList}
            />
            <PageHeaderSearch
                headerIconName="map"
                headerTitle={headerTitle ? headerTitle : 'Mes utilisateurs'}
                onSearchClick={updateFilters}
                onActionClick={handleOpenAddPopup}
                actionText="Créer"
                actionIconName="add"
            />
        </>
    )
}

export default CustomersListHeader
