import { IRoute } from 'src/common/react-platform-components/utils/mm'
import CustomerDetails from 'src/modules/DetailsPages/CustomerDetails'
import { authTypes } from 'src/common/react-platform-components'

const URL_CUSTOMER_DETAILS = '/customers/:customerId/:tab'

const CustomerDetailsConfig = [
    {
        path: URL_CUSTOMER_DETAILS,
        component: CustomerDetails,
        auth: { authType: authTypes.loginRequired },
    } as IRoute</**
     *
     */
    {}>,
]

export { CustomerDetailsConfig, URL_CUSTOMER_DETAILS }
