import FusePageCarded from 'src/common/ui-kit/fuse/components/FusePageCarded'
import { styled } from '@mui/material/styles'
import InstallationsRequestsHeader from 'src/modules/InstallationsRequests/components/InstallationsRequestsHeader'
import InstallationsRequestsTable from 'src/modules/InstallationsRequests/components/InstallationsRequestsTable'

const Root = styled(FusePageCarded)(({ theme }) => ({
    '& .FusePageCarded-header': {
        minHeight: 72,
        height: 72,
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: 136,
            height: 136,
        },
    },
    '& .FusePageCarded-content': {
        display: 'flex',
    },
    '& .FusePageCarded-contentCard': {
        overflow: 'hidden',
    },
}))

/**
 * Installation Requests component.
 *
 * @returns JSX element.
 */
function LeadExchanges(): JSX.Element {
    return (
        <Root
            header={<InstallationsRequestsHeader />}
            content={<InstallationsRequestsTable isPageCarded={true} displayType="leadExchange" />}
            innerScroll
        />
    )
}

export default LeadExchanges
