import React, { useCallback, useEffect, SyntheticEvent, useState } from 'react'
import FuseScrollbars from 'src/common/ui-kit/fuse/components/FuseScrollbars'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import Icon from '@mui/material/Icon'
import Chip from '@mui/material/Chip'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import TablePagination from '@mui/material/TablePagination'
import Typography from '@mui/material/Typography'
import { VariantType } from 'notistack'
import { motion } from 'framer-motion'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import InstallationsRequestsTableHead from 'src/modules/InstallationsRequests/components/InstallationsRequestsTableHead'
import {
    IInstallationRequest,
    useInstallationsRequests,
} from 'src/modules/InstallationsRequests/installationsRequestsHooks'
import InstallationsRequestsPopup from 'src/modules/InstallationsRequests/components/InstallationsRequestsPopUp'
import { useIntl } from 'src/common/react-platform-translation'
import { capitalize } from '@mui/material'
import { stableSort, getComparator } from 'src/modules/utils/tables'

dayjs.extend(utc)

/**
 *
 */
export type displayTypeT = 'installer' | 'leadExchange' | 'customer'

/**
 *
 */
interface IInstallationsRequestsTableProps {
    /**
     *
     */
    isPageCarded: boolean
    /**
     *
     */
    customerId?: number
    /**
     *
     */
    displayType?: displayTypeT
}

/**
 * Snackbar colors used for any theme.
 */
export const statusList = {
    NEW: {
        label: 'Nouveau',
        color: 'warning',
    },
    PENDING: {
        label: 'En Cours',
        color: 'info',
    },
    CLOSED: {
        label: 'Terminé',
        color: 'success',
    },
    CANCELED: {
        label: 'Abandonnée',
        color: 'error',
    },
}

/**
 *
 */
export const equipmentsTypeList = {
    SOLAR: {
        label: 'Panneau Solaire',
        icon: 'panorama_horizontal_select',
    },
    INVERTER: {
        label: 'Onduleur',
        icon: 'autofps_select',
    },
    DEMOTIC: {
        label: 'Domotique',
        icon: 'other_houses',
    },
    OTHER: {
        label: 'Autre',
        icon: 'help_center',
    },
}

/**
 * Table listing InstallationsRequests.
 *
 * @param props Props of InstallationsRequests Table.
 * @param props.isPageCarded Indicate if we're using table with PageCarded component or a table by itself.
 * @param props.displayType The displayType indicates whether Installations Requests of a specific customer,or it's all customers installations requests of the installer, or it's leadExchange.
 * @param props.customerId The customerId indicates the id of Installations Requests of a specific customer.
 * @returns Installations Requests Table.
 */
const InstallationsRequestsTable = ({
    isPageCarded,
    displayType = 'customer',
    customerId,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
IInstallationsRequestsTableProps) => {
    const {
        isInstallationsRequestsInProgress,
        installationsRequestsList,
        loadAllCustomersInstallationsRequests,
        loadCustomerInstallationsRequests,
        loadLeadExchanges,
    } = useInstallationsRequests()
    const [isInstallationsRequestsPopup, setIsInstallationsRequestsPopup] = useState(false)
    const [isLoadedInstallationsRequestsWhenMount, setIsLoadedInstallationsRequestsWhenMount] = useState(false)
    const [installationRequestDetails, setInstallationRequestDetails] = useState<IInstallationRequest | null>(null)
    const { formatMessage } = useIntl()

    /***** SORTING & Pagination Related state and function. *****/
    const [order, setOrder] = React.useState<'asc' | 'desc'>('asc')
    const [orderBy, setOrderBy] = React.useState('id')
    const [rowsPerPage, setRowsPerPage] = React.useState(25)
    const [page, setPage] = React.useState(0)
    /**
     * HandleRequest Sort function by Material UI.
     *
     * @param event Click Event.
     * @param property The column head id.
     */
    const handleRequestSort = (event: SyntheticEvent, property: string) => {
        const isAsc = orderBy === property && order === 'asc'
        setOrder(isAsc ? 'desc' : 'asc')
        setOrderBy(property)
    }
    /**
     * Handle change page table.
     *
     * @param event Event.
     * @param newPage NewPage index value.
     */
    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage)
    }
    /**
     * HandleChange Rows Per Page.
     *
     * @param event Event.
     */
    const handleChangeRowsPerPage = (
        //eslint-disable-next-line jsdoc/require-jsdoc
        event: SyntheticEvent & {
            //eslint-disable-next-line jsdoc/require-jsdoc
            target: {
                //eslint-disable-next-line jsdoc/require-jsdoc
                value: string
            }
        },
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    /**
     * Handler loading installations requests list related to customer or installer.
     */
    const loadInstallationsRequestsList = useCallback(() => {
        if (displayType === 'installer') {
            loadAllCustomersInstallationsRequests()
        } else if (displayType === 'leadExchange') {
            loadLeadExchanges()
        } else {
            //  it means it's customerId
            loadCustomerInstallationsRequests(customerId!)
        }
    }, [
        customerId,
        displayType,
        loadAllCustomersInstallationsRequests,
        loadCustomerInstallationsRequests,
        loadLeadExchanges,
    ])

    // UseEffect to load the insallations requests list when the component mount.
    useEffect(() => {
        if (!isLoadedInstallationsRequestsWhenMount) {
            loadInstallationsRequestsList()
            setIsLoadedInstallationsRequestsWhenMount(true)
        }
    }, [loadInstallationsRequestsList, isLoadedInstallationsRequestsWhenMount])

    /**
     * This is a callback when the updateFunction has succeeded., so that we update the installationRequestDetails and loadInstallationsRequestsList again, This is the updatedInstallationRequest when it's installer or customer, otherwise if its lead-exchange then we close popup.
     *
     * @param updatedInstallationRequest UpdatedInstallationRequest from backend, when customer or Installer.
     */
    const onAfterCreateUpdateDeleteSuccess = (updatedInstallationRequest?: IInstallationRequest) => {
        if (displayType === 'leadExchange') {
            loadInstallationsRequestsList()
            setIsInstallationsRequestsPopup(false)
        } else {
            setInstallationRequestDetails(updatedInstallationRequest!)
            loadInstallationsRequestsList()
        }
    }

    let emptyOrLoadingInstallationsRequestsTable = null

    // The following if condition means => If inProgress || installationsReuestsList === null.
    if (isInstallationsRequestsInProgress || !!!installationsRequestsList) {
        emptyOrLoadingInstallationsRequestsTable = <FuseLoading />
    }

    if (installationsRequestsList && installationsRequestsList.length === 0) {
        emptyOrLoadingInstallationsRequestsTable = (
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1, transition: { delay: 0.1 } }}
                className="flex flex-1 items-center justify-center h-full"
            >
                <Typography color="textSecondary" variant="h5">
                    {formatMessage({
                        id: "Aucune demande d'installation",
                        defaultMessage: "Aucune demande d'installation",
                    })}
                </Typography>
            </motion.div>
        )
    }

    /**
     * Click on one installation request handling.
     *
     * @param installationRequest Selected Installation Request.
     */
    const handleClick = (installationRequest: IInstallationRequest) => {
        setInstallationRequestDetails(installationRequest)
        setIsInstallationsRequestsPopup(true)
    }

    return (
        <>
            <InstallationsRequestsPopup
                installationRequestDetails={installationRequestDetails}
                open={isInstallationsRequestsPopup}
                displayType={displayType}
                onAfterCreateUpdateDeleteSuccess={onAfterCreateUpdateDeleteSuccess}
                handleClosePopup={() => {
                    setIsInstallationsRequestsPopup(false)
                    setInstallationRequestDetails(null)
                }}
            />
            {emptyOrLoadingInstallationsRequestsTable ? (
                emptyOrLoadingInstallationsRequestsTable
            ) : (
                <div className={`w-full flex flex-col ${!isPageCarded && 'rounded-16 border-2 shadow'}`}>
                    <FuseScrollbars className="flex-grow overflow-x-auto">
                        <Table stickyHeader className="min-w-xl" aria-labelledby="tableTitle">
                            <InstallationsRequestsTableHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={handleRequestSort}
                                transparent={!isPageCarded}
                                excludeCells={displayType === 'leadExchange'}
                            />

                            <TableBody>
                                {stableSort(installationsRequestsList!, getComparator(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .map((installationRequest: IInstallationRequest) => (
                                        <TableRow
                                            className="h-72 cursor-pointer"
                                            hover
                                            role="checkbox"
                                            tabIndex={-1}
                                            key={installationRequest.id}
                                            onClick={() => handleClick(installationRequest)}
                                        >
                                            <TableCell className="p-4 md:p-16" component="th" scope="row">
                                                {
                                                    equipmentsTypeList[
                                                        installationRequest.equipmentType as keyof typeof equipmentsTypeList
                                                    ].label
                                                }
                                            </TableCell>
                                            <TableCell className="p-4 md:p-16 truncate" component="th" scope="row">
                                                {dayjs.utc(installationRequest.createdAt).local().format('DD/MM/YYYY')}
                                            </TableCell>
                                            <TableCell className="p-4 md:p-16 truncate" component="th" scope="row">
                                                {`${capitalize(installationRequest.user!.address.city)} ${
                                                    installationRequest.user!.address.zipCode
                                                }`}
                                            </TableCell>
                                            {displayType !== 'leadExchange' && (
                                                <TableCell className="p-4 md:p-16" component="th" scope="row">
                                                    {`${capitalize(installationRequest.user!.firstName)} ${capitalize(
                                                        installationRequest.user!.lastName!,
                                                    )}`}
                                                </TableCell>
                                            )}
                                            <TableCell className="p-4 md:p-16" component="th" scope="row">
                                                {/* https://stackoverflow.com/a/51322015/13145536 */}
                                                {new Intl.NumberFormat('fr-FR', {
                                                    style: 'currency',
                                                    currency: 'EUR',
                                                    minimumFractionDigits: 2,
                                                    maximumFractionDigits: 2,
                                                }).format(parseFloat(installationRequest.budget))}
                                            </TableCell>
                                            {displayType !== 'leadExchange' && (
                                                <TableCell className="p-4 md:p-16" component="th" scope="row">
                                                    <Chip
                                                        size="small"
                                                        color={
                                                            statusList[
                                                                installationRequest.status as keyof typeof statusList
                                                            ].color as VariantType
                                                        }
                                                        label={
                                                            statusList[
                                                                installationRequest.status as keyof typeof statusList
                                                            ].label
                                                        }
                                                    />
                                                </TableCell>
                                            )}
                                            <TableCell className="p-4 md:p-16" component="th" scope="row">
                                                <Icon
                                                    data-testid="visibilityIcon"
                                                    onClick={(e: React.SyntheticEvent) => {
                                                        e.stopPropagation()
                                                        handleClick(installationRequest)
                                                    }}
                                                    style={{ marginLeft: '15px' }}
                                                >
                                                    visibility
                                                </Icon>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </FuseScrollbars>
                    <TablePagination
                        className="flex-shrink-0 border-t-1"
                        component="div"
                        count={installationsRequestsList!.length}
                        rowsPerPageOptions={[15, 25, 50, 100]}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            'aria-label': 'Previous Page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'Next Page',
                        }}
                        labelDisplayedRows={({ from, to, count }) => {
                            return `${from}–${to} sur ${count !== -1 ? count : to}`
                        }}
                        labelRowsPerPage="Lignes par page:"
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </div>
            )}
        </>
    )
}

export default InstallationsRequestsTable
