import React from 'react'
import UserMenu from 'src/modules/Layout/Toolbar/components/UserMenu'
import Notifications from 'src/modules/Layout/Toolbar/components/Notifications'

/**
 * ToolbarWidget include the content of the Toolbar.
 *
 * @returns ToolbarWidget Component.
 */
export const ToolbarWidget = () => {
    return (
        <>
            <Notifications />
            <UserMenu />
        </>
    )
}
