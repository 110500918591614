import React from 'react'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import Dialog from '@mui/material/Dialog'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Toolbar from '@mui/material/Toolbar'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { ThemeProvider } from '@mui/material/styles'
import { selectTheme } from 'src/common/ui-kit/fuse/utils/theming-generator'
import AddCustomerForm from 'src/modules/MapPages/CustomerList/components/AddCustomerPopup/AddCustomerForm'
import { useIntl } from 'src/common/react-platform-translation'
import { addCustomerInputType } from 'src/modules/MapPages/CustomerList/customerListHook'

/**
 *
 */
export interface AddCustomerPopupTypes {
    /**
     *
     */
    open: boolean
    /**
     *
     */
    getCustomersList: () => void
    /**
     *
     */
    handleCloseAddPopup: () => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    addCustomer: (input: addCustomerInputType) => void
    // eslint-disable-next-line jsdoc/require-jsdoc
    isCustomerInProgress: boolean
}
/**
 * Popup that contains the AddCustomerForm by the fitter/staller (installateur).
 *
 * @param props Containing {open, handleClose} to the AddCustomerPopup.
 * @param props.open Trigger for opening the popup.
 * @param props.handleCloseAddPopup Function responsible for closing Popup AddClient.
 * @param props.getCustomersList Handler  loadCustomer when addCustomer success.
 * @param props.addCustomer Submit addCustomer.
 * @param props.isCustomerInProgress Progress for customer.
 * @returns AddCustomerPopup component.
 */
const AddCustomerPopup = (props: AddCustomerPopupTypes) => {
    const theme = selectTheme()
    const { formatMessage } = useIntl()
    const { open, handleCloseAddPopup, getCustomersList, addCustomer, isCustomerInProgress } = props

    /**
     * Handler for when update/add/remove succeed and extra fetch needs to be made.
     */
    const onAfterCreateUpdateDeleteSuccess = () => {
        getCustomersList()
        handleCloseAddPopup()
    }

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={open}
                classes={{
                    paper: 'm-24',
                }}
                onClose={handleCloseAddPopup}
                fullWidth
                maxWidth="sm"
            >
                <>
                    <AppBar position="static" elevation={0}>
                        <Toolbar className="flex w-full justify-between">
                            <Typography variant="subtitle1" color="inherit">
                                {formatMessage({
                                    id: 'Ajout Client',
                                    defaultMessage: 'Ajout Client',
                                })}
                            </Typography>
                            <IconButton
                                color="inherit"
                                data-testid="AddCustomerPopupCloseIcon"
                                onClick={handleCloseAddPopup}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                        <div className="flex flex-col items-center justify-center pb-24">
                            <Avatar
                                className="w-96 h-96"
                                alt="contact avatar"
                                src="assets/images/avatars/profile.jpg"
                            />
                        </div>
                    </AppBar>
                    <DialogContent>
                        <AddCustomerForm
                            onAfterCreateUpdateDeleteSuccess={onAfterCreateUpdateDeleteSuccess}
                            addCustomer={addCustomer}
                            isCustomerInProgress={isCustomerInProgress}
                        />
                    </DialogContent>
                </>
            </Dialog>
        </ThemeProvider>
    )
}

export default AddCustomerPopup
