import { rest } from 'msw'
import { API_RESOURCES_URL } from 'src/configs'

/**
 * Download CSV Endpoint.
 */
export const downloadCSVEndpoint = [
    rest.get(`${API_RESOURCES_URL}/get_last_year_consumption_csv/:housing_id`, (req, res, ctx) => {
        const params = req.params

        if (params.length > 0) {
            return res(ctx.status(200), ctx.delay(1000))
        } else {
            return res(ctx.status(400), ctx.delay(1000))
        }
    }),
]
