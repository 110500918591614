import React from 'react'
import { useIntl } from 'react-intl'
import FuseLoading from 'src/common/ui-kit/fuse/components/FuseLoading'
import Table from 'src/common/ui-kit/components/Table/Table'
import IconButton from '@mui/material/IconButton'
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle'
import Tooltip from '@mui/material/Tooltip'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { IUser } from 'src/modules/User'
import { styled } from '@mui/material/styles'
import EmptyTableMessage from 'src/common/ui-kit/components/Table/EmptyTableMessage'
import {
    useInstallerRequestList,
    useInstallerRequestDetails,
} from 'src/modules/RegistrationRequests/InstallerRequests/InstallerRequestsHook'
import FusePageCarded from 'src/common/ui-kit/fuse/components/FusePageCarded'
import { dayjsUTC } from 'src/common/react-platform-components'
import PageHeaderSearch from 'src/common/ui-kit/fuse/components/PageHeaderSearch/PageHeaderSearch'
import { ButtonLoader } from 'src/common/ui-kit'

const Root = styled(FusePageCarded)(({ theme }) => ({
    '& .FusePageCarded-header': {
        minHeight: 72,
        height: 72,
        alignItems: 'center',
        [theme.breakpoints.up('sm')]: {
            minHeight: 136,
            height: 136,
        },
    },
    '& .FusePageCarded-content': {
        display: 'flex',
    },
    '& .FusePageCarded-contentCard': {
        overflow: 'hidden',
    },
}))

/**
 * Component ActionsCell that will be rendered as content of the ActionButtons Cell.
 *
 * @param props N/A.
 * @param props.row Represent the installer information of the current row (current row represent the row when clicking on Accept or Refuse).
 * @param props.onAfterCreateUpdateDeleteSuccess Callback function when activation succeeded.
 * @returns ActionsCell Component.
 */
const ActionsCell = ({
    row,
    onAfterCreateUpdateDeleteSuccess,
}: //eslint-disable-next-line jsdoc/require-jsdoc
{
    //eslint-disable-next-line jsdoc/require-jsdoc
    row: IUser
    //eslint-disable-next-line jsdoc/require-jsdoc
    onAfterCreateUpdateDeleteSuccess: () => void
}) => {
    const { formatMessage } = useIntl()

    const { acceptInstallerRequest, refuseInstallerRequest, loadingActivateRequest } = useInstallerRequestDetails(
        row.id,
    )
    return (
        <div>
            {loadingActivateRequest ? (
                <ButtonLoader variant="text" loadingWidth="30" loadingHeight="30" inProgress={true} />
            ) : (
                <React.Fragment>
                    <Tooltip
                        title={formatMessage({
                            id: 'Accepter',
                            defaultMessage: 'Accepter',
                        })}
                    >
                        <IconButton
                            color="success"
                            onClick={async () => {
                                await acceptInstallerRequest()
                                onAfterCreateUpdateDeleteSuccess()
                            }}
                        >
                            <CheckCircleIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip
                        title={formatMessage({
                            id: 'Refuser',
                            defaultMessage: 'Refuser',
                        })}
                    >
                        <IconButton
                            color="error"
                            onClick={async () => {
                                await refuseInstallerRequest()
                                onAfterCreateUpdateDeleteSuccess()
                            }}
                        >
                            <RemoveCircleIcon />
                        </IconButton>
                    </Tooltip>
                </React.Fragment>
            )}
        </div>
    )
}

/**
 * InstallerRequestsTable component to display sensors in a table.
 *
 * @returns InstallerRequestsTable component.
 */
const InstallerRequestsTable = () => {
    const {
        elementList: installerRequestList,
        reloadElements: reloadInstallerRequestList,
        loadingInProgress: isInstallerRequestInProgress,
        updateFilters,
        totalElementList: totalInstallerRequestList,
        loadPage,
        page,
    } = useInstallerRequestList()

    const { formatMessage } = useIntl()

    /**
     * Row containing the Cells of the Chameleons Table.
     */
    const installerRequestsCells = [
        {
            id: 'firstName',
            headCellLabel: formatMessage({ id: 'Prénom Et Nom', defaultMessage: 'Prénom Et Nom' }),
            disablePadding: true,
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => `${row.firstName} ${row.lastName}`,
        },
        {
            id: 'companyName',
            headCellLabel: formatMessage({ id: 'Raison sociale', defaultMessage: 'Raison sociale' }),
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => `${row.companyName ? row.companyName : ''}`,
        },
        {
            id: 'siren',
            headCellLabel: formatMessage({ id: 'Siren', defaultMessage: 'Siren' }),
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => `${row.siren ? row.siren : ''}`,
        },
        {
            id: 'address.name',
            headCellLabel: formatMessage({ id: 'Adresse', defaultMessage: 'Adresse' }),
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => row.address.name,
        },
        {
            id: 'createdAt',
            headCellLabel: formatMessage({
                id: 'Date et Heure de la demande',
                defaultMessage: 'Date et Heure de la demande',
            }),
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => dayjsUTC(row.createdAt).format('DD/MM/YYYY à HH:mm'),
        },
        {
            id: '',
            headCellLabel: '',
            // eslint-disable-next-line jsdoc/require-jsdoc
            rowCell: (row: IUser) => (
                <ActionsCell row={row} onAfterCreateUpdateDeleteSuccess={reloadInstallerRequestList} />
            ),
        },
    ]

    return (
        <Root
            header={<PageHeaderSearch onSearchClick={updateFilters} headerIconName="list_al" headerTitle="Demandes" />}
            content={
                <React.Fragment>
                    {isInstallerRequestInProgress || installerRequestList === null ? (
                        <FuseLoading />
                    ) : installerRequestList.length === 0 ? (
                        <EmptyTableMessage
                            message={formatMessage({
                                id: "Il n'y a pas de demandes",
                                defaultMessage: "Il n'y a pas de demandes",
                            })}
                        />
                    ) : (
                        <div className="w-full flex flex-col">
                            <Table<IUser>
                                cells={installerRequestsCells}
                                totalRows={totalInstallerRequestList}
                                onPageChange={loadPage}
                                rows={installerRequestList}
                                pageProps={page}
                            />
                        </div>
                    )}
                </React.Fragment>
            }
            innerScroll
        />
    )
}

export default InstallerRequestsTable
