import React from 'react'
import { useFormContext } from 'react-hook-form'
import { email, requiredBuilder, Form } from 'src/common/react-platform-components'
import { ButtonLoader, TextField } from 'src/common/ui-kit'
import { useIntl } from 'src/common/react-platform-translation'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Icon from '@mui/material/Icon'
import Switch from '@mui/material/Switch'
import { userSpecificitiesT } from 'src/modules/MapPages/CustomerList/customerListHook'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import {
    customerAddressType,
    customerT,
    interestValuesType,
    useCustomerDetails,
} from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'
import {
    getPlaceIdFromValueForCustomerAddress,
    getDisplayedLabelFromValueForCustomerAddress,
    getFormattedValueForCustomerAddress,
    GoogleMapsAddressAutoCompleteFieldFormCustomer,
    getAdditionAddressValue,
    setAdditionAddressValue,
} from 'src/modules/MapPages/CustomerList/components'
import { PhoneNumber } from 'src/common/ui-kit/form-fields/phoneNumber/PhoneNumber'
import EditButton from 'src/modules/utils/EditButton'

/**
 * PersonalDetailForm fields Component.
 *
 * @param props N/A.
 * @param props.isEdit Indicating if the form is editable or not.
 * @param props.customerDetails The current customer selected as prop.
 * @param props.loadCustomerDetails Handler to be called when updating customer infos, so that we load the customerDetails again.
 * @param props.disableEditForm Handler to be called when click on Annuler and Enregistrer success so that form is not editable.
 * @param props.editCustomerDetails Function that handles edit customer details.
 * @returns PersonalDetailForm.
 */
const PersonalDetailForm = ({
    /**
     *
     */
    isEdit,
    /**
     *
     */
    customerDetails,
    /**
     * This loadCustomerDetails props is passed from CustomersDetails/index.tsx as it'll reload the customerDetails when update.
     */
    loadCustomerDetails,
    /**
     *
     */
    disableEditForm,
    /**
     *
     */
    editCustomerDetails,
}: /**
 *
 */
{
    /**
     *
     */
    isEdit: boolean
    /**
     *
     */
    customerDetails: customerT
    /**
     *
     */
    loadCustomerDetails: () => Promise<void>
    /**
     *
     */
    disableEditForm: () => void
    /**
     *
     */
    editCustomerDetails: () => void
}) => {
    const { isCustomerInProgress, updateCustomer } = useCustomerDetails()

    const interestInitialValues = {
        isInterestedByInstallation: customerDetails.interests.includes('installation'),
        isInterestedBySupplier: customerDetails.interests.includes('supplier'),
    }
    // TODO We should use the form fields to retrieve the data instead of using this state as an external source of data.
    const [userSpecificities, setUserSpecificities] = React.useState<typeof interestInitialValues>({
        isInterestedByInstallation: customerDetails.interests.includes('installation'),
        isInterestedBySupplier: customerDetails.interests.includes('supplier'),
    })

    const installation_intrest = userSpecificities.isInterestedByInstallation
    const supplier_intrest = userSpecificities.isInterestedBySupplier

    const formInitialValues = {
        email: customerDetails.email,
        phone: customerDetails.phone,
        address: customerDetails.address,
    }
    const { formatMessage } = useIntl()

    return (
        <Form
            defaultValues={formInitialValues}
            onSubmit={async (
                // eslint-disable-next-line jsdoc/require-jsdoc
                values: Omit<typeof formInitialValues, 'address'> & {
                    // eslint-disable-next-line jsdoc/require-jsdoc
                    address: customerAddressType & { placeId?: string }
                } & Omit<userSpecificitiesT, 'hasSensor'>,
            ) => {
                // If nothing change no need to update Customer
                // TODO make the equality check with old values for the different fields more generic.
                if (
                    values.email === customerDetails.email &&
                    values.phone === customerDetails.phone &&
                    values.address === customerDetails.address &&
                    userSpecificities.isInterestedByInstallation ===
                        customerDetails.interests.includes('installation') &&
                    userSpecificities.isInterestedBySupplier === customerDetails.interests.includes('supplier')
                ) {
                    disableEditForm()
                    return
                }

                const interests = [
                    userSpecificities.isInterestedByInstallation && 'installation',
                    userSpecificities.isInterestedBySupplier && 'supplier',
                ].filter(Boolean) as interestValuesType[]

                delete values.address?.placeId
                await updateCustomer(customerDetails, { ...values, interests })
                loadCustomerDetails()
                disableEditForm()
            }}
        >
            <div className="flex mb-20 items-start justify-between">
                <div className="flex mb-10 sm:mb-0">
                    <ContentPasteSearchIcon className="sm:inline text-20 sm:text-32 mr-8" />
                    <Typography className="text-12 sm:text-18 font-semibold">
                        {formatMessage({
                            id: 'Intérêts du client',
                            defaultMessage: 'Intérêts du client',
                        })}
                    </Typography>
                </div>
                {editCustomerDetails && <EditButton onClick={editCustomerDetails} isEdit={isEdit} />}
            </div>
            <div className="flex flex-col">
                <div className="flex mb-20 items-center justify-start">
                    <Typography
                        variant="subtitle1"
                        sx={{ color: `${installation_intrest ? 'success.main' : 'error.main'}` }}
                        className="mr-40 font-semibold"
                    >
                        {installation_intrest
                            ? formatMessage({
                                  id: 'Utilisateur intéressé par une installation.',
                                  defaultMessage: 'Utilisateur intéressé par une installation.',
                              })
                            : formatMessage({
                                  id: 'Utilisateur pas intéressé par une installation.',
                                  defaultMessage: 'Utilisateur pas intéressé par une installation.',
                              })}
                    </Typography>
                    <div className={`${!isEdit ? 'hidden' : 'flex'} items-start`}>
                        <Switch
                            disabled={!isEdit}
                            color="success"
                            name="is_interested_by_installation"
                            checked={installation_intrest}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserSpecificities({
                                    ...userSpecificities,
                                    isInterestedByInstallation: event.target.checked,
                                })
                            }}
                            data-testid="switch-installation"
                        />
                    </div>
                </div>
                <div className="flex mb-20 items-center justify-start">
                    <Typography
                        variant="subtitle1"
                        sx={{ color: `${supplier_intrest ? 'success.main' : 'error.main'}` }}
                        className="mr-40 font-semibold"
                    >
                        {supplier_intrest
                            ? formatMessage({
                                  id: 'Utilisateur intéressé par un changement de fournisseur.',
                                  defaultMessage: 'Utilisateur intéressé par un changement de fournisseur.',
                              })
                            : formatMessage({
                                  id: 'Utilisateur pas intéressé par un changement de fournisseur.',
                                  defaultMessage: 'Utilisateur pas intéressé par un changement de fournisseur.',
                              })}
                    </Typography>
                    <div className={`${!isEdit ? 'hidden' : 'flex'} items-start`}>
                        <Switch
                            disabled={!isEdit}
                            color="success"
                            name="is_interested_by_supplier"
                            checked={supplier_intrest}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setUserSpecificities({
                                    ...userSpecificities,
                                    isInterestedBySupplier: event.target.checked,
                                })
                            }}
                            data-testid="switch-supplier"
                        />
                    </div>
                </div>
            </div>
            <div className="flex mb-20 items-start justify-between">
                <div className="flex mb-10 sm:mb-0">
                    <Icon className="sm:inline text-20 sm:text-32 mr-8">contacts</Icon>
                    <Typography className="text-12 sm:text-18 font-semibold">
                        {formatMessage({ id: 'Coordonnées', defaultMessage: 'Coordonnées' })}
                    </Typography>
                </div>
            </div>
            <PhoneNumber
                disabled={!isEdit}
                type="tel"
                name="phone"
                label={formatMessage({
                    id: 'Numéro de téléphone',
                    defaultMessage: 'Numéro de téléphone',
                })}
                sx={{ margin: '0 0 20px 0' }}
                validateFunctions={[requiredBuilder()]}
            />
            <GoogleMapsAddressAutoCompleteFieldFormCustomer
                disabled={!isEdit}
                name="address"
                validateFunctions={[requiredBuilder()]}
                valueFunctionsOverride={{
                    getFormattedValue: getFormattedValueForCustomerAddress,
                    getDisplayedLabelFromValue: getDisplayedLabelFromValueForCustomerAddress,
                    getPlaceIdFromValue: getPlaceIdFromValueForCustomerAddress,
                    getAddressAdditionFromValue: getAdditionAddressValue,
                    setAddressAdditionInValue: setAdditionAddressValue,
                }}
            />
            <TextField
                disabled={!isEdit}
                name="email"
                label={formatMessage({
                    id: 'Email',
                    defaultMessage: 'Email',
                })}
                placeholder={formatMessage({
                    id: 'Quel est son adresse email?',
                    defaultMessage: 'Quel est son adresse email?',
                })}
                validateFunctions={[requiredBuilder(), email()]}
            />
            {isEdit && (
                <div>
                    <ButtonReset
                        initialValues={formInitialValues}
                        interestInitialValues={interestInitialValues}
                        disableEditForm={disableEditForm}
                        setUserSpecificities={setUserSpecificities}
                    />
                    <ButtonLoader inProgress={isCustomerInProgress} variant="contained" type="submit" className="ml-8">
                        {formatMessage({ id: 'Enregistrer', defaultMessage: 'Enregistrer' })}
                    </ButtonLoader>
                </div>
            )}
        </Form>
    )
}

export default PersonalDetailForm

/**
 * ButtonReset Component for applying the reset on the Form.
 *
 * @param props N/A.
 * @param props.initialValues Initial Values of the form when reset.
 * @param props.initialValues.email The email property of the initial values.
 * @param props.initialValues.phone The phone property of the initial values.
 * @param props.initialValues.address The address property of the initial values.
 * @param props.interestInitialValues The Interests property of the initial values.
 * @param props.interestInitialValues.isInterestedByInstallation The Interest of installation property of the initial values.
 * @param props.interestInitialValues.isInterestedBySupplier The Interest of supplier property of the initial values.
 * @param props.disableEditForm To disable edit of form when reset.
 * @param props.setUserSpecificities To reset initial values of the Interest user specificities.
 * @returns ButtonReset.
 */
const ButtonReset = ({
    initialValues,
    interestInitialValues,
    disableEditForm,
    setUserSpecificities,
}: /**
 *
 */
{
    /**
     *
     */
    initialValues: /**
     *
     */
    {
        /**
         *
         */
        email: string
        /**
         *
         */
        phone: string
        /**
         *
         */
        address: customerAddressType
    }
    /**
     *
     */
    interestInitialValues: /**
     *
     */
    {
        /**
         *
         */
        isInterestedByInstallation: boolean
        /**
         *
         */
        isInterestedBySupplier: boolean
    }
    /**
     *
     */
    disableEditForm: () => void
    /**
     *
     */
    setUserSpecificities: React.Dispatch<React.SetStateAction<any>>
}) => {
    const { reset } = useFormContext()
    const { formatMessage } = useIntl()

    return (
        <Button
            variant="outlined"
            className="mb-4 sm:mr-8 sm:mb-0"
            onClick={() => {
                reset(initialValues)
                setUserSpecificities(interestInitialValues)
                disableEditForm()
            }}
        >
            {formatMessage({ id: 'Annuler', defaultMessage: 'Annuler' })}
        </Button>
    )
}
