import { rest } from 'msw'
import { CUSTOMER_API } from 'src/modules/DetailsPages/CustomerDetails/customerDetailsHooks'

/**
 *
 */
export const TEST_SUCCESS_COMMENT = {
    id: 1,
    created_at: '2021-12-15T14:07:38.138000',
    message: 'Installation réussi, suivi chaque mois pendant les 3 prochains mois.',
}

/**
 *
 */
export var TEST_COMMENTS = [TEST_SUCCESS_COMMENT]

/**
 *
 */
export const URL_COMMENTS = `${CUSTOMER_API}/:customerId/comments`

const commentsREST = [
    // Get Comments by customerId.
    rest.get(URL_COMMENTS, (req, res, ctx) => {
        const { customerId } = req.params
        if (parseInt(customerId)) {
            return res(
                ctx.status(200),
                ctx.delay(1000),
                ctx.json({
                    items: TEST_COMMENTS,
                    total: TEST_COMMENTS.length,
                    page: 1,
                    size: 100,
                }),
            )
        } else {
            return res(ctx.status(404), ctx.json({ message: 'Customer not to be found!' }))
        }
    }),

    /* Add Comment By customerId. */
    rest.post</**
     *
     */
    {
        /**
         *
         */
        message: string
        /**
         *
         */
        customer_id: number
    }>(URL_COMMENTS, (req, res, ctx) => {
        const { message } = req.body
        const { customerId } = req.params

        if (message !== 'error' && parseInt(customerId)) {
            const newId = TEST_COMMENTS.length + 1
            const date = new Date()
            let newComment = {
                message,
                created_at: date.toISOString(),
                id: newId,
            }

            TEST_COMMENTS.push(newComment)
            return res(ctx.status(200), ctx.delay(1000), ctx.json(newComment))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),

    // Update Comment By customerId
    rest.put</**
     *
     */
    {
        /**
         *
         */
        message: string
    }>(`${URL_COMMENTS}/:id`, (req, res, ctx) => {
        const { id } = req.params
        const { message } = req.body
        if (parseInt(id) && message !== 'error') {
            let indexOfComment = TEST_COMMENTS.findIndex((c) => c.id === parseInt(id))
            let newComment = { ...TEST_COMMENTS[indexOfComment], message: message }
            TEST_COMMENTS[indexOfComment] = newComment
            return res(ctx.status(200), ctx.delay(1000), ctx.json(newComment))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),

    // Remove Comment
    rest.delete(`${URL_COMMENTS}/:id`, (req, res, ctx) => {
        const { id } = req.params
        if (parseInt(id)) {
            let indexOfComment = TEST_COMMENTS.findIndex((c) => c.id === parseInt(id))
            let oldComment = TEST_COMMENTS[indexOfComment]
            TEST_COMMENTS.splice(indexOfComment, 1)
            return res(ctx.status(200), ctx.delay(1000), ctx.json(oldComment))
        } else {
            return res(ctx.status(401), ctx.delay(1000))
        }
    }),
]

export default commentsREST
