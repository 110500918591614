/**
 * Metrics intervals.
 */
export type metricIntervalType = '30m' | '1d' | '1M'

/**
 * Enum representing the metricTarget without exposing the backend naming.
 */
export enum metricTargetsEnum {
    /**
     * Enum value for global_consumption_with_auto_consumption.
     *
     * Nrlink & Enedis & Autoconsumption.
     */
    globalConsumtpion = 'global_consumption_with_auto_consumption',
    /**
     * Enum value for only_ned_unified_consumption_metrics.
     *
     * Consumption bought from enedis.
     */
    // This target is temporary and only for NED.
    enedisConsumption = 'only_ned_unified_consumption_metrics',
    /**
     * Enum value for auto_consumption_metrics.
     *
     * Production globale - Production injectée (vendu). What's remaining is the auto consumption.
     */
    autoconsumption = 'auto_consumption_metrics',
    /**
     * Enum value for enphase_production_metrics.
     */
    totalProduction = 'enphase_production_metrics',
    /**
     * Enum value for production_metrics.
     */
    injectedProduction = 'production_metrics',
}

/**
 * Metric Targets.
 */
export type metricTargetType =
    | metricTargetsEnum.globalConsumtpion
    | metricTargetsEnum.enedisConsumption
    | metricTargetsEnum.autoconsumption
    | metricTargetsEnum.injectedProduction
    | metricTargetsEnum.totalProduction

/**
 * Metric range.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export type metricRangeType = {
    /**
     *
     */
    from: string
    /**
     *
     */
    to: string
}

/**
 * Metrics filter.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export type metricTargetsType = {
    /**
     * Single metric target.
     */
    target: metricTargetType
    /**
     * Metric type.
     */
    type: 'timeserie'
}

/**
 * Metric Filters.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export type metricFiltersType = {
    /**
     * Key. Default is: "housing_id".
     */
    key: 'housing_id'
    /**
     * Operator.
     */
    operator: '='
    /**
     * Value of the housing_id.
     */
    value: string
}

/**
 * Metric model.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
export type IMetric = {
    /**
     * Metric target.
     */
    target: metricTargetType
    /**
     * Metric Datapoints.
     */
    datapoints: number[][]
}

/**
 * Information to be passed to body when getting metrics.
 */
//eslint-disable-next-line jsdoc/require-jsdoc
export type getMetricType = {
    /**
     * Range of time.
     */
    //eslint-disable-next-line jsdoc/require-jsdoc
    range: metricRangeType
    /**
     * Metric interval of time.
     */
    interval: metricIntervalType
    /**
     * Metrics targets.
     */
    targets: metricTargetsType[]
    /**
     * Metric filters.
     */
    filters: metricFiltersType[]
}
