import React from 'react'
import ContentLoader from 'react-content-loader'
/**
 * Component that is a placeholder for a single pages loading, it show a fake content loading page.
 *
 * @returns Component Single Page Loader.
 */
export const SinglePageLoader = () => {
    return (
        <ContentLoader
            data-testid="singlepageloader"
            height={1000}
            width={800}
            speed={3}
            backgroundColor="#f3f3f3"
            foregroundColor="#e9e8ec"
        >
            <rect x="5.02" y="16.7" rx="0" ry="0" width="782.02" height="319.55" />
            <rect x="7.77" y="360.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="207.77" y="360.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="104.77" y="362.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="305.77" y="361.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="611.78" y="359.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="709.78" y="360.22" rx="0" ry="0" width="82.47" height="39" />
            <rect x="8.02" y="421.71" rx="0" ry="0" width="522.01" height="120.96" />
            <rect x="550.03" y="418.71" rx="0" ry="0" width="240" height="301.56" />
            <rect x="9.04" y="561.71" rx="0" ry="0" width="120.06" height="120.96" />
            <rect x="7.06" y="690.71" rx="0" ry="0" width="522.01" height="120.96" />
            <rect x="272.25" y="560.71" rx="0" ry="0" width="120.06" height="120.96" />
            <rect x="405.04" y="558.71" rx="0" ry="0" width="120.06" height="120.96" />
            <rect x="142.04" y="559.71" rx="0" ry="0" width="120.06" height="120.96" />
            <rect x="6.06" y="819.71" rx="0" ry="0" width="522.01" height="120.96" />
        </ContentLoader>
    )
}

/**.
 * Component that is a placeholder for the GridCard Item when it's loading, it show a fake loading Card
 *
 * @returns Component Item PlaceHolder.
 */
export const PostPlaceholder = () => {
    return (
        <ContentLoader
            height={400}
            width={400}
            speed={2}
            className="placeholder"
            backgroundColor="#f3f3f3"
            foregroundColor="#e9e8ec"
            data-testid="postplaceholder"
        >
            <circle cx="39.34" cy="17.32" r="0.71" />
            <circle cx="751.46" cy="58.81" r="40.21" />
            <rect x="605.25" y="39.6" rx="0" ry="0" width="66" height="41" />
            <rect x="639.25" y="59.6" rx="0" ry="0" width="0" height="0" />
            <rect x="518.25" y="38.6" rx="0" ry="0" width="66" height="41" />
            <rect x="435.25" y="40.6" rx="0" ry="0" width="66" height="41" />
            <rect x="264.25" y="216.6" rx="0" ry="0" width="0" height="0" />
            <rect x="31.25" y="451.94" rx="0" ry="0" width="167.17" height="147.66" />
            <rect x="399.25" y="531.94" rx="0" ry="0" width="0" height="0" />
            <rect x="217.25" y="450.94" rx="0" ry="0" width="176.33" height="147.66" />
            <rect x="415.25" y="449.94" rx="0" ry="0" width="167.17" height="147.66" />
            <rect x="608.17" y="448.94" rx="0" ry="0" width="171.75" height="147.66" />
            <rect x="30.25" y="609.94" rx="0" ry="0" width="167.17" height="147.66" />
            <rect x="417.25" y="612.94" rx="0" ry="0" width="167.17" height="147.66" />
            <rect x="609.25" y="612.94" rx="0" ry="0" width="167.17" height="147.66" />
            <rect x="220.25" y="612.94" rx="0" ry="0" width="174.04" height="147.66" />
            <rect x="9.63" y="18.61" rx="0" ry="0" width="381.03" height="186" />
            <rect x="80.63" y="132.61" rx="0" ry="0" width="0" height="0" />
            <rect x="9.63" y="218.61" rx="0" ry="0" width="183" height="25" />
            <rect x="8.63" y="258.61" rx="0" ry="0" width="381" height="43" />
            <rect x="8.63" y="319.61" rx="0" ry="0" width="204" height="24" />
            <rect x="229.63" y="320.61" rx="0" ry="0" width="164.45" height="23" />
            <rect x="336.63" y="328.61" rx="0" ry="0" width="0" height="0" />
            <circle cx="20.56" cy="370.54" r="11.93" />
            <circle cx="53.63" cy="376.61" r="1" />
            <circle cx="52.56" cy="370.54" r="11.93" />
            <circle cx="81.56" cy="370.54" r="11.93" />
            <circle cx="112.56" cy="371.54" r="11.93" />
            <circle cx="141.56" cy="371.54" r="11.93" />
            <rect x="168.63" y="360.61" rx="0" ry="0" width="104" height="24" />
            <rect x="213.63" y="397.61" rx="0" ry="0" width="0" height="0" />
        </ContentLoader>
    )
}

/**
 * Component that is a placeholder for a profile pic when loading.
 *
 * @returns Profile Pic Loader.
 */
export const ProfilePicLoader = () => (
    <ContentLoader
        data-testid="profilepicloader"
        height={180}
        width={180}
        speed={3}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="76" cy="60" r="1" />
        <circle cx="90" cy="50" r="41" />
        <rect x="16" y="94" rx="0" ry="0" width="149" height="80" />
    </ContentLoader>
)

/**
 * Component that is a placeholder for an Image Card when loading.
 *
 * @returns ImageCard Loader.
 */
export const ImageCardLoader = () => (
    <ContentLoader
        data-testid="imagecardloader"
        height={270}
        width={215}
        speed={2}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
    >
        <circle cx="138" cy="80" r="1" />
        <rect x="87" y="24" rx="0" ry="0" width="0" height="0" />
        <rect x="87" y="24" rx="0" ry="0" width="0" height="0" />
        <rect x="0" y="0" rx="0" ry="0" width="223" height="277" />
    </ContentLoader>
)
