import { AUTH_BASE_URL } from 'src/modules/User/configs'
import { BuilderUseElementList } from 'src/modules/utils/useElementHookBuilder'
import { formatMessageType } from 'src/common/react-platform-translation'
import { searchFilterType } from 'src/modules/utils'
import { IUser } from 'src/modules/User'

/**
 * Url for InstallerList (users webservice) endpoints.
 */
export const INSTALLER_API = `${AUTH_BASE_URL}/users`

// eslint-disable-next-line jsdoc/require-jsdoc
export const loadElementListError = (error: any, formatMessage: formatMessageType) => {
    return formatMessage({
        id: 'Erreur lors du chargement des installateurs',
        defaultMessage: 'Erreur lors du chargement des installateurs',
    })
}

/**
 * Type of InstallerCustomers.
 */
// eslint-disable-next-line jsdoc/require-jsdoc
type installerSearchFilterType = searchFilterType & { role_type?: string }

/**
`* Hooks for customersList of Installers.
 *
 * @param sizeParam Indicates the default size when loadElement.
 * @returns UseCustomersList Hook.
 */
export const useInstallerList = (sizeParam?: number) =>
    BuilderUseElementList<IUser, undefined, installerSearchFilterType>({
        API_ENDPOINT: INSTALLER_API,
        sizeParam,
        snackBarMessage0verride: { loadElementListError },
    })(false, { role_type: 'installer' })
