import InstallerList, { InstallerListProps } from 'src/modules/MapPages/InstallerList'
import { authTypes } from 'src/common/react-platform-components'
import { IRouteNavigationConfig } from 'src/routes'

const URL_INSTALLER_LIST = '/installers'

const InstallerListConfig = [
    {
        path: URL_INSTALLER_LIST,
        component: InstallerList,
        auth: { authType: authTypes.loginRequired, roles: ['distributor'] },
        settings: {
            layout: {
                navbar: {
                    UINavbarItem: {
                        id: 'installers',
                        label: 'Mes Installateurs',
                        labelAbbreviation: 'Installateurs',
                        type: 'group',
                        iconLabel: 'engineering',
                        url: URL_INSTALLER_LIST,
                    },
                },
            },
        },
    } as IRouteNavigationConfig<InstallerListProps>,
]

export { InstallerListConfig, URL_INSTALLER_LIST }
