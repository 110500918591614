import { rest } from 'msw'
import { getMetricType, IMetric, metricTargetType, metricTargetsEnum } from 'src/modules/Metrics/Metrics.d'
import { SnakeCasedPropertiesDeep } from 'type-fest'
import dayjs from 'dayjs'
import { METRICS_API } from 'src/modules/Metrics/metricsHook'

/**
 * Function that generates fake Metric data.
 *
 * @param numberOfRows Rows number.
 * @param minValue Min value of target data.
 * @param maxValue Max value of target data.
 * @param interval Millisecond interval between 2 dates.
 * @returns Array of fake data.
 */
// TODO: Improve this function so that it handles range dynamically. StartDate const.
const generateMetricsFakeData = (numberOfRows: number, minValue: number, maxValue: number, interval: number) => {
    const arrData = Array(numberOfRows)
        .fill(null)
        .map(() => Array(2))
    const startDate = new Date().getTime()
    return arrData.map((row, index) => {
        const metricsValue = Math.floor(Math.random() * (maxValue - minValue) + minValue)
        const timestamp = startDate + interval * index
        return [metricsValue, timestamp]
    })
}

/**
 * Fake day data with interval of 30minutes.
 */
export const FAKE_DAY_DATA = generateMetricsFakeData(48, 1500, 1555, 1_800_000)

/**
 * Fake data of 7 days with 1 day interval.
 */
export const FAKE_WEEK_DATA = generateMetricsFakeData(7, 1500, 1570, 864_000_00)

/**
 * Fake data of 30 days with 1 day interval.
 */
export const FAKE_MONTH_DATA = generateMetricsFakeData(31, 1300, 1900, 864_00_000)

/**
 * Fake data of 1 year with 1 month interval.
 */
export const FAKE_YEAR_DATA = generateMetricsFakeData(12, 999, 1900, 2_678_400_000)

/**
 * Function that return MOCK Data, for all targets in the requests, instead of repeating ourselves creating FAKE DATA for each targets, It'll return FAKE DATA for all targets, especially if we have DAY DATA, MONTH DATA, ...etc.
 *
 * @param targets Targets of the request.
 * @param FAKE_DATA FAKE_DATA will represent which data we want to associate to all the targets.
 * @returns FAKE_DATA for all targets.
 */
function getMetricsDataFromTarget(targets: metricTargetType[], FAKE_DATA: number[][]) {
    return targets.map((target) => {
        let datapoints = FAKE_DATA
        if (target === metricTargetsEnum.globalConsumtpion) {
            datapoints = FAKE_DATA
        }
        if (target === metricTargetsEnum.enedisConsumption)
            datapoints = FAKE_DATA.map((datapoint) => [datapoint[0] - 300, datapoint[1]])
        if (target === metricTargetsEnum.autoconsumption)
            datapoints = FAKE_DATA.map((datapoint) => [datapoint[0] - 700, datapoint[1]])
        if (target === metricTargetsEnum.totalProduction) {
            datapoints = FAKE_DATA.map((datapoint) => [datapoint[0] - 1200, datapoint[1]])
        }
        if (target === metricTargetsEnum.injectedProduction) {
            datapoints = FAKE_DATA.map((datapoint) => [datapoint[0] - 800, datapoint[1]])
        }
        return {
            target,
            datapoints,
        }
    })
}

// eslint-disable-next-line jsdoc/require-jsdoc
export const TEST_SUCCESS_DAY_METRICS: (targets: metricTargetType[]) => SnakeCasedPropertiesDeep<IMetric[]> = (
    targets: metricTargetType[],
) => getMetricsDataFromTarget(targets, FAKE_DAY_DATA)

// eslint-disable-next-line jsdoc/require-jsdoc
export const TEST_SUCCESS_WEEK_METRICS: (targets: metricTargetType[]) => SnakeCasedPropertiesDeep<IMetric[]> = (
    targets: metricTargetType[],
) => getMetricsDataFromTarget(targets, FAKE_WEEK_DATA)

// eslint-disable-next-line jsdoc/require-jsdoc
export const TEST_SUCCESS_MONTH_METRICS: (targets: metricTargetType[]) => SnakeCasedPropertiesDeep<IMetric[]> = (
    targets: metricTargetType[],
) => getMetricsDataFromTarget(targets, FAKE_MONTH_DATA)

// eslint-disable-next-line jsdoc/require-jsdoc
export const TEST_SUCCESS_YEAR_METRICS: (targets: metricTargetType[]) => SnakeCasedPropertiesDeep<IMetric[]> = (
    targets: metricTargetType[],
) => getMetricsDataFromTarget(targets, FAKE_YEAR_DATA)

/**
 * Metrics endppoints.
 */
export const metricsEndpoints = [
    // Get meters metrics endpoint
    rest.post<getMetricType>(`${METRICS_API}`, (req, res, ctx) => {
        const fromInMilliseconds = dayjs(
            new Date(dayjs(new Date(req.body.range.from)).startOf('day').toDate()).getTime(),
        )
        const toInMilliseconds = dayjs(new Date(dayjs(new Date(req.body.range.to)).startOf('day').toDate()).getTime())
        // Difference will be the number of day starting date is from, end date is to, and ending date is counted in the period. so for a week we need a difference of 6.
        const difference = toInMilliseconds.diff(fromInMilliseconds, 'day')
        const targets: metricTargetType[] = req.body.targets ? req.body.targets.map((target) => target.target) : []

        // Difference can be 0 when checking the consumption at start of 00:00 to the same day 23:59.
        if ((difference === 1 || difference === 0) && req.body.interval === '30m')
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_DAY_METRICS(targets)))
        if ((difference === 6 || 7) && req.body.interval === '1d')
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_WEEK_METRICS(targets)))

        if ((difference === 30 || difference === 31) && req.body.interval === '1d')
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_MONTH_METRICS(targets)))

        if (difference >= 365 && req.body.interval === '1M')
            return res(ctx.status(200), ctx.delay(1000), ctx.json(TEST_SUCCESS_YEAR_METRICS(targets)))

        return res(ctx.status(400), ctx.delay(1000), ctx.json(TEST_SUCCESS_MONTH_METRICS))
    }),
]
